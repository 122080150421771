import React from 'react';
import { getMoodColors } from '../../../config/consts';

const RelaxSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="60" height="60" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.6001C41 32.8174 32.7173 41.1001 22.5 41.1001C12.2827 41.1001 4 32.8174 4 22.6001C4 12.3828 12.2827 4.1001 22.5 4.1001C32.7173 4.1001 41 12.3828 41 22.6001Z"
        stroke={color}
      />
      <path
        d="M24.4151 15.6287C25.791 15.6287 26.9105 14.5009 26.9105 13.1145C26.9105 11.7282 25.791 10.6001 24.4151 10.6001C23.0392 10.6001 21.9199 11.7279 21.9199 13.1145C21.9199 14.5012 23.0392 15.6287 24.4151 15.6287Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1729 18.691V25.1003C26.6163 26.7533 23.8774 27.9739 23.8774 27.9739L14.4518 33.1775L12.5 30.7301L14.4936 29.6295L14.4867 25.6821C14.4867 22.918 16.3548 22.8144 17.3462 22.8144H21.3825L21.3906 18.691C19.5669 18.6905 18.7002 18.2341 17.879 17.413C17.0574 16.5914 16.8042 15.587 17.3453 13.9893C17.8864 12.3917 19.7239 11.6416 19.7239 11.6416L20.6507 13.804C20.6507 13.804 19.5307 14.2637 19.5222 15.0851C19.5138 15.9065 21.12 16.1351 21.12 16.1351H28.195C28.195 16.1351 29.8012 15.9065 29.7928 15.0851C29.7844 14.2637 28.6644 13.804 28.6644 13.804L29.5911 11.6416C29.5911 11.6416 31.4286 12.3917 31.9697 13.9893C32.5108 15.587 32.2577 16.5914 31.4361 17.413C30.6144 18.2346 28.9987 18.691 27.1729 18.691ZM21.2739 25.5132H16.9467V27.8646L21.2739 25.5132Z"
        fill={color}
      />
    </svg>
  );
};

export default RelaxSVG;
