import { Box, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
// Components
import ProductCard from '../../../../components/cards/productCard';
import ProductLoadingCard from '../../../../components/cards/productCardLoading';

// Selectors
import { selectSelectedFilters } from '../../../../store/selectors/shopBySelectors';

// Actions
import { fetchProductsScrollingAction, updateFiltersAction } from '../../../../store/actions/shopByActions';

const StyledGridContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  scrollbarWidth: 'none' /* For Firefox */,
  '-ms-overflow-style': 'none' /* For Internet Explorer and Edge */,
  '&::-webkit-scrollbar': {
    display: 'none' // Hide scrollbar for Chrome, Safari and Opera
  }
});

const PerfectMatchProductListHome = ({ products, loading, onClick }) => {
  // Dispatch
  const dispatch = useDispatch();

  // Selector
  const selectedFilters = useSelector(selectSelectedFilters);

  async function fetchData(page) {
    dispatch(fetchProductsScrollingAction(true));
    const newFilters = {
      ...selectedFilters,
      page: selectedFilters.page + 1,
      pageSize: 10
    };

    dispatch(updateFiltersAction(newFilters));
  }

  return (
    <StyledGridContainer container justifyContent={'center'} spacing={2} mt={2}>
      {loading || products?.length === 0 ? (
        <Swiper spaceBetween={16} slidesPerView={'auto'} freeMode={true}>
          {[...Array(4)].map((_, index) => (
            <SwiperSlide key={index} style={{ width: 'auto' }}>
              <ProductLoadingCard />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Swiper spaceBetween={16} slidesPerView={'auto'} freeMode={true}>
          {products?.map((product) => (
            <SwiperSlide key={product.id} style={{ width: 'auto' }}>
              <ProductCard product={product} onClick={onClick} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </StyledGridContainer>
  );
};

export default PerfectMatchProductListHome;
