import { Grid, Paper } from '@mui/material';
import React from 'react';

export default function ProductReportLayout({ thumbnail, experience, composition }) {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: { xs: 2, sm: 6 },
        margin: 0,
        minHeight: '100vh'
      }}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '3rem' }}
    >
      <Grid container spacing={2} style={{ flex: '1 1 0%' }}>
        <Grid item container xs={12} spacing={2}>
          {thumbnail}
          {experience}
          {composition}
        </Grid>
      </Grid>
      <div style={{ backgroundColor: 'white', marginTop: '0.5rem' }}>
        <p style={{ width: '89%', padding: '1rem 1rem 1.5rem 0rem', backgroundColor: 'white', fontSize: 12, marginBottom: 0 }}>
          *Potency values reflect ingredient % by product weight. Speedometers and line graphs show relative ingredient potency compared to
          legal market products of the same type (i.e. dry flower, concentrate, edible, etc.) Relative potency of dominant terpenes is
          reflected by individual icon size.
        </p>
      </div>
    </Paper>
  );
}
