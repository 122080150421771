import { useRoutes } from 'react-router-dom';

// Route links
import { appRoutes } from './appRoutes';

//Components
import AppLayout from '../layouts/appLayout/appLayout';
import AgeVerification from '../pages/AgeVerification/AgeVerification';
import Checkout from '../pages/Checkout/Checkout';
import OrderConfirmation from '../pages/Checkout/OrderConfirmation';
import PaymentInfo from '../pages/Checkout/PaymentInfo';
import HomePage from '../pages/HomePage/HomePage';
import ProductDetails from '../pages/ProductDetails/ProductDetails';
import ProductMiniReport from '../pages/ProductReport/ProductMiniReport';
import ProductReport from '../pages/ProductReport/ProductReport';
import SearchComponent from '../pages/Search/searchComponent';
import ShopByActivity from '../pages/shopBy/activity/shopByActivity';
import ShopByMood from '../pages/shopBy/mood/shopByMood';
import ShopByPerfectMatch from '../pages/shopBy/perfectMatch/shopByPerfectMatch';
import ShopByProductType from '../pages/shopBy/productType';
import ShopBySymptoms from '../pages/shopBy/symptoms/shopBySymptoms';

// Routes

const allRoutes = [
  {
    path: appRoutes.shopByMood,
    element: (
      <AppLayout>
        <ShopByMood />
      </AppLayout>
    )
  },
  {
    path: appRoutes.shopByActivity,
    element: (
      <AppLayout>
        <ShopByActivity />
      </AppLayout>
    )
  },
  {
    path: appRoutes.shopBySymptoms,
    element: (
      <AppLayout>
        <ShopBySymptoms />
      </AppLayout>
    )
  },
  {
    path: appRoutes.shopByPerfectMatch,
    element: (
      <AppLayout>
        <ShopByPerfectMatch />
      </AppLayout>
    )
  },
  {
    path: appRoutes.shopByProductType,
    element: (
      <AppLayout>
        <ShopByProductType />
      </AppLayout>
    )
  },
  {
    path: appRoutes.product,
    element: <ProductDetails />
  },
  {
    path: appRoutes.productReport,
    element: <ProductReport />
  },
  {
    path: appRoutes.productMiniReport,
    element: <ProductMiniReport />
  },
  {
    path: appRoutes.checkout,
    element: <Checkout />
  },
  {
    path: appRoutes.payment,
    element: <PaymentInfo />
  },
  {
    path: appRoutes.confirmation,
    element: <OrderConfirmation />
  },
  {
    path: appRoutes.home,
    element: <HomePage />
  },
  {
    path: appRoutes.search,
    element: (
      <AppLayout>
        <SearchComponent />
      </AppLayout>
    )
  },
  {
    path: appRoutes.ageVerification,
    element: (
      <AgeVerification />
    )
  }
];

// Routing Render Function
export default function ThemeRoutes() {
  return useRoutes(allRoutes);
}