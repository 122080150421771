import { Box, CardMedia, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCardIcon, ProductName, ProductPrice, ProductQTY } from './StyledComponents';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import RemoveIcon from '@mui/icons-material/Remove';
import Divider from '@mui/material/Divider';
import { decreaseQuantity, increaseQuantity, removeFromCart } from '../../../store/checkout/actions/checkoutActions';
import { selectProductTotalPrice } from '../../../store/checkout/selectors/checkoutSelectors';
import { parseImg } from '../../../utils/parseImg';

const ProductCard = ({ productInfo, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const totalPrice = useSelector((state) => selectProductTotalPrice(state, productInfo.id));
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <Box m={0} p={1} onClick={() => onClick(productInfo.id)} sx={{ '&:hover': { cursor: 'pointer' } }}>
      <Paper sx={{ padding: '1em', boxShadow: 3 }}>
        <Grid container spacing={isMobile ? 1 : 2} direction="row">
          <Grid item xs={3}>
            {productInfo.img && imageLoaded ? (
              <CardMedia
                component="img"
                height={isMobile ? '47px' : '67px'}
                src={parseImg(productInfo.img)}
                alt={'Product'}
                style={{ width: '100%', height: isMobile ? '47px' : '67px', objectFit: 'cover' }}
                onError={() => setImageLoaded(false)}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={isMobile ? '47px' : '67px'}
                sx={{ width: '100%', backgroundColor: '#f0f0f0' }}
              >
                <ImageIcon style={{ fontSize: '300%', color: '#ccc' }} />
              </Box>
            )}
          </Grid>
          <Grid item xs={9}>
            <ProductName>{productInfo.name}</ProductName>
            {/* {productInfo?.productType !== 'Vape' && (<ProductWeight>Weight: {productInfo.weight}</ProductWeight>)} */}
            <ProductPrice my={'10px'}>$ {totalPrice.toFixed(2)}</ProductPrice>
            {!isMobile && <QuantitySelector productInfo={productInfo} />}
          </Grid>
        </Grid>
        {isMobile && <QuantitySelector productInfo={productInfo} />}
      </Paper>
    </Box>
  );
};

export default ProductCard;

const QuantitySelector = ({ productInfo }) => {
  const dispatch = useDispatch();

  const handleIncreaseQuantity = () => {
    dispatch(increaseQuantity(productInfo.id));
  };

  const handleDecreaseQuantity = () => {
    dispatch(decreaseQuantity(productInfo.id));
  };

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(productInfo.id));
  };
  return (
    <>
      <Divider sx={{ color: '#F1EEEB' }} />
      <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: '0.7em' }}>
        <ProductCardIcon
          onClick={(event) => {
            handleRemoveFromCart();
            event.stopPropagation();
          }}
        >
          <DeleteIcon aria-label="Remove Product From Cart" />
        </ProductCardIcon>

        <Grid item container xs justifyContent="flex-end" alignItems="center" spacing={1}>
          <ProductCardIcon
            onClick={(event) => {
              handleDecreaseQuantity();
              event.stopPropagation();
            }}
          >
            <RemoveIcon />
          </ProductCardIcon>
          <Grid item>
            <ProductQTY>{productInfo.quantity}</ProductQTY>
          </Grid>
          <ProductCardIcon
            onClick={(event) => {
              handleIncreaseQuantity();
              event.stopPropagation();
            }}
          >
            <AddIcon />
          </ProductCardIcon>
        </Grid>
      </Grid>
    </>
  );
};
