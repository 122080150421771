import React, { useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

// Components
import CustomButton from '../buttons/custombutton';
import IconButton from '../buttons/iconButton';
import PerfectMatchCard from '../cards/perfectMatchCard';
import CustomPopper from '../poppers/customPopper';

// Assets
import { ReactComponent as ArrowBack } from '../../assets/icons/arrowBackIcon.svg';

// Hooks
import { usePopper } from '../../hooks/usePopper';

// Consts
import { popperRevealNavigationTabs } from '../../consts/shopbyPerfectMatchConsts';
import { perfectMatchFiltersConsts } from '../../consts/shopbyPerfectMatchConsts';

// Styled Tabs container to match your specifications
const StyledTabs = styled(Tabs)({
  borderRadius: '2px',
  border: '2px solid #000',
  '& .MuiTabs-flexContainer': {
    gap: '-27px' // This will make tabs overlap
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#000'
  }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  minWidth: '4vw',

  letterSpacing: '0.1px',
  textTransform: 'uppercase',
  backgroundColor: 'transparent',
  color: 'inherit',
  border: '1px solid var(--Neutrals-Black, #000)',
  '&.Mui-selected': {
    backgroundColor: 'var(--Neutrals-Black, #000)',
    color: '#fff',
    boxShadow: 'none'
  }
}));

const NavigationTabs = ({ filterStates, handleFilterChange, showFirstPopper, showSecondPopper, stepState, handleNext, handleBack }) => {
  // Hooks
  const { ref, anchorEl, togglePopper } = usePopper();
  // State
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
      <Grid item ref={ref}>
        <Box sx={{ maxWidth: '90vw', overflowX: 'auto' }}>
          <StyledTabs value={value} onChange={handleChange} aria-label="navigation tabs">
            {perfectMatchFiltersConsts.map((filter, index) => (
              <StyledTab key={filter.title} label={filter.title} />
            ))}
          </StyledTabs>
        </Box>

        {showSecondPopper && (
          <CustomPopper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={() => handleNext()}
            popperDetails={popperRevealNavigationTabs}
            placement="bottom"
          />
        )}
      </Grid>
      <Grid item>
        <PerfectMatchCard
          noFiltersDescription={perfectMatchFiltersConsts[value].noFiltersDescription}
          title={perfectMatchFiltersConsts[value].title}
          steps={perfectMatchFiltersConsts[value].steps}
          currentStep={parseInt(filterStates[value] / 25)}
          onStepChange={(step) => handleFilterChange(value, step)}
          index={value}
          showFirstPopper={showFirstPopper}
          toggleFirstPopper={handleNext}
        />
      </Grid>
      <Grid item container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item>
          {stepState.currentStep > 0 && (
            <IconButton
              icon={<ArrowBack />}
              disabled={stepState.currentStep === 0}
              title={'Back'}
              onClick={handleBack}
              sx={{ width: 'auto' }}
            />
          )}
        </Grid>

        <Grid item>
          <CustomButton title={stepState.currentStep < 2 ? 'Next' : 'Reveal Products'} onClick={handleNext} sx={{ width: 'auto' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavigationTabs;
