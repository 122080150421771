const findIcon = (type, iconArray, iconName) => {
  const icon = iconArray.find((icon) => icon.name.toLowerCase() === iconName.toLowerCase());
  if (type === 'mood') {
    return icon ? icon.selected : null; // assuming you want to use the selected icon
  } else {
    return icon ? icon.unselected : null; // assuming you want to use the unselected icon
  }
};

function convertFilters(originalFilters) {
  const newFilters = {
    page: originalFilters.page || 1,
    pageSize: originalFilters.pageSize || 10
  };

  // Add medicalOnly only if it exists in the original filters
  if ('medicalOnly' in originalFilters) {
    newFilters.medicalOnly = originalFilters.medicalOnly;
  }

  // Purchase Type
  if ('Purchase Type' in originalFilters) {
    newFilters.medicalOnly = originalFilters['Purchase Type'] === 'medical';
  }

  // Add Customize mood related filters only if they exist
  if (originalFilters['Customize mood']) {
    if (originalFilters['Customize mood'][0] !== undefined) {
      newFilters.blissFilter = originalFilters['Customize mood'][0];
    }
    if (originalFilters['Customize mood'][1] !== undefined) {
      newFilters.energyFilter = originalFilters['Customize mood'][1];
    }
    if (originalFilters['Customize mood'][2] !== undefined) {
      newFilters.focusFilter = originalFilters['Customize mood'][2];
    }
    if (originalFilters['Customize mood'][3] !== undefined) {
      newFilters.relaxationFilter = originalFilters['Customize mood'][3];
    }
  }

  // Add other filters only if they exist
  if (originalFilters['Product Type']) {
    if (originalFilters['Product Type'] === 'Concentrates') {
      newFilters.productType = 'Concentrate';
    } else if (originalFilters['Product Type'] === 'Edibles & Beverage') {
      newFilters.productType = 'Edibles';
    } else if (originalFilters['Product Type'] === 'Vaporizors') {
      newFilters.productType = 'Vape';
    } else if (originalFilters['Product Type'] === 'All') {
      newFilters.productType = '';
    } else {
      newFilters.productType = originalFilters['Product Type'];
    }
  }

  if (originalFilters['Brand']) {
    newFilters.brand = originalFilters['Brand'];
  }
  if (originalFilters['Category']) {
    newFilters.rightMood = originalFilters['Category'];
  }
  if (originalFilters['Activity']) {
    newFilters.activity = originalFilters['Activity'];
  }
  if (originalFilters['Wellness']) {
    newFilters.medicinal = originalFilters['Wellness'];
  }
  if (originalFilters['search']) {
    newFilters.search = originalFilters['search'];
  }
  if (originalFilters['Power Leaf Analyzed']) {
    newFilters.powerLeafAnalyzed = true;
  }
  if (originalFilters['Cannabinoids']) {
    newFilters.cannabinoids = originalFilters['Cannabinoids'];
  }
  if (originalFilters['Terpenes']) {
    newFilters.terpene = originalFilters['Terpenes'];
  }
  if (originalFilters['sort']) {
    if (originalFilters['sort'] === `${originalFilters['Terpenes']}_low` || originalFilters['sort'] === `${originalFilters['Terpenes']}_high`) {
      newFilters.terpene = originalFilters['sort'];
    }

    if (originalFilters['sort'] === `${originalFilters['Cannabinoids']}_low` || originalFilters['sort'] === `${originalFilters['Cannabinoids']}_high`) {
      newFilters.cannabinoids = originalFilters['sort'];
    }

    newFilters.sort = originalFilters['sort'];
  } else {
    newFilters.sort = 'low';
  }

  return newFilters;
}

export { convertFilters, findIcon };

