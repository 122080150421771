// hooks/useFetchProductData.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProductData } from '../store/productDetails/actions/productActions';

const useFetchProductData = (id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchProductData(id));
    }
  }, [dispatch, id]);
};

export default useFetchProductData;
