import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

// Consts
import { noProductsConsts } from '../../consts/shopByConsts';

// Styled component for titleOne
const StyledTitleOne = styled(Typography)({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '26px'
});

// Styled component for titleTwo
const StyledTitleTwo = styled(Typography)({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

function NoProducts() {
  return (
    <Grid
      mt={10}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      style={{ marginBottom: '5%' }}
    >
      <Grid item>{noProductsConsts.icon}</Grid>
      <Grid item>
        <StyledTitleOne variant="h6" align="center">
          {noProductsConsts.titleOne}
        </StyledTitleOne>
      </Grid>
      <Grid item>
        <StyledTitleTwo variant="body1" align="center">
          {noProductsConsts.titleTwo}
        </StyledTitleTwo>
      </Grid>
    </Grid>
  );
}

export default NoProducts;
