import { Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Mousewheel, Navigation, Scrollbar } from 'swiper/modules';
import FilterCard from '../cards/filterCard';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ShopByCategoryFilters = ({ filters, selectedId, setSelectedId }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const swiperRef = useRef(null);

  const HeaderSection = styled(Grid)(({ theme }) => ({
    position: 'relative',
    background: `url(${filters.backgroundImage}) no-repeat center center`,
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4, 1),
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.65)',
      mixBlendMode: 'multiply',
      pointerEvents: 'none',
      zIndex: -1,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  const swiperSettings = {
    breakpoints: {
      1400: {
        slidesPerView: filters.categories.length === 6 ? 6 : 7.2,
      },
      1300: {
        slidesPerView: filters.categories.length === 6 ? 6 : 6.6,
      },
      1100: {
        slidesPerView: 5.8,
      },
      1000: {
        slidesPerView: 5.2,
      },
      800: {
        slidesPerView: 4.2,
      },
      700: {
        slidesPerView: 3.8,
      },
      600: {
        slidesPerView: 3.2,
      },
      400: {
        slidesPerView: 2.1
      },
      200: {
        slidesPerView: 1.9
      }
    }
  };

  useEffect(() => {
    swiperRef.current.swiper.slideTo(selectedId - 1, 0);
  })

  return (
    <HeaderSection container>
      <Grid
        item
        xs={12}
        md={12}
        gap={isLargeScreen ? 8 : 2}
        // display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        style={{ marginLeft: isLargeScreen ? '12%' : '2%' }}
      >
        <Grid item>
          {/* <Typography textAlign='left' fontFamily='Space Grotesk' fontWeight={500} fontSize='18px' lineHeight='20px' marginBottom={1}>{filters.titleOne}</Typography> */}
          <Typography textAlign='left' fontFamily='Space Grotesk' fontWeight={700} fontSize='46px' lineHeight='42px'>{filters.titleTwo}</Typography>
        </Grid>
        <Grid item xs={9} style={{ marginTop: '10px' }}>
          <Typography textAlign='left' fontFamily='Roboto' fontWeight={400} fontSize='16px' lineHeight='22px'>{filters.description}</Typography>
          {
            filters.titleTwo === 'EXPLORE WELLNESS APPLICATIONS' && (
              <Typography textAlign='left' fontFamily='Roboto' fontWeight={400} fontSize='12px' lineHeight='16px' mt={2}><b>DISCLAIMER: </b> Cannabis is classified as a Schedule I contolled substance under federal law, and the United States government does not currently recognize any medicinal benefit from it&apos;s use. Suggested pairings are not meant to imply that products have any accepted medicinal role in the treatment or cure of a specific illness or disease.</Typography>
            )
          }
        </Grid>
      </Grid>

      <Grid container spacing={1} item mt={6} display={'flex'} justifyContent={'center'} sx={{ maxWidth: '1400px', overflow: 'hidden' }}>
        <Swiper
          {...swiperSettings}
          modules={[Scrollbar, Mousewheel, Navigation]}
          scrollbar={{ draggable: true }}
          mousewheel={true}
          direction='horizontal'
          navigation
          style={{
            "--swiper-navigation-color": "black",
          }}
          ref={swiperRef}
        >
          {filters.categories.map((category, index) => (
            <SwiperSlide key={index}>
              <Grid item key={category.id} style={{ marginBottom: '20px', marginLeft: '15px' }}>
                <FilterCard
                  title={category.title}
                  image={category.image}
                  onClick={() => setSelectedId(category.id)}
                  isSelected={selectedId === category.id}
                />
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </HeaderSection>
  );
};

export default ShopByCategoryFilters;
