import { Drawer, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import omit from 'lodash/omit';

import {
  NavTitle,
  NavigationLink,
  SearchTextField,
  StyledAppBar,
  StyledBadge,
  StyledClearIcon,
  StyledDivider,
  StyledIconButton,
  StyledInputAdornment,
  StyledLogo1,
  StyledLogo2,
  StyledLogoContainer,
  StyledMenuIcon,
  StyledSearchIcon,
  StyledShoppingCartIcon
} from './styledComponents';

import { NavigationType } from '../../../consts/app_enums';

import { selectSelectedFilters } from '../../../store/selectors/shopBySelectors';

import { fetchProductsLoadingAction, updateFiltersAction } from '../../../store/actions/shopByActions';
import PhoneNumberDialog from '../../phoneNumberDialog/phoneNumberDialog';
import HamburgerMenu from './Hamburger';

import HomeIcon from '@mui/icons-material/Home';
import { setPhoneDialogState } from '../../../store/actions/userActions';
import { selectItemsCount } from '../../../store/checkout/selectors/checkoutSelectors';
import { selectUserPhoneNumber } from '../../../store/selectors/userSelectors';

// Routes
import { appRoutes } from '../../../routes/appRoutes';
import { useFilterSelection } from '../../../hooks/useFilterSelection';
import Container from '../../Container';

const NavigationBar = ({ version, title }) => {
  // Actions
  const { handleResetFilters } = useFilterSelection();

  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Selectors
  const selectedFilters = useSelector(selectSelectedFilters);
  const phoneNumber = useSelector(selectUserPhoneNumber);
  const cartCount = useSelector(selectItemsCount);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  console.log({ isMobile });
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = () => {
    setMobileDrawerOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileDrawerOpen(false);
  };

  const handleClearSearchString = () => {
    setSearchString('');

    const updatedFilters = {
      filters: [],
      page: 1,
      pageSize: 10,
      search: ''
    };
    dispatch(fetchProductsLoadingAction(true));
    dispatch(updateFiltersAction(updatedFilters));
  };

  const handleEnterPress = (e) => {
    if (e.keyCode == 13) {
      // const prevSelections = 'Customize mood' in selectedFilters ? omit({ ...selectedFilters }, ['Customize mood']) : selectedFilters;
      const purchaseType = selectedFilters['Purchase Type'] ? selectedFilters['Purchase Type'] : 'adult';
      const updatedFilters = {
        page: 1,
        pageSize: 10,
        search: searchString,
        'Product Type': 'All',
        'Purchase Type': purchaseType
      };
      dispatch(fetchProductsLoadingAction(true));
      dispatch(updateFiltersAction(updatedFilters));
      navigate(appRoutes.search);
    }
  };

  useEffect(() => {
    if (selectedFilters?.search) {
      setSearchString(selectedFilters?.search);
    }
  }, [phoneNumber]);

  console.log({ version });
  return (
    <>
      <StyledAppBar version={version}>
        <Toolbar>
          {isMobile ? (
            <Container style={{ width: '95%', padding: 0 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <StyledLogo1 onClick={() => navigate('/home')} isMobile version={version} style={{ marginRight: 12 }} />
                <SearchTextField
                  style={{ margin: "0 0 0 16px", padding: 0 }}
                  version={version}
                  placeholder="Search for products..."
                  InputProps={{
                    startAdornment: (
                      <StyledInputAdornment position="start">
                        <StyledSearchIcon style={{ padding: 0 }} version={version} />
                      </StyledInputAdornment>
                    ),
                    style: { padding: 4, margin: 0 }
                  }}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  onKeyDown={handleEnterPress}
                  margin="none"
                />
                {title !== 'Confirmed' && (
                  <>
                    <HamburgerMenu anchorEl={menuAnchorEl} setAnchorEl={setMenuAnchorEl} />

                    <IconButton onClick={() => navigate(appRoutes.checkout)} style={{paddingRight: 0}}>
                      <StyledBadge
                        badgeContent={<b>{cartCount}</b>}
                        invisible={cartCount === 0 || cartCount === null ? true : false || version === NavigationType.V3}
                      >
                        <StyledShoppingCartIcon version={version} />
                      </StyledBadge>
                    </IconButton>
                  </>
                )}
              </div>
            </Container>
          ) : (
            <>
              <IconButton
                sx={{
                  marginLeft: -2,
                  marginRight: 1,
                  backgroundColor: version === 'Version 1' ? '#000' : '#F1EEEB',
                  color: version === 'Version 1' ? '#fff' : '#000',
                  '&:hover': {
                    backgroundColor: version === 'Version 1' ? '#000' : '#F1EEEB',
                    color: version === 'Version 1' ? '#fff' : '#000'
                  }
                }}
                onClick={() => navigate('/home')}
              >
                <HomeIcon
                  sx={{
                    backgroundColor: version === 'Version 1' ? '#000' : '#F1EEEB',
                    color: version === 'Version 1' ? '#fff' : '#000',
                    '&:hover': {
                      backgroundColor: version === 'Version 1' ? '#000' : '#F1EEEB',
                      color: version === 'Version 1' ? '#fff' : '#000'
                    }
                  }}
                />
              </IconButton>

              <StyledLogoContainer onClick={() => navigate(appRoutes.home)}>
                <StyledLogo1 version={version} />
                <StyledDivider version={version} orientation="vertical" flexItem sx={{ mx: 2, borderColor: 'transparent' }} />
                <StyledLogo2 version={version} />
              </StyledLogoContainer>

              <NavTitle version={version}>{title}</NavTitle>

              <SearchTextField
                version={version}
                placeholder="What can we help you find?"
                InputProps={{
                  startAdornment: (
                    <StyledInputAdornment position="start">
                      <StyledSearchIcon version={version} />
                    </StyledInputAdornment>
                  ),
                  endAdornment:
                    searchString !== '' ? (
                      <StyledInputAdornment position="start">
                        <StyledClearIcon version={version} onClick={handleClearSearchString} />
                      </StyledInputAdornment>
                    ) : (
                      <></>
                    )
                }}
                // defaultValue={selectedFilters?.search ? selectedFilters?.search : ''}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                onKeyDown={handleEnterPress}
              />

              {title !== 'Confirmed' && (
                <Typography
                  style={{ cursor: 'pointer', color: version === NavigationType.V1 ? '#fff' : '#000' }}
                  marginRight={1}
                  marginLeft={version === NavigationType.V1 ? 0 : 3}
                  onClick={() => {
                    dispatch(setPhoneDialogState(true));
                  }}
                >
                  Ph: {phoneNumber}
                </Typography>
              )}

              {title !== 'Confirmed' && (
                <>
                  <NavigationLink onClick={handleMenuOpen} version={version}>
                    Menu
                  </NavigationLink>
                  <HamburgerMenu anchorEl={menuAnchorEl} setAnchorEl={setMenuAnchorEl} />
                  {/* <NavigationLink version={version}>The Science</NavigationLink>
            <NavigationLink version={version}>Contact Us</NavigationLink> */}

                  <IconButton onClick={() => navigate(appRoutes.checkout)}>
                    <StyledBadge
                      badgeContent={<b>{cartCount}</b>}
                      invisible={cartCount === 0 || cartCount === null ? true : false || version === NavigationType.V3}
                    >
                      <StyledShoppingCartIcon version={version} />
                    </StyledBadge>
                  </IconButton>
                </>
              )}
            </>
          )}
        </Toolbar>
      </StyledAppBar>

      <PhoneNumberDialog />
    </>
  );
};

export default NavigationBar;
