import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { getMoodColors } from '../../../../config/consts';

const CannabinoidName = styled(Typography)(({ theme, isReport }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Inter',
  fontSize: isReport ? '11px' : '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  display: 'inline'
}));

const CannabinoidValue = styled(Typography)(({ theme, isReport }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Inter',
  fontSize: isReport ? '11px' : '14px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  display: 'inline',
  marginLeft: theme.spacing(1)
}));

const BarContainer = styled('div')(({ theme, chemoName, isReport }) => ({
  height: isReport ? '8px' : '18px',
  backgroundColor: `${getMoodColors(chemoName).bgColor}`,
  borderRadius: '5px',
  position: 'relative',
  marginBottom: isReport ? '8px' : '22px'
}));

const Indicator = styled('div')(({ theme, value, chemoName, isReport }) => ({
  width: '14px',
  height: isReport ? '8px' : '18px',
  backgroundColor: `${getMoodColors(chemoName).color}`,
  position: 'absolute',
  left: `calc(${value}%)`,
  borderRadius: '2px'
}));

const BarLegend = styled(Box)(({ theme, isReport }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
}));

const ProductCannabinoids = (productType) => {
  if (productType === `Flower - Prepackaged`) {
    return [`cbca`, `cbga`, `cbda`, `cbdva`, `thca`, `thcva`, `delta9thc`, `delta8thc`]
  } else if (productType === `Concentrate`) {
    return [`cbca`, `cbga`, `cbda`, `cbdva`, `thca`, `thcva`, `delta9thc`, `delta8thc`]
  } else if (productType === `Edibles`) {
    return [`cbc`, `cbg`, `cbd`, `cbdv`, `thcv`, `delta9thc`, `delta8thc`]
  } else if (productType === `Topicals`) {
    return [`cbc`, `cbg`, `cbd`, `cbdv`, `thcv`, `delta9thc`, `delta8thc`]
  } else {
    return [`thca`, `thcva`, `cbda`, `cbda`, `cbga`, `cbca`, `cbn`]
  }
}

const CannabinoidsGraph = ({ cannabinoidData, chemoName, isReport = false }) => {
  const entries = ProductCannabinoids(cannabinoidData?.productType);

  return (
    <Box sx={{ pr: { xs: 0, md: 10 }, mt: isReport ? -3 : 0 }}>
      <Typography variant="h4" gutterBottom fontSize={isReport ? '13px' : '18px'} fontFamily={"Inter"} fontWeight={"700"}>
        Cannabinoids
      </Typography>

      {Object.entries(cannabinoidData?.cannabinoids).map(([key, value]) => (
        <Box key={key}>
          <CannabinoidName isReport={isReport}>{key.toUpperCase()}:</CannabinoidName>
          <CannabinoidValue isReport={isReport}>{value ? parseFloat(value)?.toFixed(1) : value === null ? 0 : value}%</CannabinoidValue>
          <BarContainer chemoName={chemoName} isReport={isReport}>
            <Indicator value={cannabinoidData?.zCannabinoids[key] >= 5 ? 97 : cannabinoidData?.zCannabinoids[key] <= -5 ? 0 : (50 / 5 * cannabinoidData?.zCannabinoids[key]) + 50} chemoName={chemoName} isReport={isReport} />
          </BarContainer>
        </Box>
      ))}

      {/* {
        entries.map((entry, index) => {
          // console.log(entry, " ", index);
          return (
            <Box key={index}>
              <CannabinoidName isReport={isReport}>{entry.toUpperCase()}:</CannabinoidName>
              <CannabinoidValue isReport={isReport}>{cannabinoidData?.cannabinoids[entry] ? parseFloat(cannabinoidData?.cannabinoids[entry])?.toFixed(1) : cannabinoidData?.cannabinoids[entry] === null ? 0 : cannabinoidData?.cannabinoids[entry]}%</CannabinoidValue>

              <BarContainer chemoName={chemoName} isReport={isReport}>
                <Indicator value={cannabinoidData?.zCannabinoids[entry] >= 5 ? 97 : cannabinoidData?.zCannabinoids[entry] <= -5 ? 0 : (50 / 5 * cannabinoidData?.zCannabinoids[entry]) + 50} chemoName={chemoName} isReport={isReport} />
              </BarContainer>
            </Box>
          )
        })
      } */}

      <BarLegend isReport={isReport}>
        <Typography>Low</Typography>
        <Typography>Typical</Typography>
        <Typography>High</Typography>
      </BarLegend>
    </Box>
  );
};

export default CannabinoidsGraph;
