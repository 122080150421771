import React from 'react';
import { getMoodColors } from '../../../config/consts';

const SleepSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="60" height="60" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.96229 1.29556C10.1398 1.19309 10.3584 1.19309 10.5359 1.29556L17.644 5.39944C17.8215 5.50191 17.9308 5.69127 17.9308 5.89621V14.104C17.9308 14.3089 17.8215 14.4983 17.644 14.6007L10.5359 18.7046C10.3584 18.8071 10.1398 18.8071 9.96229 18.7046L2.85417 14.6007C2.67669 14.4983 2.56736 14.3089 2.56736 14.104V5.89621C2.56736 5.69127 2.67669 5.5019 2.85417 5.39944L9.96229 1.29556Z" stroke={color} strokeWidth="0.382413" />
      <path d="M7.95871 10.1969C8.64466 10.1969 9.19838 9.66905 9.19838 9.01511C9.19838 8.36117 8.64466 7.83329 7.95871 7.83329C7.27276 7.83329 6.71904 8.36117 6.71904 9.01511C6.71904 9.66905 7.27276 10.1969 7.95871 10.1969ZM12.9174 7.83329H9.6116V10.5909H6.30582V7.04541H5.47937V12.9545H6.30582V11.7727H13.7438V12.9545H14.5703V9.40905C14.5703 8.53844 13.8306 7.83329 12.9174 7.83329Z" fill={color} />
    </svg>
  );
};

export default SleepSVG;
