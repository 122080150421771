
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material';

// Custom styled components
const CustomSlider = styled(Slider)(({ theme }) => ({
  width: 280,
  marginLeft: '20px',
  height: 8,
  color: 'black', // This is for the thumb
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: 'black', // Thumb color
    border: '2px solid white' // Thumb border
  },
  '& .MuiSlider-track': {
    backgroundColor: 'black' // Track color
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'black' // Default Track color when slider hasn't been activated
  }
}));

const StyledDrawerBox = styled(Box)({
  width: 380,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '12px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderBottom: '1px solid black'
});

const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  alignItems: 'center'
});

const StyledTypography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '15px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.15px'
});

const StyledFiltersBox = styled(Box)({
  padding: '10px 0',
  borderTop: '1px solid black',
  border: '1px solid black',
  borderRadius: '5px'
});

const StyledFiltersBoxParent = styled(Box)({
  padding: '30px 0',
  gap: 30,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 4
});

const StyledActiveFiltersTypography = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px', // 133.333%
  letterSpacing: '0.155px'
});

const StyledClearAllTypography = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer',
  alignSelf: 'center',
  color: 'black',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

const StyledSliderTypography = styled(Typography)({
  color: 'black',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

const StyledOptionsTypography = styled(Typography)({
  color: 'black',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

const StyledChip = styled(Chip)({
  height: 'auto',
  width: '220px',
  justifyContent: 'space-between',
  gap: '10px',
  borderRadius: '2px',
  border: '2px solid black',
	backgroundColor: 'white',
   color: 'black',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

export {
    CustomSlider,
    StyledDrawerBox,
    StyledAccordionSummary,
    StyledTypography,
    StyledFiltersBox,
    StyledFiltersBoxParent,
    StyledActiveFiltersTypography,
    StyledClearAllTypography,
    StyledSliderTypography,
    StyledOptionsTypography,
    StyledChip
}