import { LinearProgress, Button as MuiButton, Paper as MuiPaper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getMoodColors } from '../../../../config/consts';

const ProductName = styled(Typography)(({ theme }) => ({
  color: '#1d1b20',
  fontSize: '32px',
  fontStyle: 'normal',
  fontFamily: 'Space Grotesk',
  fontWeight: 500,
  lineHeight: '42px',
  marginBottom: '10px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    fontSize: '26px',
    lineHeight: '30px'
  }
}));

const AnalyzerText = styled(Typography)`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  line-height: 13.5px;
  letter-spacing: 0.155px;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const OfferedByText = styled(Typography)`
  color: #1d1b20;
  font-size: 15px;
  font-style: normal;
  font-family: Inter;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.3px;
  text-decoration-line: underline;
  margin-top: 10px;
`;

const DescriptionText = styled(Typography)({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  fontFamily: 'Inter'
});

const ExeperienceCardBackground = styled(MuiPaper)(({ theme, isreport = false, chemoname }) => ({
  display: 'flex',
  alignItems: isreport ? 'start' : 'center',
  justifyContent: isreport ? 'center' : 'start',
  padding: '20px',
  borderRadius: '5px',
  border: `1px solid ${getMoodColors(chemoname).color}`,
  background: `${getMoodColors(chemoname).bgColor}`,
  borderBottom: `6px solid ${getMoodColors(chemoname).color}`,
  marginBottom: '16px',
  height: isreport ? '90%' : '116px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0 4px 20px 0 rgba(3, 9, 114, 0.25)'
  }
}));

const CustomLinearProgress = styled(LinearProgress)(({ theme, isReport = false, isMiniReport = false, chemoname }) => ({
  height: isMiniReport ? 4 : isReport ? 10 : 18,
  borderRadius: 2,
  backgroundColor: `${getMoodColors(chemoname).bgColor}`,
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 2,
    backgroundColor: `${getMoodColors(chemoname).color}`
  }
}));

const OptionsQTYContainer = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2.5),

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: 0,
    width: '80%',
    height: '280px',
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    borderRadius: '2px',
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.20)'
  },
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    padding: theme.spacing(2),
    border: 'none',
    boxShadow: 'none'
  }
}));

const OptionButton = styled(MuiButton)(({ theme, selected }) => ({
  // border: '1px solid black',
  backgroundColor: selected ? 'black' : 'white',
  color: selected ? 'white' : 'black',
  width: '100%',
  border: selected ? '2px solid transparent' : '1px solid black ',
  borderImage: selected ? 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)' : 'none',
  borderImageSlice: 1,
  boxShadow: selected ? theme.shadows[3] : 'none',
  '&:hover': {
    backgroundColor: selected ? 'black' : 'white',
    color: selected ? 'white' : 'black',
    border: selected ? '2px solid transparent' : '1px solid black ',
    borderImage: selected ? 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)' : 'none',
    borderImageSlice: 1
  }
}));

export {
  AnalyzerText, CustomLinearProgress, DescriptionText,
  ExeperienceCardBackground, OfferedByText, OptionButton, OptionsQTYContainer, ProductName
};

