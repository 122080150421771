import React from 'react';
import { Grid } from '@mui/material';
import NavBar from '../../components/bars/navigationBar/navigationBar';
import Footer from '../../components/bars/Footer/Footer';
import { NavigationType } from '../../consts/app_enums';

const AppLayout = ({ version = NavigationType.V1, title, children }) => {
  return (
    <React.Fragment>
      <NavBar version={version} title={title} />
      {children}
      <Footer />
    </React.Fragment>
  );
};
export default AppLayout;
