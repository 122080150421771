import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { fetchProductsAction, fetchProductsLoadingAction } from '../store/actions/shopByActions';

// Selectors
import { selectProducts, selectedProducsLoading, selectSelectedFilters } from '../store/selectors/shopBySelectors';

const useFetchProducts = (initialFetch = true) => {
  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const products = useSelector(selectProducts);
  const loading = useSelector(selectedProducsLoading);
  const filterState = useSelector(selectSelectedFilters);

  // Refs
  const isInitialMount = useRef(true);

  // Handlers
  const fetchData = async () => {
    dispatch(fetchProductsAction());
  };

  // UseEffects
  useEffect(() => {
    if (!initialFetch) return;
    dispatch(fetchProductsLoadingAction(true));
    fetchData();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false; // Update the flag after the first render
    } else {
      fetchData();
    }
  }, [filterState]);

  return {
    loading,
    products
  };
};

export default useFetchProducts;
