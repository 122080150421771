import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import CustomButton from '../../../components/buttons/custombutton';

import { Box, Grid, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useCheckPurchaseLimit from '../../../hooks/useCheckPurchaseLimit';
import { resetPurchaseLimitState } from '../../../store/checkout/actions/checkoutActions';
import { selectCartType, selectItemsCount, selectItemsPrice, selectOrderTotal } from '../../../store/checkout/selectors/checkoutSelectors';

const calculateTax = (orderTotal, taxType) => {
  if (taxType === 'adult') {
    return 0.2 * orderTotal;
  } else {
    return 0.07 * orderTotal;
  }
};

const OrderSummaryCard = () => {
  const itemsCount = useSelector(selectItemsCount);
  const itemsPrice = useSelector(selectItemsPrice);
  const orderTotal = useSelector(selectOrderTotal);
  const taxType = useSelector(selectCartType);
  const orderTax = calculateTax(orderTotal, taxType);
  const { handleCheckPurchaseLimit } = useCheckPurchaseLimit();
  const loading = useSelector((state) => state.checkout.purchaseLimit.loading);
  const errorMessage = useSelector((state) => state.checkout.purchaseLimit.errorMessage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetPurchaseLimitState());
  }, [dispatch]);
  return (
    <Box m={0} p={1}>
      <Paper style={{ padding: '1em 2em' }} sx={{ boxShadow: 3 }}>
        <Typography style={{ marginBottom: '1.2em' }} variant="h4" gutterBottom>
          Order Summary
        </Typography>

        <Grid container style={{ marginBottom: '0.5em' }} spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Items ({itemsCount})</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">$ {itemsPrice.toFixed(2)}</Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '0.5em' }} spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Tax {taxType === 'adult' ? '(20%)' : '(7%)'}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">$ {orderTax.toFixed(2)}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ borderBottomWidth: 2 }} style={{ borderColor: '#000000' }} />

        <Grid
          container
          style={{ marginTop: '1.5em', marginBottom: '1.5em' }}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">Order Total</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h3">$ {(orderTotal + orderTax).toFixed(2)}</Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: '1.5em', marginBottom: '1.5em' }} spacing={2}>
          <Grid item xs={12}>
            <CustomButton title={'PROCEED TO ORDER'} onClick={handleCheckPurchaseLimit} loading={loading} />
            {errorMessage && (
              <Typography color="error" mt={1}>
                {errorMessage}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <CustomButton title={'CONTINUE SHOPPING'} onClick={() => { navigate("/home") }} loading={loading} sx={{ backgroundColor: '#fff', color: '#000', '&:hover': { backgroundColor: '#fff' }, }} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default OrderSummaryCard;
