// Mood
import BackgroundImage from "../assets/images/shopBy/background/background.png";
import ElevateImage from '../assets/images/shopBy/moods/elevate.png';
import IndulgeImage from '../assets/images/shopBy/moods/indulge.jpg';
import MotivateImage from '../assets/images/shopBy/moods/motivate.png';
import RecoverImage from '../assets/images/shopBy/moods/recover.jpg';
import RelaxImage from '../assets/images/shopBy/moods/Relax.jpg';
import SleepImage from '../assets/images/shopBy/moods/sleep.png';

// Activity
import CreativityImage from '../assets/images/shopBy/activity/Creativity.png';
import DiningImage from '../assets/images/shopBy/activity/Dining.png';
import EntertainmentImage from '../assets/images/shopBy/activity/Entertainment.png';
import ExerciseImage from '../assets/images/shopBy/activity/Exercise.png';
import IntimactyImage from '../assets/images/shopBy/activity/Intimacy.png';
import MindfulnessImage from '../assets/images/shopBy/activity/Mindfulness.png';
import MobilityImage from '../assets/images/shopBy/activity/Mobility.png';
import OutdoorsImage from '../assets/images/shopBy/activity/Outdoors.png';
import SleepActivityImage from '../assets/images/shopBy/activity/Sleep.png';
import SocializingImage from '../assets/images/shopBy/activity/Socializing.png';

// Symptoms
import AppetiteImage from '../assets/images/shopBy/symptoms/Appetite.png';
import FatigueImage from '../assets/images/shopBy/symptoms/Fatigue.png';
import FocusImage from '../assets/images/shopBy/symptoms/Focus.png';
import InflamationImage from '../assets/images/shopBy/symptoms/Inflamation.png';
import MoodImage from '../assets/images/shopBy/symptoms/Mood.png';
import NauseaImage from '../assets/images/shopBy/symptoms/Nausea.png';
import PainImage from '../assets/images/shopBy/symptoms/Pain.png';
import SleepImageSymptoms from '../assets/images/shopBy/symptoms/Sleep.png';
import StressImage from '../assets/images/shopBy/symptoms/Stress.png';

import ExerciseIcon from '../assets/images/icons/exercise.png';
import WaterMelonGummies from '../assets/images/products/product.png';

import { ReactComponent as NoProductsIcon } from '../assets/svgs/noProductsIcon.svg';

const moodConsts = {
  titleOne: 'Find a strain to get you in the',
  titleTwo: 'EXPLORE BY CATEGORY',
  description:
    'There is no "one size fits all" when it comes to cannabis, but we can get you started on the right track. Browse our chemotype categories to explore how different product ingredients make you feel.',
  backgroundImage: BackgroundImage,
  categories: [
    {
      id: 1,
      title: 'recover',
      image: RecoverImage
    },
    {
      id: 2,
      title: 'elevate',
      image: ElevateImage
    },
    {
      id: 3,
      title: 'motivate',
      image: MotivateImage
    },
    {
      id: 4,
      title: 'relax',
      image: RelaxImage
    },
    {
      id: 5,
      title: 'indulge',
      image: IndulgeImage
    },
    {
      id: 6,
      title: 'sleep',
      image: SleepImage
    }
  ]
};

const productConsts = [
  {
    id: 1,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 2,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 3,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 4,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 5,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 6,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 7,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 8,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 9,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 10,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 11,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  },
  {
    id: 12,
    name: 'Focus Watermelon Gummies',
    price: 22.0,
    image: WaterMelonGummies,
    properties: ['Plant Based', 'Powerleaf Analyzed'],
    border_color: '#4CAF50',
    icons: [
      { icon: ExerciseIcon, title: 'Worry' },
      { icon: ExerciseIcon, title: 'Recovery' },
      { icon: ExerciseIcon, title: 'Appetite' }
    ]
  }
];

const activityConsts = {
  titleOne: 'Pair your cannabis with',
  titleTwo: 'PAIR PRODUCTS WITH ACTIVITY',
  description:
    'Brain chemistry influences our performance and enjoyment of various activities. Pair your favourite cannabis-enchanced activities with products that promote your go-to vibe!',
  backgroundImage: BackgroundImage,
  categories: [
    {
      id: 1,
      title: 'creativity',
      image: CreativityImage
    },
    {
      id: 2,
      title: 'entertainment',
      image: EntertainmentImage
    },
    {
      id: 3,
      title: 'exercise',
      image: ExerciseImage
    },
    {
      id: 4,
      title: 'dining',
      image: DiningImage
    },
    {
      id: 5,
      title: 'intimacy',
      image: IntimactyImage
    },
    {
      id: 6,
      title: 'mindfulness',
      image: MindfulnessImage
    },
    {
      id: 7,
      title: 'mobility',
      image: MobilityImage
    },
    {
      id: 8,
      title: 'relaxation',
      image: RelaxImage
    },
    {
      id: 9,
      title: 'sleep',
      image: SleepActivityImage
    },
    {
      id: 10,
      title: 'socializing',
      image: SocializingImage
    },
    {
      id: 11,
      title: 'outdoors',
      image: OutdoorsImage
    },
  ]
}

const symptomsConsts = {
  titleOne: 'Help Alleviate',
  titleTwo: 'EXPLORE WELLNESS APPLICATIONS',
  description: 'Smart product pairings for potential relief, based on product ingredients.',
  backgroundImage: BackgroundImage,
  categories: [
    {
      id: 1,
      title: 'appetite',
      image: AppetiteImage
    },
    {
      id: 2,
      title: 'energy',
      image: FatigueImage
    },
    {
      id: 3,
      title: 'focus',
      image: FocusImage
    },
    // {
    //   id: 4,
    //   title: 'inflammation',
    //   image: InflamationImage
    // },
    {
      id: 5,
      title: 'mood',
      image: MoodImage
    },
    {
      id: 6,
      title: 'nausea',
      image: NauseaImage
    },
    {
      id: 7,
      title: 'discomfort',
      image: PainImage
    },
    {
      id: 8,
      title: 'rest',
      image: SleepImageSymptoms
    },
    {
      id: 9,
      title: 'stress',
      image: StressImage
    },
    // {
    //   id: 10,
    //   title: 'recovery',
    //   image: RecoverImageSymptoms
    // }
  ]
}

const noProductsConsts = {
  titleOne: 'We’re sorry. No products were found.',
  titleTwo: 'Try adjusting your filter selections.',
  icon: <NoProductsIcon />
}

export {
  activityConsts, moodConsts, noProductsConsts, productConsts, symptomsConsts
};
