// HamburgerMenu.jsx
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Link, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import Grow from '@mui/material/Grow';
import Menu from '@mui/material/Menu';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CustomizeExperiencePNG from '../../../assets/icons/navigationBar/customize-image.png';
import { menuData } from '../../../consts/navConsts';

// Actions
import { resetFiltersAction, updateFiltersAction } from '../../../store/actions/shopByActions';

// Selectors

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '700px',
    backgroundColor: theme.palette.background.paper,
    padding: '50px',
    position: 'relative',
    maxWidth: '60vw',
    minHeight: '620px'
  }
}));

const ColumnTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '18px',
  paddingBottom: '9px',
  cursor: 'pointer',
}));

const ColumnList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}));

const CustomLink = styled(Link)(({ theme }) => ({
  margin: theme.spacing(0.5, 0),
  fontSize: '14px',
  fontWeight: '500',
  cursor: 'pointer',
  color: '#000',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const StyledCustomizeExperiencePNG = styled('img')({
  position: 'absolute',
  right: 0,
  bottom: -79,
  maxWidth: '50%',
  height: 'auto',
  // '&:hover': {
  //   cursor: 'pointer'
  // }
});

const HamburgerMenu = ({ anchorEl, setAnchorEl }) => {
  // Navigation
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isLg = useMediaQuery(theme.breakpoints.down('xl'));
  const isXl = useMediaQuery(theme.breakpoints.down('xxl'));

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (link, route, title) => {
    setAnchorEl(null);
    dispatch(resetFiltersAction());

    let newFilters = {
      page: 1,
      pageSize: 10
    };

    if (title === 'Product Type') {
      newFilters[title] = link;
    }
    else if (link !== null) {
      if (title === 'Wellness Applications') {
        newFilters['Wellness'] = link.toLowerCase();
      } else {
        newFilters[title] = link.toLowerCase();
      }
    }

    dispatch(updateFiltersAction(newFilters));

    navigate(route);
  };

  return (
    <StyledMenu
      id="hamburger-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      // MenuListProps={{
      //   onMouseLeave: handleClose
      // }}
      transitionDuration={500}
      TransitionComponent={Grow}
    >
      <IconButton onClick={handleClose} style={{ position: 'absolute', right: -30, top: -30, zIndex: 1300 }}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={6}>
        {Object.entries(menuData).map(([key, { title, svg, links, route }]) => (
          <Grid item xs={3} key={key}>
            <ColumnTitle onClick={() => handleNavigation(null, route, title)}>{title}</ColumnTitle>
            <ColumnList>
              <img src={svg} alt={'activities'} />
              {links.map((link, index) => (
                <CustomLink
                  key={index}
                  onClick={() => handleNavigation(link, route, title)}
                  sx={{ marginTop: index === 0 ? '14px' : undefined }}
                >
                  {link}
                </CustomLink>
              ))}
            </ColumnList>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          top: isLg ? '78%' : '72%',
          left: isLg ? '50%' : '50%',
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            cursor: "pointer",
          }
        }}
        onClick={() => navigate('/shop/perfectMatch')}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 700,
            fontFamily: 'Space Grotesk',
            lineHeight: '34px',
          }}
          display="inline"
        // sx={{ "&:hover": { cursor: 'pointer' } }}
        // onClick={() => navigate('/shop/perfectMatch')}
        >
          Customize Experience
        </Typography>

        <ArrowRightAltIcon style={{ width: '32px', height: 'auto' }} />
      </Box>

      <StyledCustomizeExperiencePNG src={CustomizeExperiencePNG} alt="Customized Experience" />
    </StyledMenu>
  );
};

export default HamburgerMenu;
