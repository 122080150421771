import React from 'react';
import ProductComposition from '../ProductDetails/Components/ProductComposition/ProductComposition';
// redux
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReportBottomBar from '../../components/bars/reportBottomBar/reportBottomBar';
import ReportTopBar from '../../components/bars/reportTopBar/reportBar';
import useFetchProductData from '../../hooks/useFetchProductData';
import ProductReportLayout from '../../layouts/ProductReportLayout/ProductReportLayout';
import { selectErrorState, selectLoadingState, selectProductData } from '../../store/productDetails/selectors/productSelectors';
import ProductReportShimmer from '../ProductDetails/Components/ProductDetailsShimmer/ProductDetailsShimmer';
import ProductReportExperience from './Components/ProductReportExperience/ProductReportExperience';
import ProductReportThumbnail from './Components/ProductReportThumbnail/ProductReportThumbnail';

export default function ProductReport() {
  const productData = useSelector(selectProductData);
  const loading = useSelector(selectLoadingState);
  const error = useSelector(selectErrorState);
  const { id } = useParams();
  useFetchProductData(id);

  console.log(productData);

  return (
    <>
      {loading ? (
        <ProductReportShimmer isReport={true} />
      ) : error ? (
        <Typography>Error: {error}</Typography>
      ) : productData ? (
        <>
          <ReportTopBar
            chemoName={productData.experience.logo.category}
            productType={productData?.composition?.cannabinoids?.productType}
          />
          <ProductReportLayout
            thumbnail={<ProductReportThumbnail thumbnailData={productData?.thumbnail} product={productData} />}
            experience={<ProductReportExperience experienceData={productData?.experience} />}
            composition={<ProductComposition compositionData={productData?.composition} isReport={true} />}
          />
          <ReportBottomBar qr={productData?.qr} chemoName={productData?.experience.logo.category} isReport={true} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
