// Services
import { fetchFilters, fetchProducts } from '../../network/services/shopByService';

// Action types
import {
  FETCH_FILTERS_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_LOADING,
  FETCH_PRODUCT_SCROLLING,
  FETCH_PRODUCT_SCROLLING_SUCCESS,
  RESET_FILTERS,
  RESET_PRODUCTS,
  UPDATE_FILTERS,
  UPDATE_TOTAL_PRODUCTS
} from '../consts/reduxActionTypes';

// Utils
import { convertFilters } from '../../utils/shopByUtils';

// Selectors
import { selectCartType } from '../checkout/selectors/checkoutSelectors';
import { selectProductsScrolling, selectSelectedFilters, selectedProducsLoading } from '../selectors/shopBySelectors';
import { selectUserTypeFilter } from '../selectors/userSelectors';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

// Actions
const fetchFiltersAction = () => async (dispatch) => {
  try {
    const response = await fetchFilters();
    dispatch({
      type: FETCH_FILTERS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
  }
};

const updateFiltersAction = (payload) => (dispatch, getState) => {
  const userType = selectUserTypeFilter(getState());
  const cartType = selectCartType(getState());
  const selectedFilters = selectSelectedFilters(getState());

  console.log('Payload', payload);

  var purchaseType = '';
  if (payload['Purchase Type']) {
    purchaseType = payload['Purchase Type'];
  } else if (selectedFilters['Purchase Type']) {
    purchaseType = selectedFilters['Purchase Type'];
  } else if (cartType) {
    purchaseType = cartType;
  } else {
    purchaseType = userType;
  }

  payload = {
    ...payload,
    'Purchase Type': purchaseType
  };

  try {
    mixpanel.track('Filters Used', { ...payload });
  } catch (error) {
    console.log(error);
  }

  dispatch({
    type: UPDATE_FILTERS,
    payload: payload
  });
};

const resetFiltersAction = () => (dispatch, getState) => {
  const userType = selectUserTypeFilter(getState());
  const cartType = selectCartType(getState());
  const selectedFilters = selectSelectedFilters(getState());

  var purchaseType = '';
  if (selectedFilters['Purchase Type']) {
    purchaseType = selectedFilters['Purchase Type'];
  } else if (cartType) {
    purchaseType = cartType;
  } else {
    purchaseType = userType;
  }

  const payload = {
    'Purchase Type': purchaseType,
    'Product Type': 'All'
  };

  dispatch({
    type: RESET_FILTERS,
    payload: payload
  });
};

const fetchProductsScrollingAction = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_SCROLLING, payload: payload });
};

const fetchProductsLoadingAction = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_LOADING, payload: payload });
};

const fetchProductsAction = () => async (dispatch, getState) => {
  const scrolling = selectProductsScrolling(getState());
  const loading = selectedProducsLoading(getState());

  const selectedFilters = selectSelectedFilters(getState());
  const payload = convertFilters(selectedFilters);

  try {
    const response = await fetchProducts(payload);
    if (loading) {
      dispatch({
        type: FETCH_PRODUCTS_SUCCESS,
        payload: response.data.products
      });
    }
    if (scrolling) {
      dispatch({
        type: FETCH_PRODUCT_SCROLLING_SUCCESS,
        payload: response.data.products
      });
    }
    dispatch({
      type: UPDATE_TOTAL_PRODUCTS,
      payload: response.data.pagination.totalCount
    });
  } catch (error) {
    console.log(error);
  } finally {
    if (loading) {
      dispatch(fetchProductsLoadingAction(false));
    }
    if (scrolling) {
      dispatch(fetchProductsScrollingAction(false));
    }
  }
};

const resetProductsAction = () => (dispatch) => {
  dispatch({
    type: RESET_PRODUCTS
  });
};

export {
  fetchFiltersAction,
  fetchProductsAction,
  fetchProductsLoadingAction,
  fetchProductsScrollingAction,
  resetFiltersAction,
  resetProductsAction,
  updateFiltersAction
};
