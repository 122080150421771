import React from 'react';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

const StyledButton = styled(MuiButton)(({ theme }) => ({
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  textDecoration: 'underline',
  width: '38px',
  flexShrink: 0
}));

const IconButton = ({ title, onClick, sx, icon }) => {
  return (
    <StyledButton startIcon={icon} onClick={onClick} sx={sx}>
      {title}
    </StyledButton>
  );
};

export default IconButton;
