// productDetails/actions/productActions.js

import ProductService from '../services/productService';

// UI imports
import CreativitySvg from '../../../assets/icons/productDetails/creativity.jsx';
import RecoverySvg from '../../../assets/icons/productDetails/intimacy.jsx';
import MindfullnessSvg from '../../../assets/icons/productDetails/mindfullness.jsx';
import ProductExperienceLogoSVG from '../../../assets/icons/productDetails/product_exp_logo.jsx';
import RelaxationSvg from '../../../assets/icons/productDetails/relax.jsx';
import { default as SleepSvg, default as StressSvg } from '../../../assets/icons/productDetails/sleep.jsx';
import { MedicinalLogosSVG } from "../../../utils/medicinalUtil";
import { ActivitiesLogosSVG } from "../../../utils/activitiesUtil.js";

export const FETCH_PRODUCT_DATA_PENDING = 'FETCH_PRODUCT_DATA_PENDING';
export const FETCH_PRODUCT_DATA_SUCCESS = 'FETCH_PRODUCT_DATA_SUCCESS';
export const FETCH_PRODUCT_DATA_FAILURE = 'FETCH_PRODUCT_DATA_FAILURE';
export const SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY';
export const SET_PRODUCT_WEIGHT = 'SET_PRODUCT_WEIGHT';

export const fetchProductDataPending = () => ({
  type: FETCH_PRODUCT_DATA_PENDING
});

export const fetchProductDataSuccess = (data) => ({
  type: FETCH_PRODUCT_DATA_SUCCESS,
  payload: data
});

export const fetchProductDataFailure = (error) => ({
  type: FETCH_PRODUCT_DATA_FAILURE,
  payload: error
});

export const setProductQuantity = (quantity) => ({
  type: SET_PRODUCT_QUANTITY,
  payload: quantity
});

export const setProductWeight = (weight) => ({
  type: SET_PRODUCT_WEIGHT,
  payload: weight
});

export const fetchProductData = (id) => async (dispatch) => {
  try {
    dispatch(fetchProductDataPending());
    const productData = await ProductService.fetchProductData(id);
    const transformedData = transformProductData(productData);
    dispatch(fetchProductDataSuccess(transformedData));
  } catch (error) {
    dispatch(fetchProductDataFailure(error.message));
  }
};

function transformProductData(apiResponse) {
  const productFromApi = apiResponse.data[0];
  // console.log(productFromApi);

  return {
    productId: productFromApi.id,
    isPowerleafAnalyzed: productFromApi.isPowerleafAnalyzed,
    recommended: productFromApi.recommended,
    thumbnail: {
      name: productFromApi.product,
      picture: productFromApi.imageURL,
      analyzedBy: productFromApi.isPowerleafAnalyzed ? 'PowerLeaf Analyzed' : 'Not Analyzed by Powerleaf',
      offeredBy: productFromApi.brand
    },
    experience: {
      description:
        'Different product ingredients activate different neurotransmitters in your body, which evoke different moods. We know this because we’ve analyzed the lab results. Based on the product ingredients in this product you should expect the experience described here.',
      filters: productFromApi.filters,
      logo: {
        picture: <ProductExperienceLogoSVG chemoName={productFromApi.chemoName.toUpperCase()} />,
        category: productFromApi.chemoName.toUpperCase(),
        effect: 'Sedative Effects'
      },
      activityPairings: [
        { icon: <ActivitiesLogosSVG activityName={productFromApi.activity.activity1?.toUpperCase()} chemoName={productFromApi?.chemoName?.toUpperCase()} width={"60"} height={"60"} />, label: productFromApi.activity.activity1 },
        { icon: <ActivitiesLogosSVG activityName={productFromApi.activity.activity2?.toUpperCase()} chemoName={productFromApi?.chemoName?.toUpperCase()} width={"60"} height={"60"} />, label: productFromApi.activity.activity2 },
        { icon: <ActivitiesLogosSVG activityName={productFromApi.activity.activity3?.toUpperCase()} chemoName={productFromApi?.chemoName?.toUpperCase()} width={"60"} height={"60"} />, label: productFromApi.activity.activity3 }
      ],
      medicinalUses: [
        { icon: <MedicinalLogosSVG medicinalName={productFromApi.medicinal.app1?.toUpperCase()} chemoName={productFromApi?.chemoName?.toUpperCase()} width={"60"} height={"60"} />, label: productFromApi.medicinal.app1 },
        { icon: <MedicinalLogosSVG medicinalName={productFromApi.medicinal.app2?.toUpperCase()} chemoName={productFromApi?.chemoName?.toUpperCase()} width={"60"} height={"60"} />, label: productFromApi.medicinal.app2 },
        { icon: <MedicinalLogosSVG medicinalName={productFromApi.medicinal.app3?.toUpperCase()} chemoName={productFromApi?.chemoName?.toUpperCase()} width={"60"} height={"60"} />, label: productFromApi.medicinal.app3 }
      ]
    },
    quantitySelection: {
      price: productFromApi.price,
      quantity: 1,
      quantityAvailable: productFromApi.quantity,
      weight: productFromApi.flowerEquiv || '0g',
      qtyOptions: [
        { weight: productFromApi.flowerEquiv || '0g' }
        //   { weight: '1g', price: 10.0 },
        //   { weight: '2g', price: 19.0 },
        //   { weight: '3g', price: 27.0 },
        //   { weight: '4g', price: 34.0 },
        //   { weight: '5g', price: 42.0 },
        //   { weight: '6g', price: 50.0 }
      ]
    },
    composition: {
      chemoName: productFromApi.chemoName.toUpperCase(),
      cannabinoids: {
        total: productFromApi.totalCannabinoids,
        totalZ: productFromApi.totalCannabinoidsZ,
        cannabinoids: productFromApi.cannabinoids,
        zCannabinoids: productFromApi.zcannabinoids,
        totalTHC: `THC: ${productFromApi.totalTHC}%`,
        productType: productFromApi.productType,
      },
      terpenes: {
        total: productFromApi.totalTerpenes,
        totalZ: productFromApi.totalTerpenesZ,
        terpenes: productFromApi.terpenes,
        profile: productFromApi.profile,
        totalTHC: `THC: ${productFromApi.totalTHC}%`,
        testedDate: productFromApi.testedDate === '' || productFromApi.testedDate === null ? "Not Available" : productFromApi.testedDate,
        harvestedDate: productFromApi.harvestedDate === '' || productFromApi.harvestedDate === null ? "Not Available" : productFromApi.harvestedDate
      }
    },
    cannabinoidsNotAnalyzed: productFromApi.cannabinoidsNotAnalyzed,
    terpenesNotAnalyzed: productFromApi.terpenesNotAnalyzed,
    qr: {
      qrCode: productFromApi.qr,
      qrUrl: productFromApi.qrUrl,
    },
    companyLogoUrl: productFromApi.logoURL,
  };
}
