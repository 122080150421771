import React from 'react';
import { styled } from '@mui/material/styles';
import { Popper, Paper, Typography, Button } from '@mui/material';

import CustomButton from '../buttons/custombutton';

import { ReactComponent as PopperLogo } from '../../assets/svgs/popperLogo.svg';

const getArrowStyles = (placement) => {
  switch (placement) {
    case 'top':
      return {
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid black'
      };
    case 'bottom':
      return {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid black'
      };
  }
};

const PopperPaper = styled(Paper)(({ placement }) => ({
  background: 'black',
  borderImage: `linear-gradient(285deg, #007FFF 10.6%, #030972 32.13%, #88B04B 54.01%, #FFC700 73.09%, #EB6E23 89.92%, #BF1932 110.69%)`,
  borderImageSlice: 1,
  borderStyle: 'solid',
  borderRadius: '1px',
  borderWidth: '1px',
  width: '212px',
  height: '250px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px',
  position: 'relative', // For the arrow
  '&:before': {
    // CSS for the arrow
    content: '""',
    position: 'absolute',
    ...getArrowStyles(placement)
  },
  marginBottom: '10px',
  zIndex: 1300
}));

const PopperTypography = styled(Typography)({
  color: '#FFF',
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

const CustomPopper = ({ open, anchorEl, handleClose, popperDetails, placement = 'top' }) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} sx={{ zIndex: 1300 }}>
      <PopperPaper elevation={3} placement={placement}>
        {popperDetails.icon}
        <PopperTypography>{popperDetails.description}</PopperTypography>
        <CustomButton onClick={handleClose} sx={{ marginTop: '20px' }} title={popperDetails.buttonTitle} />
      </PopperPaper>
    </Popper>
  );
};

export default CustomPopper;
