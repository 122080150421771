import { Box, Grid, styled } from '@mui/material';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ProductCard from '../cards/productCard';
import ProductLoadingCard from '../cards/productCardLoading';
import CartDialog from '../cartTypeDialog/cartTypeDialog';
import NoProducts from '../placeholders/noProducts';
import { debounce } from 'lodash';

// Selectors
import { selectProductsScrolling, selectSelectedFilters, selectTotalProducts } from '../../store/selectors/shopBySelectors';

// Actions
import { fetchProductsScrollingAction, updateFiltersAction } from '../../store/actions/shopByActions';

const StyledGridContainer = styled(Grid)({
  overflowX: 'auto',
  whiteSpace: 'nowrap'
});

const StyledOverlayText = styled('div')(({ theme }) => ({
  position: 'absolute', // Absolute position
  top: '50%', // Center vertically
  left: '50%', // Center horizontally
  transform: 'translate(-50%, -50%)', // Adjust to center from its own dimensions
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  color: theme.palette.text.primary, // You can adjust the color using theme
  zIndex: 2 // Ensure it's above other content
  // Add more styles as required
}));

const ProductsList = ({ products, loading, showOverlayText = false, onClick, isSearch = false }) => {
  // State
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // Dispatch
  const dispatch = useDispatch();

  // Selector
  const selectedFilters = useSelector(selectSelectedFilters);
  const totalProducts = useSelector(selectTotalProducts);
  const scrolling = useSelector(selectProductsScrolling);

  async function fetchProductsData(page) {
    dispatch(fetchProductsScrollingAction(true));
    const newFilters = {
      ...selectedFilters,
      page: selectedFilters.page + 1,
      pageSize: 10
    };

    dispatch(updateFiltersAction(newFilters));
    setIsFirstLoad(false);
  }

  const fetchData = debounce(fetchProductsData, 300);

  return (
    <Box overflowX={'hidden'}>
      <StyledGridContainer container justifyContent={'center'} display={'flex'} item spacing={2} mt={2}>
        {loading ? (
          [...Array(10)].map((_, index) => (
            <Grid item key={index}>
              <ProductLoadingCard />
            </Grid>
          ))
        ) : products.length === 0 ? (
          <NoProducts />
        ) : (
          <InfiniteScroll
            scrollThreshold={'10px'}
            loadMore={fetchData}
            hasMore={totalProducts > selectedFilters.page * selectedFilters.pageSize}
            loader={
              scrolling ? (
                <Grid display={'flex'} container gap={2} justifyContent={'center'} alignItems={'center'}>
                  {[...Array(10)].map((_, index) => (
                    <Grid item key={index}>
                      <ProductLoadingCard />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <></>
              )
            }
          >
            <Grid display={'flex'} container gap={isSearch ? 9 : 2} justifyContent={'center'} alignItems={'center'}>
              {/* {!showOverlayText && (
                <StyledOverlayText>
                  Adjust the sliders above and tap reveal your products to explore which products will achieve your desired effect.
                </StyledOverlayText>
              )} */}

              {products.map((product) => (
                <Grid item key={product.id}>
                  <ProductCard product={product} onClick={onClick} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
        <CartDialog />
      </StyledGridContainer>
    </Box>
  );
};

export default ProductsList;
