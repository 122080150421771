// reducers/checkoutReducer.js
import {
  ADD_TO_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  REMOVE_FROM_CART,
  SET_PAYMENT_DETAILS,
  SET_PAYMENT_METHOD,
  SET_COMPLETED_ORDER,
  REST_CART,
  RESET_COMPLETED_ORDER,
  SET_CART_TYPE,
  RESET_CART_TYPE,
  SET_CART_DIALOG,
  SET_PURCHASE_LIMIT_LOADING,
  PURCHASE_LIMIT_SUCCESS,
  PURCHASE_LIMIT_FAILURE,
  RESET_PURCHASE_LIMIT_STATE
} from '../actions/checkoutActions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  items: [],
  paymentDetails: {},
  completedOrder: {},
  cartType: null,
  cartDialog: false,
  purchaseLimit: {
    loading: false,
    errorMessage: ''
  }
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const itemToAdd = action.payload;
      const existingItemIndex = state.items.findIndex((item) => item.id === itemToAdd.id);

      if (existingItemIndex !== -1) {
        const updatedItems = state.items.map((item, index) => (index === existingItemIndex ? itemToAdd : item));

        return {
          ...state,
          items: updatedItems
        };
      } else {
        return {
          ...state,
          items: [...state.items, itemToAdd]
        };
      }
    }

    case INCREASE_QUANTITY: {
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.payload ? { ...item, quantity: item.quantity + 1 } : item))
      };
    }
    case DECREASE_QUANTITY: {
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.payload ? { ...item, quantity: Math.max(item.quantity - 1, 0) } : item))
      };
    }
    case REMOVE_FROM_CART: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload)
      };
    }

    case SET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          ...action.payload
        }
      };
    }

    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentMethod: action.payload
        }
      };
    }

    case SET_COMPLETED_ORDER: {
      return {
        ...state,
        completedOrder: action.payload
      };
    }

    case RESET_COMPLETED_ORDER: {
      return {
        ...state,
        completedOrder: {},
        cartType: null
      };
    }

    case REST_CART: {
      return {
        ...state,
        items: [],
        cartType: null
      };
    }

    case SET_CART_TYPE: {
      return {
        ...state,
        cartType: action.payload
      };
    }

    case RESET_CART_TYPE: {
      return {
        ...state,
        cartType: null
      };
    }

    case SET_CART_DIALOG: {
      return {
        ...state,
        cartDialog: action.payload
      };
    }
    case SET_PURCHASE_LIMIT_LOADING:
      return {
        ...state,
        purchaseLimit: {
          ...state.purchaseLimit,
          loading: action.payload
        }
      };
    case PURCHASE_LIMIT_SUCCESS:
      return {
        ...state,
        purchaseLimit: {
          loading: false,
          errorMessage: ''
        }
      };
    case PURCHASE_LIMIT_FAILURE:
      return {
        ...state,
        purchaseLimit: {
          loading: false,
          errorMessage: action.payload
        }
      };
    case RESET_PURCHASE_LIMIT_STATE:
      return {
        ...state,
        purchaseLimit: {
          ...initialState.purchaseLimit
        }
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'checkout',
  storage
};

export default persistReducer(persistConfig, checkoutReducer);
