import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { DescriptionText } from '../StyledComponents/StylesComponents';
import AromaProfile from './AromaProfile';
import { SemiCircularGaugeNew } from './ProgressMeter';

const Terpenes = ({ terpenesData, isReport = false, isMiniReport = false, chemoName }) => {
  return (
    <Box>
      {isReport ? (
        <Grid container alignItems="center" justifyContent={'space-between'} pr={0} spacing={0} mt={-6}>
          <Grid item>
            <Typography variant="h4" fontSize={'16px'} fontFamily={"Inter"} fontWeight={"700"}>
              Total
            </Typography>
            <Typography variant="h4" fontSize={'16px'} fontFamily={"Inter"} fontWeight={"700"}>
              Terpenes
            </Typography>
            <Typography variant="subtitle1" fontSize={'10px'} fontFamily={"Inter"} fontWeight={"500"}>
              % by weight
            </Typography>
          </Grid>
          <Grid item mr={3}>
            {/* <SemiCircularGaugeReport value={terpenesData.total} size={180} chemoName={chemoName} /> */}
            <SemiCircularGaugeNew value={parseFloat(terpenesData.total).toFixed(1)} valueZ={parseFloat(terpenesData.totalZ)?.toFixed(1)} label="% by weight" subLabel={terpenesData.totalTHC} chemoName={chemoName} type={'Terpenes'} width={'220px'} height={'120px'} isReport={true} />
          </Grid>
        </Grid>
      ) : (
        isMiniReport ?
          (
            <>
              <Grid container alignItems="center" justifyContent={'space-between'} spacing={0} pr={0} mt={0.2}>
                <Grid item xs={8}>
                  <Typography fontFamily='Inter' fontSize='8px' fontWeight={600}>
                    Total Terpenes
                  </Typography>
                  <Typography fontFamily='Space Grotesk' fontSize='12px' fontWeight={700} style={{ marginTop: '-5px' }}>
                    {parseFloat(terpenesData.total).toFixed(1)}*
                  </Typography>
                  <Typography color="#767676" fontFamily='Inter' fontSize='5px' fontWeight={400} style={{ marginTop: '-4px' }}>
                    * % by weight
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{ position: 'absolute', marginTop: '6px', marginLeft: '65px' }}>
                  {/* <SemiCircularGaugeMiniReport value={terpenesData.total} size={50} label="" subLabel="" chemoName={chemoName} /> */}
                  <SemiCircularGaugeNew value={parseFloat(terpenesData.total).toFixed(1)} valueZ={parseFloat(terpenesData.totalZ)?.toFixed(1)} label="% by weight" subLabel={terpenesData.totalTHC} chemoName={chemoName} type={'Terpenes'} width={'37px'} height={'20px'} isMiniReport={true} />
                </Grid>

                {/* <Grid item sx={{ objectFit: 'contain' }}>
                  <SemiCircularGaugeReport value={cannabinoids.total} subLabel="THC: 22.8%" chemoName={chemoName} />
                </Grid> */}
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="h4" mb={2} mt={1.6} fontFamily={"Space Grotesk"} fontWeight={700} fontSize={"22px"}>
                Total Terpenes
              </Typography>
              <DescriptionText mb={2} fontFamily={"Inter"} fontWeight={"500"} fontSize={"14px"}>
                Terpenes give cannabis products their distinctive flavor and aroma. They also effect mind and body functions.
              </DescriptionText>
              {/* <SemiCircularGauge value={terpenesData.total} size={300} label="% by weight" subLabel="THC: 22.8%" chemoName={chemoName} /> */}
              <SemiCircularGaugeNew value={parseFloat(terpenesData.total).toFixed(1)} valueZ={parseFloat(terpenesData.totalZ)?.toFixed(1)} label="% by weight" subLabel={terpenesData.totalTHC} chemoName={chemoName} type={'Terpenes'} width={'310px'} height={'160px'} />
            </>
          )
      )}

      {/* Aroma Profile (always displayed regardless of isReport) */}
      <AromaProfile terpenesData={terpenesData} isReport={isReport} isMiniReport={isMiniReport} />
    </Box>
  );
};

export default Terpenes;
