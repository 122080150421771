// src/useAnalytics.js
import { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { app } from './firebase';

const useAnalytics = (eventName, eventParams) => {
  useEffect(() => {
    if (eventName) {
      const analytics = getAnalytics(app);
      logEvent(analytics, eventName, eventParams);
    }
  }, [eventName, eventParams]);
};

export default useAnalytics;
