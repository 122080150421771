import React from 'react';
import { getMoodColors } from '../../../config/consts';

const MindfullnessSVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="60" height="60" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.8001C41 33.0173 32.7173 41.3001 22.5 41.3001C12.2827 41.3001 4 33.0173 4 22.8001C4 12.5828 12.2827 4.30005 22.5 4.30005C32.7173 4.30005 41 12.5828 41 22.8001Z"
        stroke={color}
      />
      <path
        d="M16.4569 33.5787C15.8458 33.5787 15.3264 33.3649 14.8986 32.9371C14.4708 32.5093 14.2569 31.9899 14.2569 31.3787C14.2569 30.951 14.3792 30.5487 14.6236 30.1718C14.8681 29.795 15.194 29.525 15.6014 29.3621L20.3681 27.4676V24.7176C19.2681 26.001 17.9898 26.9838 16.5333 27.6662C15.0769 28.3487 13.5032 28.6899 11.8125 28.6899V26.2454C13.1977 26.2454 14.4556 25.9602 15.5861 25.3899C16.7167 24.8195 17.7403 24.0047 18.6569 22.9454L20.3069 20.9899C20.5514 20.7047 20.8366 20.4908 21.1625 20.3482C21.4884 20.2056 21.8347 20.1343 22.2014 20.1343H23.4236C23.7903 20.1343 24.1366 20.2056 24.4625 20.3482C24.7884 20.4908 25.0736 20.7047 25.3181 20.9899L26.9681 22.9454C27.8847 24.0047 28.9083 24.8195 30.0389 25.3899C31.1694 25.9602 32.4273 26.2454 33.8125 26.2454V28.6899C32.1218 28.6899 30.5481 28.3487 29.0917 27.6662C27.6352 26.9838 26.3569 26.001 25.2569 24.7176V27.4676L30.0236 29.3621C30.431 29.525 30.7569 29.795 31.0014 30.1718C31.2458 30.5487 31.3681 30.951 31.3681 31.3787C31.3681 31.9899 31.1542 32.5093 30.7264 32.9371C30.2986 33.3649 29.7792 33.5787 29.1681 33.5787H20.3681V32.9676C20.3681 32.438 20.5412 32 20.8875 31.6537C21.2338 31.3075 21.6718 31.1343 22.2014 31.1343H25.8681C26.0514 31.1343 26.1991 31.0783 26.3111 30.9662C26.4231 30.8542 26.4792 30.7065 26.4792 30.5232C26.4792 30.3399 26.4231 30.1922 26.3111 30.0801C26.1991 29.9681 26.0514 29.9121 25.8681 29.9121H22.2014C21.3458 29.9121 20.6227 30.2075 20.0319 30.7982C19.4412 31.3889 19.1458 32.1121 19.1458 32.9676V33.5787H16.4569ZM22.8125 18.9121C22.1403 18.9121 21.5648 18.6727 21.0861 18.194C20.6074 17.7153 20.3681 17.1399 20.3681 16.4676C20.3681 15.7954 20.6074 15.22 21.0861 14.7412C21.5648 14.2625 22.1403 14.0232 22.8125 14.0232C23.4847 14.0232 24.0602 14.2625 24.5389 14.7412C25.0176 15.22 25.2569 15.7954 25.2569 16.4676C25.2569 17.1399 25.0176 17.7153 24.5389 18.194C24.0602 18.6727 23.4847 18.9121 22.8125 18.9121Z"
        fill={color}
      />
    </svg>
  );
};

export default MindfullnessSVG;
