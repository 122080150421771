import ImageIcon from '@mui/icons-material/Image';
import { Box, CardMedia, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AnalyzerText, OfferedByText, ProductName } from '../StyledComponents/StylesComponents';
import { parseImg } from '../../../../utils/parseImg';

const ProductAnalyzedLogoSVG = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.71312 0.115995C5.87589 -0.038665 6.12818 -0.038665 6.29095 0.115995C7.21058 0.999186 8.52086 1.47538 9.63581 1.72772C10.1892 1.85389 10.6816 1.92308 11.0356 1.95971C11.2146 1.97599 11.353 1.9882 11.4507 1.99227C11.4995 1.99227 11.5361 1.99634 11.5605 1.99634C11.5727 1.99634 11.5809 1.99634 11.589 1.99634H11.5931C11.821 2.00041 12 2.18763 12 2.41148V5.66341C12 8.82987 10.3154 12.0045 6.12411 13.315C6.04273 13.3394 5.95727 13.3394 5.87589 13.315C3.79654 12.6638 2.31943 11.5486 1.36724 10.173C0.415056 8.80138 0 7.19373 0 5.58201V2.41555C0 2.18763 0.179044 2.00448 0.406918 2.00041H0.410987C0.415056 2.00041 0.427263 2.00041 0.439471 2.00041C0.463886 2.00041 0.500509 2.00041 0.549339 1.99634C0.646999 1.99227 0.78942 1.98006 0.964395 1.96378C1.31841 1.92715 1.81078 1.85796 2.36419 1.73179C3.48322 1.47945 4.78942 0.999186 5.70905 0.120065L5.71312 0.115995Z"
        fill="url(#paint0_angular_2726_37265)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_2726_37265"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6 6.5) rotate(93.0128) scale(9.51315 8.61157)"
        >
          <stop offset="0.0423425" stopColor="#654EA3" />
          <stop offset="0.240949" stopColor="#007FFF" />
          <stop offset="0.415927" stopColor="#030972" />
          <stop offset="0.657455" stopColor="#88B04B" />
          <stop offset="0.771558" stopColor="#FFDF00" />
          <stop offset="0.867719" stopColor="#EB6E23" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const ProductThumbnail = ({ thumbnailData, productType }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} sx={{ order: { xs: -1, md: 0 } }}>
        {/* <ProductAnalyzedLogoSVG display="inline" /> */}
        <AnalyzerText display="inline" style={{ marginLeft: '2px' }}>
          {thumbnailData.analyzedBy}
        </AnalyzerText>
        <ProductName>{thumbnailData.name}</ProductName>
        <Typography component="div" mb={1} mt={-0.5} fontFamily={'Inter'} fontSize={'16px'} lineHeight={'20px'} fontWeight={600}>
          {productType}
        </Typography>
        <Typography component="span" sx={{ display: 'inline' }}>
          by <OfferedByText component="span">{thumbnailData.offeredBy}</OfferedByText>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} sx={{ order: { xs: 0, md: -1 } }}>
        {thumbnailData.picture && imageLoaded ? (
          <CardMedia
            component="img"
            height={'135px'}
            src={parseImg(thumbnailData.picture)}
            alt={'Product'}
            style={{ width: '100%', height: '135px', objectFit: 'cover' }}
            onError={() => setImageLoaded(false)}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={'135px'}
            sx={{ width: '100%', backgroundColor: '#f0f0f0' }}
          >
            <ImageIcon style={{ fontSize: '300%', color: '#ccc' }} />
          </Box>
        )}
        {/* <img
          src={thumbnailData.picture}
          alt="Product"
          style={{
            height: '137px',
            width: '258px'
          }}
        /> */}
      </Grid>
    </React.Fragment>
  );
};

export default ProductThumbnail;
