import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React from 'react';

// Styled components
const StyledCard = styled(Card)({
  display: 'flex',
  height: '100%'
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledTypography = styled(Typography)({
  marginTop: '10px',
  color: 'black',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  textAlign: 'center'
});

const NofiltersCard = ({ logo, text }) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <Box component="img" src={logo} alt="logo" sx={{ width: 'auto', height: '40' }} />
        <StyledTypography color="text.secondary" gutterBottom>
          {text}
        </StyledTypography>
      </StyledCardContent>
    </StyledCard>
  );
};

export default NofiltersCard;
