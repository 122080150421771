import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be in the format (XXX) XXX-XXXX')
    .required('Phone Number is required'),
  medicalId: yup.string().required('Medical ID is required'),
  emailAddress: yup.string().email('Invalid email format').notRequired(),
  pickupTime: yup.string().required('Pickup Time is required')
});

export function getMoodColors(mood) {
  return moodColors[mood] || defaultMood;
}

// Function to convert Hex to RGBA with 10% opacity
const hexToRGBA = (hex, opacity) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Mood colors with bgColor calculated
export const moodColors = {
  ELEVATE: {
    color: '#FFC700',
    bgColor: hexToRGBA('#FFC700', 0.1)
  },
  MOTIVATE: {
    color: '#88B04B',
    bgColor: hexToRGBA('#88B04B', 0.1)
  },
  INDULGE: {
    color: '#654EA3',
    bgColor: hexToRGBA('#654EA3', 0.1)
  },
  RECOVER: {
    color: '#EB6E23',
    bgColor: hexToRGBA('#EB6E23', 0.1)
  },
  RELAX: {
    color: '#007FFF',
    bgColor: hexToRGBA('#007FFF', 0.1)
  },
  SLEEP: {
    color: '#030972',
    bgColor: hexToRGBA('#030972', 0.1)
  }
};

const defaultMood = {
  color: 'black',
  bgColor: '#E8E8E8'
};
