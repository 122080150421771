import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import customizeExperience from '../../../../assets/videos/customize-experience.mp4';
import shopbyActivity from '../../../../assets/videos/shopby-activity.mp4';
import shopbyMedicine from '../../../../assets/videos/shopby-medicinal.mp4';
import shopbyMood from '../../../../assets/videos/shopby-mood.mp4';

// Routes
import { appRoutes } from '../../../../routes/appRoutes';
import { resetFiltersAction } from '../../../../store/actions/shopByActions';
import { useFilterSelection } from '../../../../hooks/useFilterSelection';

const cardData = [
  { image: shopbyMood, label: 'EXPLORE CATEGORIES', route: appRoutes.shopByMood },
  { image: shopbyActivity, label: 'PAIR WITH ACTIVITY', route: appRoutes.shopByActivity },
  { image: customizeExperience, label: 'CUSTOMIZE EXPERIENCE', route: appRoutes.shopByPerfectMatch },
  { image: shopbyMedicine, label: 'WELLNESS APPLICATIONS', route: appRoutes.shopBySymptoms }
];

const StyledCard = styled(Card)(({ theme }) => ({
  // height: { xs: '120px', sm: '150px', lg: '220px' },
  [theme.breakpoints.up('xs')]: {
    height: '200px'
  },
  [theme.breakpoints.up('sm')]: {
    height: '200px'
  },
  [theme.breakpoints.up('lg')]: {
    height: '270px'
  },
  [theme.breakpoints.up('xxl')]: {
    height: '340px'
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(2)
}));

const CardLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 127, 255, 1) 0%, rgba(3, 9, 114, 1) 20%, rgba(136, 176, 75, 1) 40%, rgba(255, 199, 0, 1) 60%, rgba(235, 110, 35, 1) 80%, rgba(191, 25, 50, 1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 75%',
    backgroundSize: '100% 3px'
  },
  [theme.breakpoints.up('sm')]: {
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 127, 255, 1) 0%, rgba(3, 9, 114, 1) 20%, rgba(136, 176, 75, 1) 40%, rgba(255, 199, 0, 1) 60%, rgba(235, 110, 35, 1) 80%, rgba(191, 25, 50, 1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: '100% 4px'
  },
  [theme.breakpoints.up('lg')]: {
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 127, 255, 1) 0%, rgba(3, 9, 114, 1) 20%, rgba(136, 176, 75, 1) 40%, rgba(255, 199, 0, 1) 60%, rgba(235, 110, 35, 1) 80%, rgba(191, 25, 50, 1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: '100% 4px'
  },
  textAlign: 'center',
  display: 'inline',
  textDecoration: 'none',
  paddingBottom: '8px'
}));

const Underline = styled('img')({
  width: '100%',
  height: 'auto'
});

const HomePageCards = () => {
  const navigate = useNavigate();
  const { handleResetFilters } = useFilterSelection();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={2} p={{ xs: 0, lg: 2 }} maxWidth={'100vw'} margin={'auto'}>
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <StyledCard
            onClick={() => {
              handleResetFilters();
              navigate(card.route);
            }}
          >
            <CardActionArea sx={{ borderRadius: '6px' }}>
              <CardMedia
                component="video"
                playsInline
                alt={card.label}
                src={card.image}
                autoPlay
                loop
                muted
                sx={{ height: { xs: '200px', sm: '200px', lg: '220px', xxl: '340px' }, objectFit: 'cover' }}
              />
              <CardContent sx={{ padding: { xs: '14px', sm: '18px', md: '20px' } }}>
                <Box>
                  <CardLabel variant="h6" style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: '700' }}>
                    {card.label}
                  </CardLabel>
                  {/* <Underline src={underlineSvg} alt="underline" style={{ width: 'inherit' }} /> */}
                </Box>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePageCards;
