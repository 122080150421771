import React from 'react';
import { Toolbar, Box } from '@mui/material';

import { StyledAppBar, StyledLogoContainer, StyledLogo2, StyledDivider, StyledTypography } from './styledComponents';

const Footer = () => {
  return (
    <StyledAppBar variant={window.location.pathname === '/home' ? 'home' : 'default'}>
      <Toolbar>
        <StyledLogoContainer>
          <StyledLogo2 />
          <StyledDivider orientation="vertical" flexItem sx={{ mx: 2, borderColor: 'transparent' }} />
          <Box>
            <StyledTypography>Copyright &#169;{new Date().getFullYear()} PowerLeaf.</StyledTypography>
            <StyledTypography>All Rights Reserved.</StyledTypography>
          </Box>
        </StyledLogoContainer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Footer;
