import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as LogoV1 } from '../../assets/icons/ageVerification/logo.svg';

const StyledLogo = styled(() => {
    return <LogoV1 width={'370px'} />;
})(() => ({}));

const StyledTypography = styled(Typography)(() => ({
    fontFamily: 'Space Grotesk',
    fontSize: '36px',
    fontWeight: '700',
    color: 'black',
}));

const StyledTypographyBody = styled(Typography)(() => ({
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    color: 'black',
    display: 'inline',
}));

const StyledTypographyBodyQuestion = styled(Typography)(() => ({
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    color: 'black',
    marginTop: '3%',
    marginBottom: '3%'
}));

const StyledTypographyHelperText = styled(Typography)(() => ({
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: '500',
    color: 'black',
    marginTop: '3%',
    marginBottom: '5%',
    width: '80%',
    textAlign: 'center'
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: 'black',
    border: '3px solid transparent',
    borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
    borderImageSlice: 1,
    width: '100%',
    '&:hover': {
        backgroundColor: 'black'
    },
    "&:disabled": {
        backgroundColor: '#767676'
    },
    color: 'white'
}));

export {
    StyledButton, StyledLogo,
    StyledTypography,
    StyledTypographyBody,
    StyledTypographyBodyQuestion, StyledTypographyHelperText
};
