// services/checkoutService.js
import ApiBaseHelper from '../../../network/apiBaseHelper';
import ApiLinks from '../../../network/apiLinks';

const submitOrder = async (orderData) => {
  try {
    const response = await ApiBaseHelper.post(ApiLinks.CHECKOUT, orderData);
    return response;
  } catch (error) {
    console.error('Error submitting order:', error);
    throw error;
  }
};

const checkPurchaseLimit = async (orderData) => {
  try {
    const response = await ApiBaseHelper.post(ApiLinks.CHECK_PRUCHASE_LIMIT, orderData);
    return response;
  } catch (error) {
    console.error('Error submitting order:', error);
    throw error;
  }
};

export { submitOrder, checkPurchaseLimit };
