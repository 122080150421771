import { Box, Grid, useMediaQuery, Hidden } from '@mui/material';
import { HeaderSection, Title, Description, StyledButton } from './StyledComponents';
import PerfectMatchProductListHome from './PerfectMatchProductListHome';
import { useNavigate } from 'react-router';
import { appRoutes } from '../../../../routes/appRoutes';
import Container from '../../../../components/Container';

const PerfectMatchProductSectionHome = ({ loading, products, showOverlayText, onClick }) => {
  const navigate = useNavigate();
  return (
    <HeaderSection>
      <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Grid container>
          <Grid container justifyContent="space-between" alignItems="start" mb={2}>
            <Grid item>
              <Title>Your Results</Title>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <StyledButton onClick={() => navigate(appRoutes.shopByPerfectMatch)}>VIEW ALL PRODUCTS</StyledButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container>
            <PerfectMatchProductListHome loading={loading} products={products} onClick={onClick} />
          </Grid>
          <Hidden mdUp>
            <Box mt={2} display="flex" justifyContent="center">
              <StyledButton fullWidth onClick={() => navigate(appRoutes.shopByPerfectMatch)}>
                VIEW ALL PRODUCTS
              </StyledButton>
            </Box>
          </Hidden>
        </Grid>
      </Container>
    </HeaderSection>
  );
};

export default PerfectMatchProductSectionHome;
