// src/hooks/useCheckPurchaseLimit.js

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../routes/appRoutes';
import { purchaseLimitFailure, purchaseLimitSuccess, setPurchaseLimitLoading } from '../store/checkout/actions/checkoutActions';
import { selectCartType, selectCheckoutProducts, selectUserId } from '../store/checkout/selectors/checkoutSelectors';
import { checkPurchaseLimit } from '../store/checkout/services/checkoutService';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

const useCheckPurchaseLimit = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const items = useSelector(selectCheckoutProducts);
  const purchaseType = useSelector(selectCartType);
  const navigate = useNavigate();
  const handleCheckPurchaseLimit = useCallback(async () => {
    if (items.length === 0 || !purchaseType) {
      dispatch(purchaseLimitFailure('Required information is missing for the purchase limit check.'));
      return;
    }
    dispatch(setPurchaseLimitLoading(true));

    try {
      const orderData = {
        userId,
        items: items.map((item) => ({ productId: item.id, quantity: item.quantity })),
        orderType: purchaseType
      };

      const response = await checkPurchaseLimit(orderData);

      if (response.status === 'error') {
        dispatch(purchaseLimitFailure(response.message));
      } else {
        try {
          mixpanel.track('Checkout Started', { ...orderData });
        } catch (error) {
          console.log(error);
        }
        dispatch(purchaseLimitSuccess(response.message));
        navigate(appRoutes.payment);
      }
    } catch (error) {
      dispatch(purchaseLimitFailure(error.response.data.message));
    } finally {
      dispatch(setPurchaseLimitLoading(false));
    }
  }, [dispatch, userId, items]);

  return { handleCheckPurchaseLimit };
};

export default useCheckPurchaseLimit;
