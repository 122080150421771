import { Card, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const ProductCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})(({ theme, isSelected }) => ({
  display: 'flex',
  flexDirection: 'row', // default is row
  alignItems: 'center',
  justifyContent: 'start',
  boxShadow: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  textAlign: 'center',
  padding: '16px',
  border: isSelected ? '3px solid' : 'none',
  borderImage: isSelected ? 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%) 1' : 'none',
  [`@media (max-width:${1330}px)`]: { // Use the value from the theme breakpoints
    flexDirection: 'column', // switch to column for smaller screens
    justifyContent: 'center', // center items in the main-axis
  },
  '&:hover': {
    cursor: 'pointer'
  }
}));

const ProductImage = styled(CardMedia)(({ theme }) => ({
  width: '93px',
  height: '64.291px',
  flexShrink: 0,
  [theme.breakpoints.down('1330px')]: {
    width: 'auto', // adjust width for smaller screens if needed
    height: 'auto', // adjust height for smaller screens if needed
    marginBottom: '8px', // space between image and text for smaller screens
  },
  marginRight: '16px' // space between image and text for larger screens
}));

const ProductName = styled(Typography)({
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '32px',
  fontFamily: 'Space Grotesk'
  // The margin is adjusted in the ProductImage for smaller screens
});

const ProductTypeCard = ({ name, image, isSelected, onClick }) => {
  return (
    <ProductCard isSelected={isSelected} onClick={onClick}>
      <ProductImage component="img" image={image} alt={name} />
      <ProductName>{name}</ProductName>
    </ProductCard>
  );
};

export default ProductTypeCard;
