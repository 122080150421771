// Assets
import BackgroundImage from '../assets/images/shopBy/background/background.png';
import Concentrates from '../assets/images/shopBy/productType/concentrates.png';
import Edibles from '../assets/images/shopBy/productType/edibles.png';
import Flower from '../assets/images/shopBy/productType/flower.png';
import PreRolls from '../assets/images/shopBy/productType/preRolls.png';
import Topicals from '../assets/images/shopBy/productType/topicals.png';
import Vaporizors from '../assets/images/shopBy/productType/vaporizors.png';

export const productTypeConsts = {
  titleOne: 'Shop by',
  titleTwo: 'Product Type',
  backgroundImage: BackgroundImage,
  types: [
    {
      name: 'Flower',
      image: Flower
    },
    {
      name: 'Pre-Rolls',
      image: PreRolls
    },
    {
      name: 'Vaporizors',
      image: Vaporizors
    },
    {
      name: 'Concentrate',
      image: Concentrates
    },
    {
      name: 'Edibles & Beverage',
      image: Edibles
    },
    {
      name: 'Topicals',
      image: Topicals
    },
  ]
};
