// Activity icons
import creativity from '../assets/images/icons/filters/activity/creativity.png';
import creativitySelected from '../assets/images/icons/filters/activity/creativitySelected.png';
import dining from '../assets/images/icons/filters/activity/dining.png';
import diningSelected from '../assets/images/icons/filters/activity/diningSelected.png';
import entertainment from '../assets/images/icons/filters/activity/entertainment.png';
import entertainmentSelected from '../assets/images/icons/filters/activity/entertainmentSelected.png';
import exercise from '../assets/images/icons/filters/activity/exercise.png';
import exerciseSelected from '../assets/images/icons/filters/activity/exerciseSelected.png';
import intimacy from '../assets/images/icons/filters/activity/intimacy.png';
import intimacySelected from '../assets/images/icons/filters/activity/intimacySelected.png';
import mindfullness from '../assets/images/icons/filters/activity/mindfullness.png';
import mindfullnessSelected from '../assets/images/icons/filters/activity/mindfullnessSelected.png';
import outdoors from '../assets/images/icons/filters/activity/outdoors.png';
import outdoorsSelected from '../assets/images/icons/filters/activity/outdoorsSelected.png';
import relax from '../assets/images/icons/filters/activity/relax.png';
import relaxSelected from '../assets/images/icons/filters/activity/relaxSelected.png';
import sleep from '../assets/images/icons/filters/activity/sleep.png';
import sleepSelected from '../assets/images/icons/filters/activity/sleepSelected.png';
import socializing from '../assets/images/icons/filters/activity/socializing.png';
import socializingSelected from '../assets/images/icons/filters/activity/socializingSelected.png';
import mobility from '../assets/images/icons/filters/activity/mobility.png';
import mobilitySelected from '../assets/images/icons/filters/activity/mobilitySelected.png';

// Purchase type icons
import adult from '../assets/images/icons/filters/purchaseType/adult.png';
import adultSelected from '../assets/images/icons/filters/purchaseType/adultSelected.png';
import medical from '../assets/images/icons/filters/purchaseType/medical.png';
import medicalSelected from '../assets/images/icons/filters/purchaseType/medicalSelected.png';

// Medicinal icons
import appetite from '../assets/images/icons/filters/medicinal/appetite.png';
import appetiteSelected from '../assets/images/icons/filters/medicinal/appetiteSelected.png';
import fatigue from '../assets/images/icons/filters/medicinal/fatigue.png';
import fatigueSelected from '../assets/images/icons/filters/medicinal/fatigueSelected.png';
import focus from '../assets/images/icons/filters/medicinal/focus.png';
import focusSelected from '../assets/images/icons/filters/medicinal/focusSelected.png';
import inflammation from '../assets/images/icons/filters/medicinal/inflamation.png';
import inflammationSelected from '../assets/images/icons/filters/medicinal/inflamationSelected.png';
import mood from '../assets/images/icons/filters/medicinal/mood.png';
import moodSelected from '../assets/images/icons/filters/medicinal/moodSelected.png';
import nausea from '../assets/images/icons/filters/medicinal/nausea.png';
import nauseaSelected from '../assets/images/icons/filters/medicinal/nauseaSelected.png';
import pain from '../assets/images/icons/filters/medicinal/pain.png';
import painSelected from '../assets/images/icons/filters/medicinal/painSelected.png';
import discomfortSelected from '../assets/images/icons/filters/medicinal/discomfortSelected.png';
import discomfort from '../assets/images/icons/filters/medicinal/discomfort.png';
import recovery from '../assets/images/icons/filters/medicinal/recovery.png';
import recoverySelected from '../assets/images/icons/filters/medicinal/recoverySelected.png';
import sleepMedicinal from '../assets/images/icons/filters/medicinal/sleep.png';
import sleepMedicinalSelected from '../assets/images/icons/filters/medicinal/sleepSelected.png';
import stress from '../assets/images/icons/filters/medicinal/stress.png';
import stressSelected from '../assets/images/icons/filters/medicinal/stressSelected.png';

// Mood icons
import elevate from '../assets/images/icons/filters/mood/elevate.png';
import elevateSelected from '../assets/images/icons/filters/mood/elevateSelected.png';
import indulge from '../assets/images/icons/filters/mood/indulge.png';
import indulgeSelected from '../assets/images/icons/filters/mood/indulgeSelected.png';
import motivate from '../assets/images/icons/filters/mood/motivate.png';
import motivateSelected from '../assets/images/icons/filters/mood/motivateSelected.png';
import recover from '../assets/images/icons/filters/mood/recover.png';
import recoverSelected from '../assets/images/icons/filters/mood/recoverSelected.png';
import relaxMood from '../assets/images/icons/filters/mood/relax.png';
import relaxMoodSelected from '../assets/images/icons/filters/mood/relaxSelected.png';
import sleepMood from '../assets/images/icons/filters/mood/sleep.png';
import sleepMoodSelected from '../assets/images/icons/filters/mood/sleepSelected.png';

// Buttons icons
import { ReactComponent as CheckboxChecked } from '../assets/images/icons/buttons/checkboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from '../assets/images/icons/buttons/checkboxUnchecked.svg';
import { ReactComponent as Close } from '../assets/images/icons/buttons/close.svg';
import { ReactComponent as Collapse } from '../assets/images/icons/buttons/collapse.svg';
import { ReactComponent as Expand } from '../assets/images/icons/buttons/expand.svg';
import { ReactComponent as RadioChecked } from '../assets/images/icons/buttons/radioChecked.svg';
import { ReactComponent as RadioUnchecked } from '../assets/images/icons/buttons/radioUnchecked.svg';

// Filter icons
import { ReactComponent as FilterIcon } from '../assets/svgs/filters/common/filterIcon.svg';

// Create a list of object with all the icons, each a have selected and unselected version
export const activityIcons = [
  {
    id: 1,
    name: 'creativity',
    selected: creativitySelected,
    unselected: creativity
  },
  {
    id: 2,
    name: 'dining',
    selected: diningSelected,
    unselected: dining
  },
  {
    id: 3,
    name: 'entertainment',
    selected: entertainmentSelected,
    unselected: entertainment
  },
  {
    id: 4,
    name: 'exercise',
    selected: exerciseSelected,
    unselected: exercise
  },
  {
    id: 5,
    name: 'intimacy',
    selected: intimacySelected,
    unselected: intimacy
  },
  {
    id: 6,
    name: 'mindfulness',
    selected: mindfullnessSelected,
    unselected: mindfullness
  },
  {
    id: 7,
    name: 'outdoors',
    selected: outdoorsSelected,
    unselected: outdoors
  },
  {
    id: 8,
    name: 'relaxation',
    selected: relaxSelected,
    unselected: relax
  },
  {
    id: 9,
    name: 'sleep',
    selected: sleepSelected,
    unselected: sleep
  },
  {
    id: 10,
    name: 'socializing',
    selected: socializingSelected,
    unselected: socializing
  },
  {
    id: 11,
    name: 'mobility',
    selected: mobilitySelected,
    unselected: mobility
  }
];

export const purchaseTypeIcons = [
  {
    id: 1,
    name: 'adult',
    selected: adultSelected,
    unselected: adult
  },
  {
    id: 2,
    name: 'medical',
    selected: medicalSelected,
    unselected: medical
  }
];

export const medicinalIcons = [
  {
    id: 1,
    name: 'appetite',
    selected: appetiteSelected,
    unselected: appetite
  },
  {
    id: 2,
    name: 'energy',
    selected: fatigueSelected,
    unselected: fatigue
  },
  {
    id: 3,
    name: 'focus',
    selected: focusSelected,
    unselected: focus
  },
  // {
  //   id: 4,
  //   name: 'inflammation',
  //   selected: inflammationSelected,
  //   unselected: inflammation
  // },
  {
    id: 5,
    name: 'mood',
    selected: moodSelected,
    unselected: mood
  },
  {
    id: 6,
    name: 'nausea',
    selected: nauseaSelected,
    unselected: nausea
  },
  {
    id: 7,
    name: 'discomfort',
    selected: discomfortSelected,
    unselected: discomfort
  },
  {
    id: 8,
    name: 'recovery',
    selected: recoverySelected,
    unselected: recovery
  },
  {
    id: 9,
    name: 'rest',
    selected: sleepMedicinalSelected,
    unselected: sleepMedicinal
  },
  {
    id: 10,
    name: 'stress',
    selected: stressSelected,
    unselected: stress
  }
  // {
  //   id: 11,
  //   name: 'worry',
  //   selected: worrySelected,
  //   unselected: worry
  // }
];

export const moodIcons = [
  {
    id: 1,
    name: 'indulge',
    selected: indulgeSelected,
    unselected: indulge
  },
  {
    id: 2,
    name: 'elevate',
    selected: elevateSelected,
    unselected: elevate
  },
  {
    id: 3,
    name: 'motivate',
    selected: motivateSelected,
    unselected: motivate
  },
  {
    id: 4,
    name: 'relaxation',
    selected: relaxMoodSelected,
    unselected: relaxMood
  },
  {
    id: 5,
    name: 'recover',
    selected: recoverSelected,
    unselected: recover
  },
  {
    id: 6,
    name: 'sleep',
    selected: sleepMoodSelected,
    unselected: sleepMood
  }
];

export const buttonIcons = {
  checkboxChecked: <CheckboxChecked />,
  checkboxUnchecked: <CheckboxUnchecked />,
  close: <Close />,
  expand: <Expand />,
  collapse: <Collapse />,
  radioChecked: <RadioChecked />,
  radioUnchecked: <RadioUnchecked />
};

export const filterCategories = [
  { title: 'Activity', type: 'icons', icons: activityIcons },
  { title: 'Purchase Type', type: 'icons', icons: purchaseTypeIcons },
  { title: 'Wellness', type: 'icons', icons: medicinalIcons },
  { title: 'Category', type: 'icons', icons: moodIcons },
  // { title: 'Product Type', type: 'radio' },
  // {
  //   title: 'Customize mood',
  //   type: 'multipleSlider',
  //   sliders: [
  //     { title: 'Bliss', min: 0, max: 4 },
  //     { title: 'Energy', min: 0, max: 4 },
  //     { title: 'Focus', min: 0, max: 4 },
  //     { title: 'Relaxation', min: 0, max: 4 }
  //   ]
  // },
  { title: 'Brand', type: 'radio' },
  { title: 'Terpenes', type: 'radio' },
  { title: 'Cannabinoids', type: 'radio' },
  // { title: 'Weight', type: 'slider' },
  { title: 'Power Leaf Analyzed', type: 'checkbox' }
];

export const initialSelectedFilters = [
  {
    id: 'Filter',
    type: 'Filter',
    heading: 'Filter',
    icon: true,
    startIcon: <FilterIcon />
  }
];
