import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import ProductsList from '../../components/lists/productsList';
import OverAllFilters from '../../components/shopByFilters/selectedFilters';

// Hooks
import useFetchProducts from '../../hooks/useFetchProducts';

// Selectors
import { selectSelectedFilters, selectTotalProducts } from '../../store/selectors/shopBySelectors';

const SearchComponent = () => {
  // Navigate
  const navigate = useNavigate();

  //Selectors
  const selectedFilters = useSelector(selectSelectedFilters);
  const totalProducts = useSelector(selectTotalProducts);

  // Hooks
  const { loading, products } = useFetchProducts();

  // Handlers
  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <Grid container justifyContent="center" alignItems={'center'}>
      <Grid item container display={'flex'} direction={'column'}>
        {loading ? (
          <Skeleton width="80%" height={30} />
        ) : (
          <>
            {selectedFilters?.search !== '' ? (
              <Box m={2}>
                <Typography pr={2} variant="h1" display="inline" sx={{ fontWeight: 'bold' }}>
                  &quot;{selectedFilters.search}&quot;
                </Typography>
                <Typography variant="h5" display="inline" sx={{ fontWeight: 'medium' }}>
                  {totalProducts} results for{' '}
                </Typography>
                <Typography variant="h5" display="inline" sx={{ fontWeight: 'bold' }}>
                  {selectedFilters.search}
                </Typography>
              </Box>
            ) : (
              <Box m={2}>
                <Typography variant="h5" display="inline" sx={{ fontWeight: 'medium' }}>
                  {totalProducts} results
                </Typography>
              </Box>
            )}
          </>
        )}

        <OverAllFilters />
        <ProductsList loading={loading} products={products} onClick={handleProductClick} isSearch={true} />
      </Grid>
    </Grid>
  );
};

export default SearchComponent;
