import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'swiper/css';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import AgeVerification from '../../AgeVerification/AgeVerification';

// Consts
import ProductTypeCard from '../../../components/cards/productTypeCard';

// Styles
import { ProductTypeGrid, TitleContainer, TitleOne, TitleTwo } from './StyledComponents';

const swiperSettings = {
  slidesPerView: 2,
  spaceBetween: 16,
  breakpoints: {
    600: {
      slidesPerView: 3
    },
    960: {
      slidesPerView: 4
    }
  }
};

const ShopByProductTypeFilters = ({ selectedProduct, handleSelectProduct, filters }) => {
  const isBelowLargeScreen = useMediaQuery('(max-width:1330px)');
  const userVerfificationState = useSelector(state => state.user.userVerified);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      {
        !userVerfificationState && (
          <AgeVerification isProductDetail={true} />
        )
      }

      <ProductTypeGrid container spacing={1}>
        <TitleContainer item xs={12} mb={4} isBelowLargeScreen={isBelowLargeScreen}>
          <TitleOne>Shop by</TitleOne>
          <TitleTwo>Product Type</TitleTwo>
        </TitleContainer>

        {isBelowLargeScreen ? (
          <Swiper {...swiperSettings} modules={[Scrollbar]} scrollbar={{ draggable: true }}>
            {filters.types.map((type) => (
              <SwiperSlide key={type.name}>
                <ProductTypeCard
                  name={type.name}
                  image={type.image}
                  isSelected={selectedProduct === type.name}
                  onClick={() => handleSelectProduct(type.name)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          filters.types.map((type) => (
            <Grid item xs={12} sm={6} md={3} lg={4} key={type.name}>
              <ProductTypeCard
                name={type.name}
                image={type.image}
                isSelected={selectedProduct === type.name}
                onClick={() => handleSelectProduct(type.name)}
              />
            </Grid>
          ))
        )}
      </ProductTypeGrid>
    </>
  );
};

export default ShopByProductTypeFilters;
