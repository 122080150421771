import { Box, Grid, Paper, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import backgroundImage from '../../../../assets/icons/homePage/multi-color-bg.png';
import PowerleafLogo from '../../../../assets/icons/homePage/powerleaf_alone.svg';
import ShopThePowerleafWayCards from './shopPowerleafWaycards';
import Container from '../../../../components/Container';

const HeaderSection = styled(Paper)(({ theme }) => ({
  position: 'relative',
  background: `url(${backgroundImage}) no-repeat center center`,
  backgroundSize: 'cover',
  color: theme.palette.common.white,
  textAlign: 'center',
  overflow: 'hidden',
  padding: theme.spacing(4, 2),
  maxWidth: '100%',
  margin: 'auto',
  display: 'block',

  '> .MuiGrid-container': {
    position: 'relative',
    zIndex: 2
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  fontSize: '36px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '46px',
  marginBottom: '12px',
  [theme.breakpoints.down('md')]: {
    fontSize: '26px'
  }
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '28px',
  letterSpacing: '0.25px',
  marginBottom: '35px',
  maxWidth: '724px',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  }
}));

const LogoImage = styled('img')(({ theme, isMobile }) => ({
  maxWidth: '100%',
  height: isMobile ? 'auto' : 'initial'
}));

const ShopThePowerleafWay = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <HeaderSection>
      <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Grid container spacing={{ md: 2, xs: 1 }} justifyContent="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
              <LogoImage src={PowerleafLogo} alt="Powerleaf Logo" isMobile={isMobile} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Title variant="h3">Shop the PowerLeaf Way</Title>
            {/* <Subtitle variant="subtitle1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.{' '}
          </Subtitle> */}
          </Grid>

          <ShopThePowerleafWayCards />
        </Grid>
      </Container>
    </HeaderSection>
  );
};

export default ShopThePowerleafWay;
