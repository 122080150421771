import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styles
const ProductTypeGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '1133px',
  margin: '0 auto', // centers the grid in its parent
  padding: theme.spacing(3)
}));

const TitleContainer = styled(Grid)(({isBelowLargeScreen }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: isBelowLargeScreen ? 'center' : 'none',
  flexDirection: isBelowLargeScreen ? 'column' : 'none',
}));

const TitleOne = styled(Typography)({
  textAlign: 'center',
  fontSize: '36px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '42px',
  marginRight: '8px' // Adjust the space between TitleOne and TitleTwo as needed
});

const TitleTwo = styled(Typography)({
  fontSize: '36px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '42px'
});

export {
    ProductTypeGrid,
    TitleContainer,
    TitleOne,
    TitleTwo
}