import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import 'swiper/css';

import InfinityImageSrc from '../../../../assets/icons/homePage/science_subsec_0.svg';
import { ReactComponent as Logo1 } from '../../../../assets/icons/homePage/science_subsec_1.svg';
import { ReactComponent as Logo2 } from '../../../../assets/icons/homePage/science_subsec_2.svg';
import { ReactComponent as Logo3 } from '../../../../assets/icons/homePage/science_subsec_3.svg';
import Container from '../../../../components/Container';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${InfinityImageSrc})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  position: 'relative',
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    marginTop: '-140px',
    marginBottom: '15px'
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '-140px',
    marginBottom: '15px'
  },

  [theme.breakpoints.up('md')]: {
    width: '110%'
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    marginTop: '4rem',
    '& > svg': {
      maxWidth: '150px',
      maxHeight: '150px'
    }
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '2rem',
    '& > svg': {
      maxWidth: '100px',
      maxHeight: '100px'
    }
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem',
    '& > svg': {
      maxWidth: '50px',
      maxHeight: '50px'
    }
  }
}));

const LogoDescription = styled(Typography)({
  marginTop: '1rem',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: '400'
});

const LogoTitle = styled(Typography)({
  fontFamily: 'Space Grotesk',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '26px'
});

const LogoTitleMiddle = styled(Typography)({
  fontFamily: 'Space Grotesk',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '32px'
});

const logos = [
  {
    component: <Logo1 style={{ marginTop: '80px' }} />,
    title: 'PRODUCT INGREDIENTS',
    text: 'Not all cannabis is the same. <br/> Each strain produces different ingredients. '
  },
  {
    component: <Logo2 style={{ marginTop: '40px' }} />,
    title: 'NEUROSCIENCE',
    text: 'Cannabis ingredients cause the brain to release neurotransmitters—the natural chemicals that create moods.  Knowing a products specific ingredients can predict which neurotransmitters will be released.'
  },
  {
    component: <Logo3 style={{ marginTop: '120px' }} />,
    title: 'PRODUCT EXPERIENCE',
    text: 'Specific combinations of neurotransmitters in our brains create the mood states that we experience. Pairing the right product with an intended mood or activity creates the ideal brain state needed to enhance that experience.'
  }
];

const ScienceSubsection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <StyledGridContainer container spacing={2}>
        <Grid item xs={4} container direction="column" alignItems="center">
          <LogoContainer>
            <Logo1 style={{ marginTop: isMobile ? '0px' : isMD ? '85px' : '50px' }} />
            {!isMobile && (
              <>
                <LogoTitle>PRODUCT INGREDIENTS</LogoTitle>
                {isMD ? (
                  <LogoDescription>
                    Not all cannabis is the same. Each strain produces different ingredients. Lab testing tells us what those are.
                  </LogoDescription>
                ) : (
                  <LogoDescription>
                    Not all cannabis is the same. <br /> Each strain produces different ingredients. <br /> Lab testing tells us what those
                    are.
                  </LogoDescription>
                )}
              </>
            )}
          </LogoContainer>
        </Grid>

        <Grid item xs={4} container direction="column" alignItems="center">
          <LogoContainer>
            <Logo2 style={{ marginTop: isMobile ? '0px' : isMD ? '120px' : '80px' }} />
            {!isMobile && (
              <>
                <LogoTitleMiddle>NEUROSCIENCE</LogoTitleMiddle>
                {isMD ? (
                  <LogoDescription>
                    A product&apos;s unique ingredients influence our neurotransmitter levels in different ways. These are the brain
                    chemicals that determine mood, and influence how we feel.
                  </LogoDescription>
                ) : (
                  <LogoDescription>
                    A product&apos;s unique ingredients influence our neurotransmitter levels in different ways. <br /> These are the brain
                    chemicals that determine mood, and influence how we feel.
                  </LogoDescription>
                )}
              </>
            )}
          </LogoContainer>
        </Grid>

        <Grid item xs={4} container direction="column" alignItems="center">
          <LogoContainer>
            <Logo3 style={{ marginTop: isMobile ? '0px' : isMD ? '105px' : '75px' }} />
            {!isMobile && (
              <>
                <LogoTitle>PRODUCT EXPERIENCE</LogoTitle>
                {isMD ? (
                  <LogoDescription>
                    We use product lab testing data and science to pair the ideal product ingredients with how you want to feel, creating a
                    more purposeful experience.
                  </LogoDescription>
                ) : (
                  <LogoDescription>
                    We use product lab testing data <br /> and science to pair the ideal product <br /> ingredients with how you want to
                    feel,
                    <br /> creating a more purposeful experience.
                  </LogoDescription>
                )}
              </>
            )}
          </LogoContainer>
        </Grid>
      </StyledGridContainer>
    </Container>
  );
};

export default ScienceSubsection;
