class ApiLinks {
  static API_BASE_URL = 'https://motherearthri-api.powerleaf.com/api';

  //Product
  static PRODUCT_DETAILS = '/consumers/product_details';
  static CHECKOUT = '/orders/checkout';
  static CHECK_PRUCHASE_LIMIT = '/orders/cart';

  //ShopBy
  static FETCH_FILTERS = '/consumers/getUnique';
  static FETCH_PRODUCTS = '/consumers/link';

  //UserVerification
  static VERIFY_PHONE_NUMBER = '/consumers/phoneNumber';
}

export default ApiLinks;
