import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/buttons/custombutton';
import { addToCart } from '../../../../store/checkout/actions/checkoutActions';
import { setProductQuantity, setProductWeight } from '../../../../store/productDetails/actions/productActions';
import { selectProductData, selectProductQuantity, selectProductWeight } from '../../../../store/productDetails/selectors/productSelectors';
import { OptionsQTYContainer } from '../StyledComponents/StylesComponents';

import { setCartDialog, setCartType } from '../../../../store/checkout/actions/checkoutActions';
import { selectCartType } from '../../../../store/checkout/selectors/checkoutSelectors';
import { selectPurchaseTypeFilterState } from '../../../../store/selectors/shopBySelectors';

const ProductOptionsQTY = ({ quantitySelection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quantity = useSelector(selectProductQuantity);
  const weight = useSelector(selectProductWeight);
  const product = useSelector(selectProductData);
  const cartType = useSelector(selectCartType);
  const purchaseTypeState = useSelector(selectPurchaseTypeFilterState);

  const handleQuantityChange = (newQuantity) => {
    dispatch(setProductQuantity(newQuantity));
  };
  const handleSelectOption = (weight) => {
    dispatch(setProductWeight(weight));
  };

  const handleAddToCart = () => {
    if (cartType === null) {
      dispatch(setCartType(purchaseTypeState));
      dispatch(addToCart(product));
      navigate('/checkout');
    } else if (cartType === purchaseTypeState) {
      dispatch(addToCart(product));
      navigate('/checkout');
    } else {
      dispatch(setCartDialog(true));
    }
  };

  return (
    <OptionsQTYContainer sx={{ ml: { xs: 0, md: 10 } }}>
      <Typography fontFamily={"Space Grotesk"} fontSize={"36px"} fontWeight={"700"} variant="h4" gutterBottom>
        ${quantitySelection?.price?.toFixed(2)}
      </Typography>
      {/* <Typography fontFamily={"Inter"} fontSize={"10px"} fontWeight={"500"} variant="subtitle1" gutterBottom>
        Options
      </Typography>
      <QuantityOptions quantityOptions={quantitySelection.qtyOptions} selectedOption={weight} handleSelectOption={handleSelectOption} /> */}

      <Box mt={2} mb={2}>
        <Typography fontFamily={"Inter"} fontSize={"10px"} fontWeight={"500"} variant="subtitle1" gutterBottom>
          Quantity
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => handleQuantityChange(quantity - 1)} disabled={quantity <= 1}>
            <RemoveIcon />
          </IconButton>
          <TextField
            inputProps={{ style: { textAlign: 'center' } }}
            value={quantity}
            onChange={(e) => handleQuantityChange(Number(e.target.value))}
          />
          <IconButton onClick={() => handleQuantityChange(quantity + 1)}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      <CustomButton title={'ADD TO CART'} onClick={handleAddToCart} />

      <Typography fontFamily={"Inter"} fontSize={"10px"} fontWeight={"500"} variant="caption" display="block" mt={2}>
        * Cannabis and Sales tax will be added at checkout.
      </Typography>
    </OptionsQTYContainer>
  );
};

export default ProductOptionsQTY;
