import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { fetchProductsLoadingAction, resetFiltersAction, updateFiltersAction } from '../store/actions/shopByActions';

// Selectors
import { selectSelectedFilters } from '../store/selectors/shopBySelectors';

export const useFilterSelection = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const selectedFiltersStore = useSelector(selectSelectedFilters);

  // States
  const [selectedFilters, setSelectedFilters] = useState({});
  const [expanded, setExpanded] = useState(null);

  // Handlers
  const updateSelectedFilters = (category, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: value
    }));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSliderChange = (category, newValue) => {
    updateSelectedFilters(category, newValue);
  };

  const handleMultipleSliderChange = (category, newValue, index) => {
    const newValues = [...(selectedFilters[category] || [0, 0, 0, 0])];
    newValues[index] = newValue;
    updateSelectedFilters(category, newValues);
  };

  const handleIconSelect = (category, iconName) => {
    updateSelectedFilters(category, iconName);
  };

  const handleRadioChange = (event, category) => {
    updateSelectedFilters(category, event.target.value);
  };

  const handleCheckboxChange = (event, category) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      updateSelectedFilters(category, category);
    } else {
      const updatedSelectedFilters = { ...selectedFilters };
      delete updatedSelectedFilters[category];
      setSelectedFilters(updatedSelectedFilters);
    }
  };

  const handleDelete = (category) => {
    const updatedSelectedFilters = { ...selectedFilters };
    delete updatedSelectedFilters[category];
    setSelectedFilters(updatedSelectedFilters);
  };

  const handleClearAll = () => {
    const newFilters = {
      page: 1,
      pageSize: 10,
      'Product Type': 'All'
    };
    setSelectedFilters({ ...newFilters });
  };

  const handleResetFilters = (filters) => {
    dispatch(resetFiltersAction());
  };

  const handleRevealProducts = () => {
    dispatch(fetchProductsLoadingAction(true));
    const newFilters = {
      ...selectedFilters,
      page: 1,
      pageSize: 10
    };
    dispatch(updateFiltersAction(newFilters));
  };

  const handleDeleteAndRefresh = (category) => {
    dispatch(fetchProductsLoadingAction(true));
    const updatedSelectedFilters = { ...selectedFilters };
    delete updatedSelectedFilters[category];
    setSelectedFilters(updatedSelectedFilters);

    const newFilters = {
      ...updatedSelectedFilters,
      page: 1,
      pageSize: 10
    };
    dispatch(updateFiltersAction(newFilters));
  };

  // UseEffects
  useEffect(() => {
    setSelectedFilters(selectedFiltersStore);
  }, [selectedFiltersStore]);

  return {
    selectedFilters,
    expanded,
    handleAccordionChange,
    handleSliderChange,
    handleMultipleSliderChange,
    handleIconSelect,
    handleRadioChange,
    handleCheckboxChange,
    handleDelete,
    handleClearAll,
    handleRevealProducts,
    handleDeleteAndRefresh,
    handleResetFilters
  };
};
