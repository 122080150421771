import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const StyledDialog = styled(Dialog)(() => ({
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
    fontFamily: 'Space Grotesk',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '26px'
}));

const StyledDialogContent = styled(DialogContent)(() => ({
    paddingTop: '6px',
}));

const StyledDialogActions = styled(DialogActions)(() => ({

}));

const StyledTypography = styled(Typography)(() => ({
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px'
}));

const StyledButton = styled(Button)(({ isCancel }) => ({
    backgroundColor: isCancel ? 'white' : 'black',
    color: isCancel ? 'black' : 'white',
    border: '3px solid transparent',
    borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
    borderImageSlice: 1,
    width: '100%',
    '&:hover': {
        backgroundColor: isCancel ? 'white' : 'black'
    },
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "700",
    lineHeight: "20px",
}));

const StyledIconButton = styled(IconButton)(() => ({
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
}));

export {
    StyledDialog,
    StyledDialogTitle,
    StyledDialogContent,
    StyledDialogActions,
    StyledTypography,
    StyledButton,
    StyledIconButton
};