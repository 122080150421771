import React from 'react';
import { Card, CardContent, Skeleton, Box, Grid } from '@mui/material';

import { ReactComponent as NoProductsSvg } from '../../assets/svgs/noProductsCard/noProducts.svg';

const ProductLoadingCard = () => {
  return (
    <>
      <NoProductsSvg />
    </>
  );
};

export default ProductLoadingCard;
