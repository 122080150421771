import {
    SET_USER_VERIFICATION,
    SET_PHONE_DIALOG_STATE
} from '../consts/reduxActionTypes';

export const verifyUserAction = (userDetails, phoneNumber, isNewUser, userType) => (dispatch) => {
    dispatch({
        type: SET_USER_VERIFICATION,
        payload: {
            userVerified: true,
            userTypeFilter: userType,
            userDetails: userDetails,
            userPhoneNumber: phoneNumber,
            isNewUser: isNewUser,
            phoneNumberDialog: false,
        }
    });
};

export const setPhoneDialogState = (dialogState) => (dispatch) => {
    dispatch({
        type: SET_PHONE_DIALOG_STATE,
        payload: dialogState
    });
}