import React from 'react';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

const StyledButton = styled(MuiButton)(({ theme, disabled }) => ({
  backgroundColor: disabled ? '#000000' : 'black',
  border: disabled ? 'none' : '3px solid transparent',
  borderImage: disabled ? 'none' : 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
  borderImageSlice: disabled ? 0 : 1,
  width: '100%',
  '&:hover': {
    backgroundColor: disabled ? '#000000' : 'black'
  },
  fontSize: "14px",
  fontFamily: "Inter",
  fontWeight: "700",
}));

const CustomButton = ({ title, onClick, loading, sx }) => {
  return (
    <StyledButton variant="contained" onClick={onClick} disabled={loading} sx={sx}>
      {loading ? <CircularProgress size={24} color="inherit" /> : title}
    </StyledButton>
  );
};

export default CustomButton;
