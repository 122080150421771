import { Box, Button, Card, CardActionArea, CardMedia, Grid, Hidden, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import ElevateSVG from '../../../../assets/icons/homePage/Elevate.png';
import IndulgeSVG from '../../../../assets/icons/homePage/Indulge.png';
import MotivateSVG from '../../../../assets/icons/homePage/Motivate.png';
import RecoverSVG from '../../../../assets/icons/homePage/Recover.png';
import RelaxSVG from '../../../../assets/icons/homePage/Relax.png';
import SleepSVG from '../../../../assets/icons/homePage/sleep_home.svg';

// Actions
import { resetFiltersAction, updateFiltersAction } from '../../../../store/actions/shopByActions';

// Routes
import { appRoutes } from '../../../../routes/appRoutes';
import Container from '../../../../components/Container';

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '27px',
    fontFamily: 'Space Grotesk',
    color: 'var(--Neutrals-Black, #000)'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    fontFamily: 'Space Grotesk',
    color: 'var(--Neutrals-Black, #000)'
  }
}));
const Description = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: '400',
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
    marginTop: '8px'
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: '32px'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: '2px solid black',
  color: 'black',
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '14px'
}));

const moodData = [
  { title: 'RECOVER', svg: RecoverSVG },
  { title: 'ELEVATE', svg: ElevateSVG },
  { title: 'MOTIVATE', svg: MotivateSVG },
  { title: 'RELAX', svg: RelaxSVG },
  { title: 'INDULGE', svg: IndulgeSVG },
  { title: 'SLEEP', svg: SleepSVG }
];

const ShopByMoods = () => {
  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  // Handlers
  const handleSelectMood = (mood) => {
    dispatch(resetFiltersAction());
    const updatedFilters = {
      page: 1,
      pageSize: 10,
      Category: mood.toLowerCase()
    };
    dispatch(updateFiltersAction(updatedFilters));
    navigate(appRoutes.shopByMood);
  };

  const handleBrowseAllMoods = () => {
    dispatch(resetFiltersAction());
    navigate(appRoutes.shopByMood);
  };

  return (
    <Box sx={{ p: { md: 5, xs: 1 }, maxWidth: '87vw', margin: 'auto', display: 'block' }}>
      <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Grid container justifyContent="space-between" alignItems="start">
          <Grid item pt={{ xs: '2rem', md: '0rem' }} pb={{ xs: '2rem', sm: '0.5rem', lg: '2rem', md: '1rem' }}>
            <Title>EXPLORE BY CATEGORY</Title>
            <Description>
              Let&apos;s get started on your ideal experience. Explore our product categories and discover their appeal.
            </Description>
          </Grid>
          <Hidden smDown>
            <Grid item pt={{ xs: '0rem', lg: '1rem' }} pb={{ xs: '2rem', md: '0rem' }}>
              <StyledButton onClick={() => handleBrowseAllMoods()}>BROWSE ALL CATEGORIES</StyledButton>
            </Grid>
          </Hidden>
        </Grid>
        <Grid container spacing={2}>
          {moodData.map((mood, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card elevation={0}>
                <CardActionArea onClick={() => handleSelectMood(mood.title)}>
                  <CardMedia component="img" image={mood.svg} alt={`${mood.title} mood`} />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Hidden mdUp>
          <Box mt={2} display="flex" justifyContent="center">
            <StyledButton fullWidth onClick={() => handleBrowseAllMoods()}>
              BROWSE ALL CATEGORIES
            </StyledButton>
          </Box>
        </Hidden>
      </Container>
    </Box>
  );
};

export default ShopByMoods;
