import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { getMoodColors } from '../../../config/consts';

const StyledFooter = styled(AppBar)(({ theme, chemoName }) => ({
  top: 'auto',
  bottom: 0,
  backgroundColor: getMoodColors(chemoName).color,
  position: 'static'
}));

const FooterContent = styled(Box)(({ theme }) => ({
  color: 'white',
  position: 'relative',
  paddingRight: theme.spacing(20)
}));

const CallToActionText = styled(Typography)(({ theme }) => ({
  marginRight: '10px',
  fontSize: '20px'
}));

const QRCodeContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  bottom: 0,
  zIndex: 2
}));

const BiggerText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '20px'
}));
const ReportBottomBar = ({ qr, chemoName, isReport }) => {
  return (
    <>
      <StyledFooter position="fixed" chemoName={chemoName}>
        <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
          <FooterContent>
            <Box display="flex" alignItems="center">
              <CallToActionText>Scan to </CallToActionText>
              <BiggerText>LEARN MORE & PURCHASE</BiggerText>
            </Box>
            <QRCodeContainer>
              {/* <QRCode /> */}
              <img src={`${qr.qrCode}`} alt={'QR'} style={{ width: '100px', height: '100px' }} />
            </QRCodeContainer>
          </FooterContent>
        </Toolbar>
      </StyledFooter>
    </>
  );
};

export default ReportBottomBar;
