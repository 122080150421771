import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ParentContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const ParentGrid = styled(Grid)(({ theme, isBelowLargeScreen, isHomeView }) =>
  isHomeView
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: isBelowLargeScreen ? '100%' : isHomeView ? '100%' : '1600px',
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
          minWidth: '100vw'
        }
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
          minWidth: 'calc(100vw + -16px)'
        }
      }
);

const TitleContainer = styled(Grid)(({ isBelowLargeScreen }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'none'
  // padding: "0rem 0rem"
}));

const FindYourTypography = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '40px',
  letterSpacing: '0.25px',
  marginRight: '8px',
  color: '#000000',
  [theme.breakpoints.down('md')]: {
    fontSize: '26px'
  }
}));

const PerfectMatchTypography = styled(Typography)(({ theme }) => ({
  fontSize: '46px',
  fontStyle: 'normal',
  fontFamily: 'Space Grotesk',
  fontWeight: 500,
  lineHeight: '60px',
  letterSpacing: '0.25px',
  maxWidth: '800px',
  textAlign: 'center',
  color: '#000000',
  [theme.breakpoints.down('lg')]: {
    fontSize: '26px',
    fontWeight: 500,
    lineHeight: '40px',
    width: '80%'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    fontWeight: 500
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '1rem',
    padding: '0 1rem'
  }
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Inter',
  lineHeight: '24px',
  letterSpacing: '0.25px',
  maxWidth: '900px',
  color: '#000000',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '0 1rem'
  }
}));

export { DescriptionTypography, FindYourTypography, ParentContainer, ParentGrid, PerfectMatchTypography, TitleContainer };
