import { createSelector } from 'reselect';

const selectCartItems = (state) => state.checkout.items;

export const selectItemsCount = createSelector([selectCartItems], (items) => items.length);

export const selectItemsPrice = createSelector([selectCartItems], (items) =>
  items.reduce((total, item) => total + item.price * item.quantity, 0)
);

const selectProductById = createSelector([selectCartItems, (state, productId) => productId], (items, productId) =>
  items.find((item) => item.id === productId)
);

export const selectProductTotalPrice = createSelector([selectProductById], (product) => (product ? product.price * product.quantity : 0));

export const selectOrderTotal = createSelector([selectItemsPrice], (itemsPrice) => {
  // const cannabisTax = 5;
  // const rlSalesTax = 0.99;
  return itemsPrice;
});
export const selectCheckoutProducts = (state) => state.checkout?.items ?? [];
export const selectedPaymentMethod = (state) => state.checkout.paymentDetails.paymentMethod;
export const selectCompletedOrder = (state) => state.checkout.completedOrder;
export const selectPaymentDetails = (state) => state.checkout.paymentDetails;
export const selectCartType = (state) => state.checkout?.cartType ?? null;
export const selectCartDialog = (state) => state.checkout.cartDialog;
export const selectUserId = (state) => state.user?.userDetails?.id ?? null;
