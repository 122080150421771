import { useEffect, useState } from 'react';

const useStepTransition = (setShowFirstPopper, setShowSecondPopper, handleProductReveal, poppersAlreadyShown) => {
  const [stepState, setStepState] = useState({ currentStep: 0 });

  useEffect(() => {
    const initialStep = poppersAlreadyShown ? 2 : 0;
    setStepState({ currentStep: initialStep });
  }, [poppersAlreadyShown]);

  const handleNext = () => {
    const nextStep = stepState.currentStep + 1;

    if (nextStep === 1) {
      setShowFirstPopper(false);
      setShowSecondPopper(true);
    } else if (nextStep === 2) {
      setShowSecondPopper(false);
    } else if (nextStep === 3) {
      handleProductReveal();
    }

    setStepState({ currentStep: nextStep > 2 ? 2 : nextStep });
  };

  const handleBack = () => {
    const prevStep = stepState.currentStep - 1;

    if (prevStep === 0) {
      setShowSecondPopper(false);
      setShowFirstPopper(true);
    } else if (prevStep === 1) {
      setShowSecondPopper(true);
    }

    if (prevStep >= 0) {
      setStepState({ currentStep: prevStep });
    }
  };

  return { stepState, handleNext, handleBack };
};
export default useStepTransition;
