import {
  SET_USER_VERIFICATION_INIT,
  SET_USER_VERIFICATION,
  SET_PHONE_DIALOG_STATE,
  RESET_USER_VERIFICATION
} from '../consts/reduxActionTypes';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Initial states
const initialUserVerificationState = {
  userVerified: false,
  userTypeFilter: null,
  userDetails: null,
  userPhoneNumber: null,
  isNewUser: null,
  phoneNumberDialog: false
};

const userReducer = (state = initialUserVerificationState, action) => {
  switch (action.type) {
    case SET_USER_VERIFICATION_INIT:
      return {
        ...state
      };
    case SET_USER_VERIFICATION:
      return action.payload;
    case SET_PHONE_DIALOG_STATE:
      return {
        ...state,
        phoneNumberDialog: action.payload
      };
    case RESET_USER_VERIFICATION:
      return initialUserVerificationState;
    default:
      return state;
  }
};

const persistConfig = {
  key: 'user',
  storage
};

export default persistReducer(persistConfig, userReducer);
