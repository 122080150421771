import { styled } from '@mui/material/styles';
import { Typography, Grid, Button, Paper } from '@mui/material';
import backgroundImage from '../../../../assets/icons/homePage/multi-color-bg.png';

const HeaderSection = styled(Paper)(({ theme }) => ({
  background: `url(${backgroundImage}) no-repeat center center`,
  backgroundSize: 'cover',
  color: theme.palette.common.white,
  textAlign: 'center',
  padding: theme.spacing(4, 2),
  maxWidth: '100%',
  width: '100%',
  margin: 'auto',
  display: 'block',

  '> .MuiGrid-container': {
    position: 'relative',
    zIndex: 2
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '27px',
    color: 'var(--Neutrals-Black, #fff)'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    color: 'var(--Neutrals-Black, #fff)'
  }
}));
const Description = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
    marginTop: '8px'
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: '32px'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: '2px solid white',
  color: 'white'
}));

export { HeaderSection, Title, Description, StyledButton };
