import { Box, Grid, Paper, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import NewLogo from '../../../../assets/NewLogo.svg';
import backgroundImage from '../../../../assets/icons/homePage/background.svg';
import HomePageCards from './homPageCards';
import Container from '../../../../components/Container';
import zIndex from '@mui/material/styles/zIndex';

const HeaderSection = styled(Paper)(({ theme }) => ({
  position: 'relative',
  background: `url(${backgroundImage}) no-repeat center center`,
  backgroundSize: 'cover',
  color: theme.palette.common.white,
  textAlign: 'center',
  padding: theme.spacing(4, 2),
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.65)',
    mixBlendMode: 'multiply',
    pointerEvents: 'none',
    zIndex: 1
  },
  '> .MuiGrid-container': {
    position: 'relative',
    zIndex: 2
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  fontFamily: 'Space Grotesk',
  fontSize: '36px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '42px',
  marginBottom: '12px',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px'
  }
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '26px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '28px',
  letterSpacing: '0.25px',
  marginBottom: '35px',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px'
  }
}));

const LogoImage = styled('img')(({ theme, isMobile }) => ({
  maxWidth: '100%',
  height: isMobile ? 'auto' : 'initial'
}));

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <HeaderSection>
      <Container style={{ zIndex: 2 }}>
        <Grid container spacing={{ md: 2, xs: 1 }} justifyContent="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
              {/* <LogoImage src={PowerleafLogo} alt="Powerleaf Logo" isMobile={isMobile} /> */}
              <LogoImage src={NewLogo} alt="Powerleaf Logo" isMobile={isMobile} style={{ marginTop: 40, marginBottom: 36 }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Title variant="h3">A NEW EXPERIENCE AWAITS...</Title>
            <Subtitle variant="subtitle1">Developed by doctors. Backed by science. Powered by data and machine learning.</Subtitle>
          </Grid>

          <HomePageCards />
        </Grid>
      </Container>
    </HeaderSection>
  );
};

export default Header;
