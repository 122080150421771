import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { activityConsts, symptomsConsts } from '../../consts/shopByConsts';
import { productTypeConsts } from '../../consts/shopByProductType';
import AppLayout from '../../layouts/appLayout/appLayout';
import { selectUserDetails } from '../../store/selectors/userSelectors';
import AgeVerification from '../AgeVerification/AgeVerification';
import PerfectMatch from '../shopBy/perfectMatch/shopByPerfectMatch';
import Header from './components/Header/header';
import Media from './components/Media/media';
import ShopByActivityHome from './components/ShopByActivityHome/ShopByActivityHome';
import ShopByCategoriesHome from './components/ShopByCategoriesHome/ShopByCategoriesHome';
import ShopByMedicinalHome from './components/ShopByMedicinalHome/ShopByMedicinalHome';
import ShopByMoods from './components/ShopByMoods/ShopByMoods';
import ShopThePowerleafWay from './components/ShopThePowerLeafWay/ShopThePowerleafWay';
import useAnalytics from '../../useAnalytics';
import { resetFiltersAction } from '../../store/actions/shopByActions';

// Actions

const HomePage = () => {
  useAnalytics('page_view', { page_title: 'Home Page' });
  // Dispatch
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userDetails = useSelector(selectUserDetails);
  const userVerfificationState = useSelector((state) => state.user.userVerified);

  // UseEffect
  useEffect(() => {
    // if (userDetails === null) {
    //   navigate('/');
    // }
    // dispatch(resetFiltersAction());
    dispatch(resetFiltersAction());
  }, []);

  return (
    <AppLayout>
      {!userVerfificationState && <AgeVerification isProductDetail={true} />}
      <Header />
      <Media />
      <ShopByActivityHome filters={activityConsts} />
      <PerfectMatch isHomeView />
      <ShopByMoods />
      <ShopThePowerleafWay />
      <ShopByMedicinalHome filters={symptomsConsts} />
      <ShopByCategoriesHome filters={productTypeConsts} />
    </AppLayout>
  );
};
export default HomePage;
