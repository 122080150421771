import { useState, useRef, useEffect } from 'react';

export const usePopper = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef(null);
  
    // Function to toggle the popper
    const togglePopper = () => {
      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(ref.current);
      }
    };
  
    // Set the popper to be open by default
    useEffect(() => {
      setAnchorEl(ref.current);
    }, []);
  
    return { ref, anchorEl, togglePopper };
  };