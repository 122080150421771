import { Button, Grid, Hidden, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Mousewheel, Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import FilterCard from '../../../../components/cards/filterCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Actions
import { resetFiltersAction, updateFiltersAction } from '../../../../store/actions/shopByActions';

// Routes
import { appRoutes } from '../../../../routes/appRoutes';
import Container from '../../../../components/Container';

const HeaderSection = styled(Grid)(({ theme, filters, isHomeView, isMobile }) => ({
  position: 'relative',
  color: theme.palette.common.white,
  margin: 'auto',
  textAlign: 'start',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4, 4)
}));
const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '27px',
    color: 'var(--Neutrals-Black, #000)'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    fontFamily: 'Space Grotesk',
    color: 'var(--Neutrals-Black, #000)'
  }
}));
const Description = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 400,
  color: '#000',
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
    marginTop: '8px'
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: '32px'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: '2px solid black',
  color: 'black',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 700
}));

const swiperSettings = {
  breakpoints: {
    1400: {
      slidesPerView: 6.2
    },
    1300: {
      slidesPerView: 5.6
    },
    1100: {
      slidesPerView: 4.8
    },
    880: {
      slidesPerView: 4
    },
    800: {
      slidesPerView: 3
    },
    600: {
      slidesPerView: 2.5
    },
    500: {
      slidesPerView: 2
    },
    400: {
      slidesPerView: 1.5
    },
    360: {
      slidesPerView: 1.25
    },
    300: {
      slidesPerView: 1
    }
  }
};

const ShopByMedicinalHome = ({ filters }) => {
  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Handlers
  function getTitleById(selectedId) {
    const category = filters.categories.find((c) => c.id === selectedId);
    return category ? category.title : 'Category not found';
  }

  const handleCardClick = (id) => {
    dispatch(resetFiltersAction());
    const updatedFilters = {
      page: 1,
      pageSize: 10,
      Wellness: getTitleById(id)
    };
    dispatch(updateFiltersAction(updatedFilters));
    navigate(appRoutes.shopBySymptoms);
  };

  const handleBrowseAllActivities = () => {
    dispatch(resetFiltersAction());
    navigate(appRoutes.shopBySymptoms);
  };
  return (
    <HeaderSection container filters={filters} isMobile={isMobile}>
      <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item justifyContent={'start'} justifyItems={'start'} xs={9}>
            <Title>EXPLORE WELLNESS APPLICATIONS</Title>
            <Description>Smart product pairings for potential relief, based on product ingredients</Description>
            <Description style={{ marginTop: isMobile ? '0' : '-16px', fontSize: '11px', marginBottom: '0' }}>
              <b>DISCLAIMER: </b> Cannabis is classified as a Schedule I contolled substance under federal law, and the United States
              government does not currently recognize any medicinal benefit from it&apos;s use. Suggested pairings are not meant to imply
              that products have any accepted medicinal role in the treatment or cure of a specific illness or disease.
            </Description>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <StyledButton onClick={() => handleBrowseAllActivities()}>BROWSE ALL APPLICATIONS</StyledButton>
            </Grid>
          </Hidden>
        </Grid>
        <Grid container spacing={1} item mt={6} display={'flex'} justifyContent={'center'} sx={{ overflow: 'hidden' }}>
          <Swiper
            {...swiperSettings}
            modules={[Scrollbar, Mousewheel, Navigation]}
            scrollbar={{ draggable: true }}
            mousewheel={true}
            direction="horizontal"
            navigation
            style={{ '--swiper-navigation-color': 'black' }}
          >
            {filters.categories.map((category, index) => (
              <SwiperSlide key={index}>
                <Grid item key={category.id} style={{ marginBottom: '20px', marginLeft: '15px' }}>
                  <FilterCard
                    title={category.title}
                    image={category.image}
                    onClick={() => handleCardClick(category.id)}
                    isSelected={null}
                  />
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Hidden mdUp>
          <Grid mt={2} xs={12} textAlign={'center'}>
            <StyledButton fullWidth onClick={() => handleBrowseAllActivities()}>
              BROWSE ALL MEDICINAL
            </StyledButton>
          </Grid>
        </Hidden>
      </Container>
    </HeaderSection>
  );
};

export default ShopByMedicinalHome;
