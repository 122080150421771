import React from 'react';
import Card from '@mui/material/Card';
import ButtonBase from '@mui/material/ButtonBase';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectedPaymentMethod } from '../../../store/checkout/selectors/checkoutSelectors';

const PaymentMethodCard = ({ icon, title, fee, description, onClick }) => {
  const payment = useSelector(selectedPaymentMethod);

  const cardStyle = {
    textAlign: 'center',
    justifyContent: 'center',
    ...(payment === title
      ? {
          border: '3px solid transparent',
          borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
          borderImageSlice: 1
        }
      : {})
  };

  return (
    <Card style={cardStyle}>
      <ButtonBase style={{ width: '100%', display: 'block', textAlign: 'initial', justifyContent: 'center' }} onClick={onClick}>
        <CardMedia style={{ marginTop: '0.5em', textAlign: 'center', justifyContent: 'center' }}>{icon}</CardMedia>

        <CardContent style={{ margin: 0, paddingTop: 0, textAlign: 'center', justifyContent: 'center' }}>
          <Typography style={{ margin: 0, padding: 0 }} gutterBottom variant="h4" component="div">
            {title}
          </Typography>
          <Typography style={{ margin: 0, padding: 0 }} gutterBottom variant="h5" component="div">
            {fee}
          </Typography>
          <Typography style={{ margin: 0, padding: 0 }} variant="body2" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
      </ButtonBase>
    </Card>
  );
};

export default PaymentMethodCard;
