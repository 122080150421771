import ApiBaseHelper from '../apiBaseHelper';
import ApiLinks from '../apiLinks';

const verifyUserPhoneNumber = async (number) => {
    const payload = {
        "phoneNumber": number
    };

    const response = await ApiBaseHelper.post(`${ApiLinks.VERIFY_PHONE_NUMBER}`, payload);
    return response;
};

export {
    verifyUserPhoneNumber,
}