import { Grid, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

// Hooks
import { usePopper } from '../../hooks/usePopper';

// Components
import Slider from '@mui/material/Slider';
import CustomPopper from '../poppers/customPopper';
import NofiltersCard from './noFiltersCard';

// Assets
import powerLeafLogoPerfectMatch from '../../assets/images/shopBy/perfectMatchNew/logo/powerLeafLogoPerfectMatch.svg';

// Consts
import { popperSlideFilters, sliderConsts } from '../../consts/shopbyPerfectMatchConsts';

const CardImageWrapper = styled('div')(({ isGreater }) => ({
  width: isGreater ? '262px' : '380px',
  height: isGreater ? '151px' : '220px',
  overflow: 'visible',
  display: 'flex', // Ensures that the child elements are flex items
  justifyContent: 'center', // Centers the child elements along the line (for horizontal centering)
  alignItems: 'center', // Centers the child elements across the line (for vertical centering)
  borderRadius: '6px'
}));

const CardImage = styled('img')({
  minWidth: '100%', // Ensures that the image covers the width of the container
  minHeight: '100%', // Ensures that the image covers the height of the container
  objectFit: 'cover',
  // objectPosition: 'center' // Ensures the image is centered in the container
});

const CardSlider = styled(Slider)({
  width: '230px',
  '& .MuiSlider-thumb': {
    width: '50px',
    height: '50px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-track': {
    height: '10px',
    borderRadius: '3px'
  },
  '& .MuiSlider-rail': {
    height: '10px',
    borderRadius: '3px',
    opacity: 0.6,
    backgroundColor: '#bfbfbf'
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'transparent',
    '&.MuiSlider-markActive': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiSlider-markLabel': {
    //   top: '40px',
    color: '#000',
    fontSize: '0.875rem'
  }
});

// Create stytled typograpy cetner alligned
const StyledTypography = styled(Typography)({
  textAlign: 'center',
  fontSize: 20,
  fontStyle: 'normal',
  marginTop: '5px'
});

const PerfectMatchCard = ({ title, noFiltersDescription, steps, currentStep, onStepChange, index, showFirstPopper, toggleFirstPopper }) => {
  // Hooks
  const { ref, anchorEl, togglePopper } = usePopper();
  const isGreater = useMediaQuery('(max-width:1600px)');

  return (
    <Grid style={{ maxWidth: '1366px' }}>
      <CardImageWrapper isGreater={true}>
        {currentStep === 0 ? (
          <NofiltersCard logo={powerLeafLogoPerfectMatch} text={noFiltersDescription} />
        ) : (
          <CardImage src={steps[currentStep].icon} alt={title} />
        )}
      </CardImageWrapper>
      <StyledTypography gutterBottom fontFamily="Space Grotesk" fontSize="20px" fontWeight={500} color="#000">
        {title}
      </StyledTypography>
      <Grid container justifyContent="center" ref={ref}>
        <CardSlider
          value={currentStep}
          min={0}
          max={4}
          onChange={(e, value) => onStepChange(value)}
          componentsProps={{
            thumb: {
              style: {
                backgroundImage: `url(${sliderConsts[currentStep].thumb})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }
            }
          }}
          sx={{
            '.MuiSlider-track': {
              backgroundImage: `url(${sliderConsts[currentStep].track})`
            }
          }}
        />
        {index === 0 && showFirstPopper && (
          <CustomPopper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={() => {
              togglePopper();
              toggleFirstPopper();
            }}
            popperDetails={popperSlideFilters}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PerfectMatchCard;
