import React from 'react';
import { getMoodColors } from '../../../config/consts';

const ProductExperienceLogoSleep = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="50" height="50" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="34.0921" fill={color} stroke={color} strokeWidth="1.81579" />
      <path
        d="M25.716 35.9204C28.7652 35.9204 31.2265 33.452 31.2265 30.3941C31.2265 27.3362 28.7652 24.8678 25.716 24.8678C22.6668 24.8678 20.2054 27.3362 20.2054 30.3941C20.2054 33.452 22.6668 35.9204 25.716 35.9204ZM47.7583 24.8678H33.0634V37.7625H18.3685V21.1836H14.6948V48.8151H18.3685V43.2888H51.432V48.8151H55.1057V32.2362C55.1057 28.1652 51.8177 24.8678 47.7583 24.8678Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoRelax = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.3515" fill="#007FFF" stroke="#007FFF" strokeWidth="1.29699" />
      <path
        d="M35.8147 22.9704C34.559 22.9704 33.5317 23.9978 33.5317 25.2535V28.6781H17.5501V25.2535C17.5501 23.9978 16.5341 22.9704 15.267 22.9704C13.9999 22.9704 12.9839 23.9978 12.9839 25.2535V30.9612C12.9839 32.2169 14.0113 33.2443 15.267 33.2443H35.8147C37.0704 33.2443 38.0978 32.2169 38.0978 30.9612V25.2535C38.0978 23.9978 37.0704 22.9704 35.8147 22.9704ZM32.3901 17.2627H18.6916C17.4359 17.2627 16.4085 18.2901 16.4085 19.5458V22.0001C17.7327 22.4681 18.6916 23.7352 18.6916 25.2078V27.5366H32.3901V25.2192C32.3901 23.7352 33.349 22.4795 34.6732 22.0115V19.5458C34.6732 18.2901 33.6458 17.2627 32.3901 17.2627Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoRecover = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="50" height="50" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25.5" cy="25.9531" r="24.3515" fill="#EB6E23" stroke="#EB6E23" strokeWidth="1.29699" />
      <path
        d="M31.7073 17.078L34.7365 14.0488H24.1543V24.631L27.1967 21.5887C29.8026 23.5199 31.4957 26.5887 31.4957 30.0809C31.4957 31.8137 31.0724 33.4407 30.3317 34.8825C33.4137 32.8719 35.464 29.4063 35.464 25.4512C35.464 22.1178 33.9957 19.1415 31.7073 17.078Z"
        fill="white"
      />
      <path
        d="M31.7073 17.078L34.7365 14.0488H24.1543V24.631L27.1967 21.5887C29.8026 23.5199 31.4957 26.5887 31.4957 30.0809C31.4957 31.8137 31.0724 33.4407 30.3317 34.8825C33.4137 32.8719 35.464 29.4063 35.464 25.4512C35.464 22.1178 33.9957 19.1415 31.7073 17.078Z"
        fill="white"
      />
      <path
        d="M19.4683 21.8261C19.4683 20.0933 19.8916 18.4662 20.6324 17.0244C17.5371 19.035 15.5 22.5007 15.5 26.4558C15.5 29.7892 16.9551 32.7654 19.2567 34.829L16.2143 37.8581H26.7965V27.2759L23.7541 30.3183C21.1615 28.3871 19.4683 25.3182 19.4683 21.8261Z"
        fill="white"
      />
      <path
        d="M19.4683 21.8261C19.4683 20.0933 19.8916 18.4662 20.6324 17.0244C17.5371 19.035 15.5 22.5007 15.5 26.4558C15.5 29.7892 16.9551 32.7654 19.2567 34.829L16.2143 37.8581H26.7965V27.2759L23.7541 30.3183C21.1615 28.3871 19.4683 25.3182 19.4683 21.8261Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoIndulge = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="50" height="50" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25.4766" r="24.0921" fill="#654EA3" stroke="#654EA3" strokeWidth="1.81579" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7443 37.2314L24.6431 38.922L26.5419 37.2314C28.7463 35.2592 30.5687 33.5578 32.0092 32.1274C33.4496 30.6969 34.5955 29.4128 35.4467 28.2749C36.2979 27.1371 36.8926 26.0913 37.2309 25.1377C37.5692 24.1841 37.7383 23.2088 37.7383 22.2118C37.7383 20.1745 37.0508 18.4731 35.6758 17.1077C34.3008 15.7423 32.5875 15.0596 30.536 15.0596C29.401 15.0596 28.3207 15.298 27.2949 15.7748C26.2691 16.2516 25.3852 16.9235 24.6431 17.7904C23.901 16.9235 23.0171 16.2516 21.9913 15.7748C20.9655 15.298 19.8852 15.0596 18.7502 15.0596C16.6986 15.0596 14.9854 15.7423 13.6104 17.1077C12.2354 18.4731 11.5479 20.1745 11.5479 22.2118C11.5479 23.2088 11.717 24.1841 12.0553 25.1377C12.3936 26.0913 12.9883 27.1371 13.8395 28.2749C14.3802 28.9977 15.0398 29.7795 15.8183 30.6204L21.0588 25.2729L23.923 28.1371L28.717 23.0908H26.4305V20.68H32.8679V27.1175H30.4572V24.7592L23.9672 31.5907L21.0761 28.6996L17.5004 32.3483C18.9044 33.732 20.6523 35.3597 22.7443 37.2314Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoMotivate = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.3515" fill="#88B04B" stroke="#88B04B" strokeWidth="1.29699" />
      <path
        d="M23.69 32.8578C21.5075 32.8578 19.6523 32.094 18.1245 30.5662C16.5968 29.0384 15.8329 27.1832 15.8329 25.0007C15.8329 24.7606 15.8438 24.5205 15.8656 24.2805C15.8874 24.0404 15.9202 23.8003 15.9638 23.5602C15.8547 23.6039 15.7237 23.6366 15.571 23.6584C15.4182 23.6803 15.2872 23.6912 15.1781 23.6912C14.2614 23.6912 13.4866 23.3747 12.8537 22.7418C12.2208 22.1088 11.9043 21.334 11.9043 20.4174C11.9043 19.5007 12.2044 18.7259 12.8046 18.093C13.4048 17.46 14.1632 17.1436 15.0799 17.1436C15.8001 17.1436 16.4494 17.3454 17.0278 17.7492C17.6062 18.153 18.0154 18.6713 18.2555 19.3043C18.9757 18.6495 19.7996 18.1257 20.7272 17.7328C21.6548 17.34 22.6424 17.1436 23.69 17.1436H38.0948V22.3817H31.5472V25.0007C31.5472 27.1832 30.7833 29.0384 29.2555 30.5662C27.7277 32.094 25.8726 32.8578 23.69 32.8578ZM15.1781 21.7269C15.5491 21.7269 15.8601 21.6014 16.1111 21.3504C16.3621 21.0994 16.4876 20.7884 16.4876 20.4174C16.4876 20.0463 16.3621 19.7353 16.1111 19.4843C15.8601 19.2333 15.5491 19.1078 15.1781 19.1078C14.8071 19.1078 14.4961 19.2333 14.2451 19.4843C13.9941 19.7353 13.8686 20.0463 13.8686 20.4174C13.8686 20.7884 13.9941 21.0994 14.2451 21.3504C14.4961 21.6014 14.8071 21.7269 15.1781 21.7269ZM23.69 27.6197C24.4102 27.6197 25.0268 27.3633 25.5397 26.8504C26.0526 26.3375 26.3091 25.7209 26.3091 25.0007C26.3091 24.2805 26.0526 23.6639 25.5397 23.151C25.0268 22.6381 24.4102 22.3817 23.69 22.3817C22.9698 22.3817 22.3532 22.6381 21.8403 23.151C21.3274 23.6639 21.071 24.2805 21.071 25.0007C21.071 25.7209 21.3274 26.3375 21.8403 26.8504C22.3532 27.3633 22.9698 27.6197 23.69 27.6197Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogoElevate = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.3515" fill="#FFC700" stroke="#FFC700" strokeWidth="1.29699" />
      <path
        d="M23.8091 16.6672V11.9053H26.19V16.6672H23.8091ZM31.7257 19.941L30.0888 18.3041L33.4222 14.8815L35.0888 16.5779L31.7257 19.941ZM33.3329 26.191V23.81H38.0948V26.191H33.3329ZM23.8091 38.0957V33.3338H26.19V38.0957H23.8091ZM18.2733 19.8815L14.8805 16.5779L16.5769 14.9112L19.94 18.2743L18.2733 19.8815ZM33.3924 35.1196L30.0888 31.6969L31.696 30.0898L35.0888 33.3636L33.3924 35.1196ZM11.9043 26.191V23.81H16.6662V26.191H11.9043ZM16.5769 35.1196L14.9102 33.4231L18.2436 30.0898L19.1067 30.8934L19.9698 31.7267L16.5769 35.1196ZM24.9995 32.1434C23.0154 32.1434 21.3289 31.4489 19.94 30.06C18.5511 28.6711 17.8567 26.9846 17.8567 25.0005C17.8567 23.0164 18.5511 21.3299 19.94 19.941C21.3289 18.5521 23.0154 17.8577 24.9995 17.8577C26.9837 17.8577 28.6702 18.5521 30.0591 19.941C31.4479 21.3299 32.1424 23.0164 32.1424 25.0005C32.1424 26.9846 31.4479 28.6711 30.0591 30.06C28.6702 31.4489 26.9837 32.1434 24.9995 32.1434Z"
        fill="white"
      />
    </svg>
  );
};

const ProductExperienceLogosMap = {
  ELEVATE: <ProductExperienceLogoElevate chemoName={'ELEVATE'} />,
  MOTIVATE: <ProductExperienceLogoMotivate chemoName={'MOTIVATE'} />,
  INDULGE: <ProductExperienceLogoIndulge chemoName={'INDULGE'} />,
  RECOVER: <ProductExperienceLogoRecover chemoName={'RECOVER'} />,
  RELAX: <ProductExperienceLogoRelax chemoName={'RELAX'} />,
  SLEEP: <ProductExperienceLogoSleep chemoName={'SLEEP'} />
};

const ProductExperienceLogoSVG = ({ chemoName }) => {
  return ProductExperienceLogosMap[chemoName] || <ProductExperienceLogoSleep />;
};

export default ProductExperienceLogoSVG;
