import { Box, FormControl, InputLabel, MenuItem, Select, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import { selectSelectedFilters, selectTotalProducts } from '../../store/selectors/shopBySelectors';

// Actions
import { fetchProductsLoadingAction, updateFiltersAction } from '../../store/actions/shopByActions';

// Styled components
const TotalItems = styled(Box)`
  margin-left: 12px;
  color: var(--Neutrals-Black, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const SortByFormControl = styled(FormControl)`
  margin-left: 12px;
  margin-right: 12px;

  & .MuiInputBase-root {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  & .MuiSelect-select {
    display: flex;
    align-items: center;
  }

  & .MuiSelect-icon {
    color: var(--Neutrals-Black, #000);
  }
`;

const SortByLabel = styled(InputLabel)`
  color: var(--Neutrals-Black, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

const SortByText = styled(Typography)`
  color: var(--Neutrals-Black, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const SortComponent = () => {
  // Dispatch
  const dispatch = useDispatch();

  //Selectors
  const selectedFilters = useSelector(selectSelectedFilters);
  const totalItems = useSelector(selectTotalProducts);

  // States
  const [sortOrder, setSortOrder] = useState(
    selectedFilters.sort ? (selectedFilters.sort === 'low' ? 'lowToHigh' : selectedFilters.sort === `${selectedFilters['Terpenes']}_high` ? `${selectedFilters['Terpenes']}_high` : selectedFilters.sort === `${selectedFilters['Cannabinoids']}_high` ? `${selectedFilters['Cannabinoids']}_high` : 'highToLow') : 'lowToHigh'
  );

  // Handlers
  const handleChange = (event) => {
    setSortOrder(event.target.value);
    const updatedFilters = {
      ...selectedFilters,
      page: 1,
      pageSize: 10,
      sort: event.target.value === 'lowToHigh' ? 'low' : event.target.value === `${selectedFilters['Terpenes']}_low` ? `${selectedFilters['Terpenes']}_low` : event.target.value === `${selectedFilters['Terpenes']}_high` ? `${selectedFilters['Terpenes']}_high` : event.target.value === `${selectedFilters['Cannabinoids']}_high` ? `${selectedFilters['Cannabinoids']}_high` : 'high'
    };
    dispatch(fetchProductsLoadingAction(true));
    dispatch(updateFiltersAction(updatedFilters));
  };

  useEffect(() => {
    if (selectedFilters['Terpenes'] !== undefined) {
      setSortOrder(`${selectedFilters['Terpenes']}_high`);
    } else if (selectedFilters['Cannabinoids'] !== undefined) {
      setSortOrder(`${selectedFilters['Cannabinoids']}_high`);
    } else {
      setSortOrder('lowToHigh');
    }
  }, [selectedFilters['Terpenes'], selectedFilters['Cannabinoids']]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
      <TotalItems>{totalItems} Items</TotalItems>
      <Box display="flex" alignItems="center">
        <SortByLabel>Sort By:</SortByLabel>
        <SortByFormControl size="small">
          <Select sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }} value={sortOrder} onChange={handleChange}>
            <MenuItem value="lowToHigh">
              <SortByText>Price Low to High</SortByText>
            </MenuItem>
            <MenuItem value="highToLow">
              <SortByText>Price High to Low</SortByText>
            </MenuItem>
            {/* {
              selectedFilters['Terpenes'] !== undefined && (
                <MenuItem value={`${selectedFilters['Terpenes']}_low`}>
                  <SortByText>{selectedFilters['Terpenes']} Low to High</SortByText>
                </MenuItem>
              )
            } */}

            {
              selectedFilters['Terpenes'] !== undefined && (
                <MenuItem value={`${selectedFilters['Terpenes']}_high`}>
                  <SortByText>{selectedFilters['Terpenes']} High to Low</SortByText>
                </MenuItem>
              )
            }

            {
              selectedFilters['Cannabinoids'] !== undefined && (
                <MenuItem value={`${selectedFilters['Cannabinoids']}_high`}>
                  <SortByText>{selectedFilters['Cannabinoids']} High to Low</SortByText>
                </MenuItem>
              )
            }
          </Select>
        </SortByFormControl>
      </Box>
    </Box>
  );
};

export default SortComponent;
