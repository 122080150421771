import { Button, Grid, Hidden, Typography, styled, useMediaQuery } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import 'swiper/css';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductTypeCard from '../../../../components/cards/productTypeCard';

// Actions
import { resetFiltersAction, updateFiltersAction } from '../../../../store/actions/shopByActions';

// Routes
import { appRoutes } from '../../../../routes/appRoutes';
import Container from '../../../../components/Container';

const HeaderSection = styled(Grid)(({ theme, filters }) => ({
  position: 'relative',
  background: `url(${filters.backgroundImage}) no-repeat center center`,
  backgroundSize: 'cover',
  color: theme.palette.common.white,
  // maxWidth: '82vw',
  margin: 'auto',
  textAlign: 'start',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4, 4),
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.65)',
    mixBlendMode: 'multiply',
    pointerEvents: 'none',
    zIndex: -1,
    textAlign: 'start',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
// Styles
const ProductTypeGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '1133px',
  margin: '0 auto', // centers the grid in its parent
  padding: theme.spacing(3)
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Space Grotesk',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '27px',
    color: 'var(--Neutrals-Black, #fff)'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    color: 'var(--Neutrals-Black, #fff)'
  }
}));
const Description = styled(Typography)(({ theme }) => ({
  color: 'var(--Neutrals-Black, #fff)',

  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
    marginTop: '8px'
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: '32px'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: '2px solid white',
  color: 'white',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 700
}));

const swiperSettings = {
  slidesPerView: 2,
  spaceBetween: 16,
  breakpoints: {
    600: {
      slidesPerView: 3
    },
    960: {
      slidesPerView: 4
    }
  }
};

const ShopByCategoriesHome = ({ filters }) => {
  const isBelowLargeScreen = useMediaQuery('(max-width:1330px)');

  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  // Handlers
  const handleBrowseAllCategories = () => {
    dispatch(resetFiltersAction());
    navigate(appRoutes.shopByProductType);
  };

  const handleSelectProduct = (product) => {
    dispatch(resetFiltersAction());
    const updatedFilters = {
      page: 1,
      pageSize: 10,
      'Product Type': product
    };
    dispatch(updateFiltersAction(updatedFilters));
    navigate(appRoutes.shopByProductType);
  };

  return (
    <>
      <HeaderSection container spacing={2} filters={filters}>
        <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item justifyContent={'start'} justifyItems={'start'}>
              <Title>BROWSE BY PRODUCT TYPE</Title>
              <Description>Browse for products of a particular type based on your consumption preferences.</Description>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <StyledButton onClick={() => handleBrowseAllCategories()}>BROWSE ALL CATEGORIES</StyledButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container spacing={2}>
            {isBelowLargeScreen ? (
              <Swiper {...swiperSettings} modules={[Scrollbar]} scrollbar={{ draggable: true }}>
                {filters.types.map((type) => (
                  <SwiperSlide key={type.name}>
                    <ProductTypeCard name={type.name} image={type.image} isSelected={null} onClick={() => handleSelectProduct(type.name)} />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              filters.types.map((type) => (
                <Grid item xs={12} sm={6} md={3} lg={4} key={type.name} sx={{ cursor: 'pointer' }}>
                  <ProductTypeCard name={type.name} image={type.image} isSelected={null} onClick={() => handleSelectProduct(type.name)} />
                </Grid>
              ))
            )}
          </Grid>

          <Hidden mdUp>
            <Grid mt={2} textAlign={'center'}>
              <StyledButton fullWidth onClick={() => handleBrowseAllCategories()}>
                BROWSE ALL CATEGORIES
              </StyledButton>
            </Grid>
          </Hidden>
        </Container>
      </HeaderSection>
    </>
  );
};

export default ShopByCategoriesHome;
