import { styled } from '@mui/material';

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: '1240px',
  width: '90%',
  paddingInline: '1.5rem',
  marginInline: 'auto',
  [theme.breakpoints.down('md')]: {
    paddingInline: '1.25rem'
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    paddingInline: '0.5rem'
  }
}));

const Container = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

export default Container;
