import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components
const ProductName = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.155px',
  whiteSpace: 'nowrap', // Prevent wrapping
  overflow: 'hidden', // Hide overflow
  textOverflow: 'ellipsis', // Add ellipsis for overflow
  wordWrap: 'break-word'
});

const ProductPrice = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '37.08px'
});

const ProductBrandType = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '9px',
  fontWeight: 600,
  lineHeight: '9px',
  letterSpacing: '0.103px',
  textTransform: 'uppercase'
});

const IconContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: 'auto', // Pushes the icons to the bottom
  marginBottom: theme.spacing(2) // Adds some bottom margin
}));

const imageAreaHeight = '140px';

export { ProductName, ProductPrice, ProductBrandType, IconContainer, imageAreaHeight };
