// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC96i7gbqCpNuaG0GyBRjV1wSM6rnrNRXE',
  authDomain: 'powerleaf-dev.firebaseapp.com',
  projectId: 'powerleaf-dev',
  storageBucket: 'powerleaf-dev.appspot.com',
  messagingSenderId: '267112880006',
  appId: '1:267112880006:web:e7e7a4084d458aacaf3e07',
  measurementId: 'G-EM4FM69YQT'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
