// Mui
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh'
}));

const RightContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `linear-gradient(${theme.palette.primary[100]} 0%, ${theme.palette.primary[100]} 10%,  ${theme.palette.primary.lighter} 30%, ${theme.palette.background.default} 45%)`
}));

const ForgotPasswordBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // boxShadow: `0 3px 5px 2px ${theme.palette.primary[100]}`,
  borderRadius: '5px',
  gap: 12
}));

const IconPlaceholder = styled('div')({
  width: 80,
  height: 80,
  borderRadius: '50%',
  backgroundColor: 'grey',
  marginBottom: '1rem'
});

const ProductName = styled(Typography)({
  color: '#1d1b20',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px'
});

const ProductWeight = styled(Typography)({
  color: '#1d1b20',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px'
});

const ProductPrice = styled(Typography)({
  color: '#1d1b20',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
});

const ProductQTY = styled(Typography)({
  color: '#1d1b20',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  marginInline: '12px'
});

const ProductCardIcon = styled(IconButton)({
  height: '28px',
  width: '28px',
  marginTop: '10px'
});

export {
  PageWrapper,
  ForgotPasswordBox,
  IconPlaceholder,
  RightContainer,
  ProductName,
  ProductWeight,
  ProductPrice,
  ProductCardIcon,
  ProductQTY
};
