import { Grid, Typography } from '@mui/material';
import React from 'react';
import ActivityMedicine from '../../../ProductDetails/Components/ProductExperience/ActivityMedicine';
import ExperienceCard from '../../../ProductDetails/Components/ProductExperience/ExperienceCard';
import MoodsComponent from '../../../ProductDetails/Components/ProductExperience/ProductMoods';

const ProductReportExperience = ({ experienceData }) => {
  return (
    <React.Fragment>
      <Typography variant="h3" pl={1} fontSize={"24px"} fontWeight={500} fontFamily={"Space Grotesk"} mt={-2.5}>
        Experience
      </Typography>
      <Grid container>
        <Grid item xs={4} p={1}>
          <ExperienceCard data={experienceData.logo} isReport={true} />
        </Grid>

        <Grid item xs={4} p={1}>
          <ActivityMedicine activities={experienceData.activityPairings} uses={experienceData.medicinalUses} isReport={true} chemoName={experienceData.logo.category} />
        </Grid>

        <Grid item xs={4} p={1}>
          <MoodsComponent chemoName={experienceData.logo.category} data={experienceData.filters} isReport={true} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProductReportExperience;
