import { Box, Card, CardActionArea, CardMedia, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import activityVideo from '../../../../assets/videos/PowerLeafWay-Activity-4.mp4';
import experienceVideo from '../../../../assets/videos/PowerLeafWay-Experience-3.mp4';
import medicinalVideo from '../../../../assets/videos/PowerLeafWay-Medicinal-2.mp4';
import moodVideo from '../../../../assets/videos/PowerLeafWay-Mood-2.mp4';

// Routes
import { appRoutes } from '../../../../routes/appRoutes';

// Actions
import { resetFiltersAction } from '../../../../store/actions/shopByActions';

const CardLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 127, 255, 1) 0%, rgba(3, 9, 114, 1) 20%, rgba(136, 176, 75, 1) 40%, rgba(255, 199, 0, 1) 60%, rgba(235, 110, 35, 1) 80%, rgba(191, 25, 50, 1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 75%',
    backgroundSize: '100% 3px'
  },
  [theme.breakpoints.up('sm')]: {
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 127, 255, 1) 0%, rgba(3, 9, 114, 1) 20%, rgba(136, 176, 75, 1) 40%, rgba(255, 199, 0, 1) 60%, rgba(235, 110, 35, 1) 80%, rgba(191, 25, 50, 1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: '100% 4px'
  },
  [theme.breakpoints.up('lg')]: {
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 127, 255, 1) 0%, rgba(3, 9, 114, 1) 20%, rgba(136, 176, 75, 1) 40%, rgba(255, 199, 0, 1) 60%, rgba(235, 110, 35, 1) 80%, rgba(191, 25, 50, 1) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: '100% 4px'
  },
  textAlign: 'center',
  display: 'inline',
  textDecoration: 'none',
  paddingBottom: '8px'
}));

const cardData = [
  { image: moodVideo, label: 'SHOP BY CATEGORY', route: appRoutes.shopByMood },
  { image: activityVideo, label: 'SHOP BY ACTIVITY', route: appRoutes.shopByActivity },
  { image: experienceVideo, label: 'CUSTOMIZE EXPERIENCE', route: appRoutes.shopByPerfectMatch },
  { image: medicinalVideo, label: 'SHOP BY WELLNESS APPLICATIONS', route: appRoutes.shopBySymptoms }
];

const ShopThePowerleafWayCards = () => {
  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  // Handlers
  const handleNavigate = (route) => {
    dispatch(resetFiltersAction());
    navigate(route);
  };

  return (
    <Grid container spacing={2} maxWidth={'1200px'} margin={'auto'}>
      {cardData.map((way, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card elevation={0}>
            <CardActionArea onClick={() => handleNavigate(way.route)}>
              <CardMedia component="video" playsInline src={way.image} alt={`${way.label}way`} autoPlay loop muted />

              <Box style={{ backgroundColor: '#fff', width: '100%', height: '50px', display: 'block', alignContent: 'center' }}>
                <CardLabel variant="h6" style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: '700' }}>
                  {way.label}
                </CardLabel>
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ShopThePowerleafWayCards;
