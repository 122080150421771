import React, { useState, useEffect } from 'react';

const usePoppersVisibility = () => {
  const [showFirstPopper, setShowFirstPopper] = useState(false);
  const [showSecondPopper, setShowSecondPopper] = useState(false);
  const [poppersAlreadyShown, setPoppersAlreadyShown] = useState(false);

  useEffect(() => {
    const poppersShown = localStorage.getItem('poppersShown');

    if (!poppersShown) {
      setShowFirstPopper(true);
      localStorage.setItem('poppersShown', 'true');
    } else {
      setPoppersAlreadyShown(true);
    }
  }, []);

  return { showFirstPopper, showSecondPopper, setShowFirstPopper, setShowSecondPopper, poppersAlreadyShown };
};

export default usePoppersVisibility;
