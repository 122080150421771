import { Radio } from '@mui/material';

// Consts

const CustomRadio = ({ checked, onChange, value }) => (
  <Radio
    sx={{
      "& .MuiSvgIcon-root": {
        height: 18,
        width: 18,
        color: "#000"
      }
    }}
    // icon={buttonIcons.radioUnchecked}
    // checkedIcon={buttonIcons.radioChecked}
    checked={checked}
    onChange={onChange}
    value={value}
  />
);

export default CustomRadio;