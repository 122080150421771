import { combineReducers } from 'redux';

// Reducers
import productSlice from '../productDetails/reducers/productSlice';
import checkoutReducer from '../checkout/reducers/checkoutSlice';
import { productsReducer, filtersReducer } from './shopByReducers';
import userReducer from './userReducers';

// ==============================|| COMBINE REDUCERS ||============================== //

// ShopBy Reducers
const shopByReducer = combineReducers({
  products: productsReducer,
  filters: filtersReducer
});

// Root reducer
const rootReducer = combineReducers({
  productDetails: productSlice,
  checkout: checkoutReducer,
  shopBy: shopByReducer,
  user: userReducer
});

export default rootReducer;
