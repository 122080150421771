import ApiBaseHelper from '../../../network/apiBaseHelper';
import ApiLinks from '../../../network/apiLinks';

class ProductService {
  static async fetchProductData(id) {
    const response = await ApiBaseHelper.get(`${ApiLinks.PRODUCT_DETAILS}/${id}`);
    return response;
  }
}

export default ProductService;
