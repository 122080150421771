// Assets for slider
import SliderStepFive from '../assets/svgs/sliderTrackStepFive.svg';
import SliderStepFour from '../assets/svgs/sliderTrackStepFour.svg';
import SliderStepOne from '../assets/svgs/sliderTrackStepOne.svg';
import SliderStepThree from '../assets/svgs/sliderTrackStepThree.svg';
import SliderStepTwo from '../assets/svgs/sliderTrackStepTwo.svg';
import SliderUnActive from '../assets/svgs/sliderUnActive.svg';

// Assets for perfect match
import BlissFour from '../assets/images/shopBy/perfectMatchNew/bliss/blissFour.png';
import BlissOne from '../assets/images/shopBy/perfectMatchNew/bliss/blissOne.png';
import BlissThree from '../assets/images/shopBy/perfectMatchNew/bliss/blissThree.png';
import BlissTwo from '../assets/images/shopBy/perfectMatchNew/bliss/blissTwo.png';
import EnergyFour from '../assets/images/shopBy/perfectMatchNew/energy/energyFour.png';
import EnergyOne from '../assets/images/shopBy/perfectMatchNew/energy/energyOne.png';
import EnergyThree from '../assets/images/shopBy/perfectMatchNew/energy/energyThree.png';
import EnergyTwo from '../assets/images/shopBy/perfectMatchNew/energy/energyTwo.png';
import FocusFour from '../assets/images/shopBy/perfectMatchNew/focus/focusFour.png';
import FocusOne from '../assets/images/shopBy/perfectMatchNew/focus/focusOne.png';
import FocusThree from '../assets/images/shopBy/perfectMatchNew/focus/focusThree.png';
import FocusTwo from '../assets/images/shopBy/perfectMatchNew/focus/focusTwo.png';
import RelaxFour from '../assets/images/shopBy/perfectMatchNew/relaxation/relaxFour.png';
import RelaxOne from '../assets/images/shopBy/perfectMatchNew/relaxation/relaxOne.png';
import RelaxThree from '../assets/images/shopBy/perfectMatchNew/relaxation/relaxThree.png';
import RelaxTwo from '../assets/images/shopBy/perfectMatchNew/relaxation/relaxTwo.png';

import SliderLogo from '../assets/svgs/sliderIcon.png';

// Assets for poppers
import { ReactComponent as PopperOneLogo } from '../assets/svgs/popperLogo.svg';
import { ReactComponent as PopperTwoLogo } from '../assets/svgs/popperLogoTwo.svg';

export const sliderConsts = [
  {
    thumb: SliderUnActive,
    track: SliderStepOne
  },
  {
    thumb: SliderLogo,
    track: SliderStepTwo
  },
  {
    thumb: SliderLogo,
    track: SliderStepThree
  },
  {
    thumb: SliderLogo,
    track: SliderStepFour
  },
  {
    thumb: SliderLogo,
    track: SliderStepFive
  }
];

export const perFectMatchConsts = {
  textOne: 'Find your',
  textTwo: "LET'S GET PERSONAL. HOW CAN WE ENHANCE YOU?",
  description: 'Sometimes we all just need a little boost.',
}

export const perfectMatchFiltersConsts = [
  {
    title: 'Bliss',
    caption: 'Euphoric',
    noFiltersDescription: 'Bliss is not important to my desired effect.',
    steps: [
      { value: 0, label: '', icon: null },
      { value: 1, label: 'Light', icon: BlissOne },
      { value: 2, label: 'Uplifted', icon: BlissTwo },
      { value: 3, label: 'Carefree', icon: BlissThree },
      { value: 4, label: 'Euphoric', icon: BlissFour }
    ]
  },
  {
    title: 'Energy',
    caption: 'Upbeat',
    noFiltersDescription: 'Energy is not important to my desired effect.',
    steps: [
      { value: 0, label: '', icon: null },
      { value: 1, label: 'Mellow', icon: EnergyOne },
      { value: 2, label: 'Calm', icon: EnergyTwo },
      { value: 3, label: 'UpBeat', icon: EnergyThree },
      { value: 4, label: 'Excited', icon: EnergyFour }
    ]
  },
  {
    title: 'Focus',
    caption: 'Attentive',
    noFiltersDescription: 'Focus is not important to my desired effect.',
    steps: [
      { value: 0, label: '', icon: null },
      { value: 1, label: 'Aware', icon: FocusOne },
      { value: 2, label: 'Attentive', icon: FocusTwo },
      { value: 3, label: 'Focused', icon: FocusThree },
      { value: 4, label: 'Zoned In', icon: FocusFour }
    ]
  },
  {
    title: 'Relaxation',
    caption: 'Cozy',
    noFiltersDescription: 'Relaxation is not important to my desired effect.',
    steps: [
      { value: 0, label: '', icon: null },
      { value: 1, label: 'Loose', icon: RelaxOne },
      { value: 2, label: 'Relaxed', icon: RelaxTwo },
      { value: 3, label: 'Cozy', icon: RelaxThree },
      { value: 4, label: 'Couch-Locked', icon: RelaxFour }
    ]
  }
];

export const popperRevealProductsConsts = {
  description: 'Tap reveal your products to see recommendations with your desired effects.',
  buttonTitle: 'GOT IT!',
  icon: <PopperOneLogo width="130" height="130" />
}

export const popperSlideFilters = {
  description: 'Adjust the sliders to set the intensity of your desired effects.',
  buttonTitle: 'Get Started',
  icon: <PopperTwoLogo width="130" height="130" />
}

export const popperRevealNavigationTabs = {
  description: 'Tap next for additional slider options.',
  buttonTitle: 'GOT IT!',
  icon: <PopperOneLogo width="130" height="130" />
}