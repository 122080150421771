import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import ProductsList from '../../../components/lists/productsList';
import BannerComponent from '../../../components/shopByFilters/banner';
import OverAllFilters from '../../../components/shopByFilters/selectedFilters';
import ShopByCategoryFilters from '../../../components/shopByFilters/shopByCategory';
import SortComponent from '../../../components/shopByFilters/sort';
import AgeVerification from '../../AgeVerification/AgeVerification';

// Consts
import { symptomsConsts } from '../../../consts/shopByConsts';

// Hooks
import useFetchProducts from '../../../hooks/useFetchProducts';

// Actions
import { fetchProductsLoadingAction, updateFiltersAction } from '../../../store/actions/shopByActions';

// Selectors
import { selectSelectedFilters } from '../../../store/selectors/shopBySelectors';

const ShopBySymptoms = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  //Selectors
  const selectedFilters = useSelector(selectSelectedFilters);
  const userVerfificationState = useSelector(state => state.user.userVerified);

  // State
  const initialSelectedId = selectedFilters.Wellness ? getIdByTitle(selectedFilters.Wellness) : 1;
  const [selectedId, setSelectedId] = useState(initialSelectedId);

  // Hooks
  const { loading, products } = useFetchProducts();

  // Handlers
  function getTitleById(selectedId) {
    const category = symptomsConsts.categories.find((c) => c.id === selectedId);
    return category ? category.title : 'Category not found';
  }

  function getIdByTitle(title) {
    const category = symptomsConsts.categories.find((c) => c.title === title);
    return category ? category.id : 0;
  }

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  // UseEffect
  useEffect(() => {
    if (selectedId !== null) {
      const updatedFilters = {
        ...selectedFilters,
        page: 1,
        pageSize: 10,
        Wellness: getTitleById(selectedId),
        'Product Type': 'All',
      };
      dispatch(fetchProductsLoadingAction(true));
      dispatch(updateFiltersAction(updatedFilters));
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedFilters.Wellness) {
      setSelectedId(getIdByTitle(selectedFilters.Wellness));
    } else {
      setSelectedId(null);
    }
  }, [selectedFilters]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {
        !userVerfificationState && (
          <AgeVerification isProductDetail={true} />
        )
      }

      <Grid container justifyContent="center" alignItems={'center'}>
        <ShopByCategoryFilters selectedId={selectedId} setSelectedId={setSelectedId} filters={symptomsConsts} />
        <Grid item container display={'flex'} direction={'column'} sx={{ maxWidth: '1600px' }}>
          <OverAllFilters />
          <BannerComponent type={'Medicinal'} />
          <SortComponent />
          <ProductsList loading={loading} products={products} onClick={handleProductClick} />
        </Grid>
      </Grid>
    </>
  );
};

export default ShopBySymptoms;
