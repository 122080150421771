import { Box, Dialog, DialogContent, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyUserPhoneNumber } from '../../network/services/userVerificationService';
import { updateFiltersAction } from '../../store/actions/shopByActions';
import { selectSelectedFilters } from '../../store/selectors/shopBySelectors';

import { StyledButton, StyledTypography, StyledTypographyBody } from './styledComponent';

import { setPhoneDialogState, verifyUserAction } from '../../store/actions/userActions';
import { selectPhoneNumberDialogState, selectUserPhoneNumber } from '../../store/selectors/userSelectors';

const PhoneNumberDialog = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const userVerfificationState = useSelector((state) => state.user.userVerified);
  const filtersState = useSelector(selectSelectedFilters);
  const dialogStatus = useSelector(selectPhoneNumberDialogState);
  const previousNumber = useSelector(selectUserPhoneNumber);

  // Navigate
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);

  // Handlers
  const onClose = () => {
    setPhoneNumber(null);
    dispatch(setPhoneDialogState(false));
  };

  const handleShoppingNavigate = async () => {
    setDisabled(true);

    try {
      const response = await verifyUserPhoneNumber(phoneNumber);
      var userType = 'adult';
      var newFilters = {
        ...filtersState
      };

      if (response?.data?.user?.isMedicalUser) {
        // console.log("User is a Medical User");
        userType = 'medical';
      }

      newFilters = {
        ...newFilters,
        'Purchase Type': userType
      };
      dispatch(verifyUserAction(response?.data?.user, response?.data?.user?.phoneNumber, false, userType));
      dispatch(updateFiltersAction(newFilters));
    } catch (error) {
      // NotificationToaster({ message: error?.response?.data?.message, type: "error" });
      dispatch(verifyUserAction(null, phoneNumber, true, 'adult'));
      console.log(error);
    } finally {
      setDisabled(false);
      onClose();
    }
  };

  const formatPhoneNumber = (numericPhoneNumber) => {
    const regex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = numericPhoneNumber.match(regex);

    if (matches) {
      return `(${matches[1]}) ${matches[2]}-${matches[3]}`;
    }

    return numericPhoneNumber;
  };

  const onChangeHandler = (e) => {
    if (e.target.value.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    const inputNumeric = e.target.value.replace(/\D/g, '');
    const formattedPhoneNumber = formatPhoneNumber(inputNumeric);
    setPhoneNumber(formattedPhoneNumber);
  };

  useEffect(() => {});

  return (
    <>
      <Dialog open={dialogStatus}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <StyledTypography m={2}>Update Your Phone Number</StyledTypography>

            <StyledTypographyBody>Your previous phone number is {previousNumber}.</StyledTypographyBody>
            <StyledTypographyBody marginBottom={1}>Enter your new phone number below.</StyledTypographyBody>

            <TextField
              label="New Phone Number"
              placeholder="(___) ___ - ____"
              style={{ width: '90%', marginTop: '4%', marginBottom: '6%' }}
              onChange={onChangeHandler}
              value={phoneNumber}
              error={error}
              inputProps={{ maxLength: 10 }}
            />

            <Grid container spacing={1} justifyContent={'center'}>
              <Grid item xs={11}>
                <StyledButton onClick={handleShoppingNavigate} disabled={disabled}>
                  Update
                </StyledButton>
              </Grid>

              <Grid item xs={11}>
                <StyledButton isCancel onClick={onClose}>
                  Cancel
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PhoneNumberDialog;
