import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Grid, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/buttons/custombutton';
import { NavigationType } from '../../consts/app_enums';
import useSubmitOrder from '../../hooks/useSubmitOrder';
import AppLayout from '../../layouts/appLayout/appLayout';
import {
  selectCartType,
  selectCheckoutProducts,
  selectItemsCount,
  selectOrderTotal,
  selectUserId,
  selectedPaymentMethod
} from '../../store/checkout/selectors/checkoutSelectors';
import PaymentForm from './Components/PaymentForm';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import { selectUserDetails } from '../../store/selectors/userSelectors';

const calculateTax = (orderTotal, taxType) => {
  if (taxType === 'adult') {
    return 0.2 * orderTotal;
  } else {
    return 0.07 * orderTotal;
  }
};

const OrderSummaryCard = ({ onConfirm, isSubmitting, pickupTime }) => {
  const orderTotal = useSelector(selectOrderTotal);
  const paymentMethod = useSelector(selectedPaymentMethod);
  const taxType = useSelector(selectCartType);

  const taxCalc = calculateTax(orderTotal, taxType);

  var afterTaxTotal = orderTotal + taxCalc;

  if (paymentMethod === 'CREDIT CARD' || paymentMethod === 'DEBIT CARD') {
    afterTaxTotal = afterTaxTotal + 3.5;
  }

  return (
    <Box m={0}>
      <Paper style={{ padding: '1em 2em' }} sx={{ boxShadow: 3 }}>
        <Typography style={{ marginBottom: '1.2em' }} variant="h4" gutterBottom>
          Pickup
        </Typography>

        <Grid container style={{ marginBottom: '1em' }} spacing={1} alignItems="center">
          <Grid item>
            <AccessTimeIcon style={{ fontSize: '1.5em' }} />
          </Grid>

          <Grid item style={{ paddingTop: '1%' }}>
            <Typography fontWeight="700" fontFamily="Inter" fontSize="16px">
              Today: {pickupTime}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '1em' }} spacing={0} alignItems="center">
          <Grid item xs={1}>
            <LocationOnIcon style={{ fontSize: '1.5em' }} />
          </Grid>

          <Grid item xs={11} marginLeft="0" paddingLeft="0">
            <Typography fontWeight="700" fontFamily="Inter" fontSize="16px">
              Mother Earth Wellness Pawtucket, RI 02860
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '0.5em' }} spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">SubTotal</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">$ {orderTotal?.toFixed(2)}</Typography>
          </Grid>
        </Grid>

        {paymentMethod === 'CREDIT CARD' || paymentMethod === 'DEBIT CARD' ? (
          <Grid container style={{ marginBottom: '0.5em' }} spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">CREDIT/DEBIT card fee</Typography>
            </Grid>

            <Grid item>
              <Typography variant="h5">$3.50</Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid container style={{ marginBottom: '0.5em' }} spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Taxes {taxType === 'adult' ? '(20%)' : '(7%)'}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">${taxCalc.toFixed(2)}</Typography>
          </Grid>
        </Grid>

        {/* <Grid container style={{ marginBottom: '1em' }} spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Credit Card Fee</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">$ 0.99</Typography>
          </Grid>
        </Grid> */}

        <Divider sx={{ borderBottomWidth: 2 }} style={{ borderColor: '#000000' }} />

        <Grid
          container
          style={{ marginTop: '1.5em', marginBottom: '1.5em' }}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">Order Total</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h3">$ {afterTaxTotal.toFixed(2)}</Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
          <Grid item xs={12}>
            <CustomButton title={'COMPLETE ORDER'} onClick={onConfirm} loading={isSubmitting} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

const PaymentInfo = () => {
  const isMdOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const paymentFormRef = useRef();
  const { isSubmitting, submit } = useSubmitOrder();
  const [pickupTime, setPickupTime] = useState(null);
  const items = useSelector((state) => state.checkout.items);
  const userId = useSelector(selectUserId);
  const purchaseType = useSelector(selectCartType);

  const paymentMethod = useSelector(selectedPaymentMethod);
  const itemsTotal = useSelector(selectOrderTotal);
  const taxType = useSelector(selectCartType);
  const userInfo = useSelector(selectUserDetails);
  const newUserPhoneNumber = useSelector((state) => state.user.userPhoneNumber);

  const navigate = useNavigate();

  const itemsCount = useSelector(selectItemsCount);

  const handleCompleteReservation = async () => {
    const orderData = {
      userId,
      items: items.map((item) => ({ productId: item.id, quantity: item.quantity })),
      orderType: purchaseType
    };

    try {
      const formData = await paymentFormRef.current?.submitForm();
      // if (formData) {
      //   setReadyToSubmit(true);
      // }
    } catch (error) {
      console.error(error);
    }
    
    try {
      mixpanel.track('Checkout Completed', { ...orderData, paymentMethod, itemsTotal, taxType, userInfo, newUserPhoneNumber });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (itemsCount === 0) {
      navigate('/home');
    }
  });

  return (
    <AppLayout version={NavigationType.V3} title={'Reserve'}>
      <Paper
        elevation={0}
        sx={{
          minHeight: '100vh'
        }}
      >
        <Box display="block" m={1} style={{ float: 'left' }}>
          <IconButton
            color="inherit"
            style={{ outline: 'none', width: '100%' }}
            sx={{
              '&:hover': {
                backgroundColor: '#fff'
              }
            }}
            onClick={() => {
              navigate('/checkout');
            }}
          >
            <ArrowBackIcon />
            <Typography style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '12px', marginLeft: '4px' }}>Back to cart</Typography>
          </IconButton>
        </Box>

        <Box sx={isMdOrLarger ? { maxWidth: '70%', margin: 'auto', mt: 3 } : { p: 1, mt: 2 }}>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} md={7} style={{ marginTop: '2%' }}>
              <PaymentForm ref={paymentFormRef} pickupTime={pickupTime} setPickupTime={setPickupTime} />
            </Grid>

            <Grid item xs={12} md={5}>
              <OrderSummaryCard onConfirm={handleCompleteReservation} isSubmitting={isSubmitting} pickupTime={pickupTime} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </AppLayout>
  );
};

export default PaymentInfo;
