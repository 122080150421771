// App routes
const appRoutes = {
  home: '/home',
  shopByActivity: '/shop/activity',
  shopByMood: '/shop/mood',
  shopBySymptoms: '/shop/symptoms',
  shopByPerfectMatch: '/shop/perfectMatch',
  shopByProductType: '/shop/productType',
  product: '/product/:id',
  productReport: '/product/report/:id',
  productMiniReport: '/product/miniReport/:id',
  checkout: '/checkout',
  payment: '/payment',
  confirmation: '/confirmation',
  search: '/search',
  ageVerification: '/'
};

export { appRoutes };
