import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ProductLayout from '../../../../layouts/ProductLayout/ProductLayout';
import { OptionsQTYContainer } from '../StyledComponents/StylesComponents';
import ProductReportLayout from '../../../../layouts/ProductReportLayout/ProductReportLayout';

const ThumbnailShimmer = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} sx={{ order: { xs: -1, md: 0 } }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" height={36} />
        <Skeleton variant="text" width="50%" />
      </Grid>
      <Grid item xs={12} md={4} sx={{ order: { xs: 0, md: -1 } }}>
        <Skeleton variant="rectangular" width="100%" height={100} />
      </Grid>
    </React.Fragment>
  );
};

const ProductExperienceShimmer = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width="30%" height={40} />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rectangular" width="80%" height={200} />
      </Grid>

      <Grid item xs={12} md={6}>
        <Box mt={{ xs: 0, md: 5 }}>
          <Skeleton variant="rectangular" width="100%" height={200} />
          <Skeleton variant="rectangular" width="100%" height={80} />
        </Box>
      </Grid>
    </React.Fragment>
  );
};

const ProductOptionsQTYShimmer = () => {
  return (
    <OptionsQTYContainer sx={{ ml: { xs: 0, md: 10 } }}>
      <Skeleton variant="text" width="30%" height={60} />
      <Skeleton variant="text" width="40%" height={30} />
      <Skeleton variant="rectangular" width="100%" height={56} style={{ borderRadius: 8, marginBottom: 16 }} />

      <Skeleton variant="rectangular" width="100%" height={56} style={{ borderRadius: 8 }} />
      <Skeleton variant="text" width="80%" height={30} />
    </OptionsQTYContainer>
  );
};

const ShimmerBlock = ({ height }) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Skeleton variant="text" width="80%" height={height} />
    <Skeleton variant="text" width="60%" height={height} />
    <Skeleton variant="text" width="40%" height={height} />
  </Box>
);

const ProductCompositionShimmer = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <Skeleton variant="text" width="50%" height={48} />
        <ShimmerBlock height={20} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mt={{ xs: 0, md: 5 }}>
          <ShimmerBlock height={20} />
        </Box>
      </Grid>
    </React.Fragment>
  );
};

const ShimmerEffect = ({ isReport = false }) => {
  return (
    <>
      {isReport ? (
        <ProductReportLayout
          thumbnail={<ThumbnailShimmer />}
          experience={<ProductExperienceShimmer />}
          composition={<ProductCompositionShimmer />}
        />
      ) : (
        <ProductLayout
          thumbnail={<ThumbnailShimmer />}
          experience={<ProductExperienceShimmer />}
          optionsQTY={<ProductOptionsQTYShimmer />}
          composition={<ProductCompositionShimmer />}
        />
      )}
    </>
  );
};

export default ShimmerEffect;
