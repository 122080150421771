import { ReactComponent as Logo2 } from '../../../assets/icons/navigationBar/logo_2.svg';

import { AppBar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getMoodColors } from '../../../config/consts';

const StyledAppBar = styled(AppBar)(({ chemoName }) => ({
  backgroundColor: getMoodColors(chemoName).color,
  elevation: 0,
  position: 'static'
}));

const StyledLogoContainer = styled(Box)(({ version }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1
}));

const StyledLogo2 = styled(({ version, ...otherProps }) => {
  return <Logo2 {...otherProps} />;
})(({ theme, version }) => ({}));

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export { StyledAppBar, StyledLogoContainer, StyledLogo2, LogoContainer };
