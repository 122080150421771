import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomLinearProgress } from '../StyledComponents/StylesComponents';

const MoodBar = ({ label, value, maxValue, minLabel, maxLabel, isReport, isMiniReport, chemoName }) => {
  const normalizedValue = (value / maxValue) * 100;

  return (
    <Box>
      <Typography
        variant="subtitle1"
        fontFamily="Inter"
        fontSize={isMiniReport ? '8px' : isReport ? '12px' : '16px'}
        fontWeight={isMiniReport ? '600' : '700'}
        sx={{ width: '100px', flexShrink: 0, mb: isMiniReport ? '0px' : isReport ? '1px' : '10px' }}
      >
        {label}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box width="100%">
          <CustomLinearProgress variant="determinate" value={normalizedValue} isReport={isReport} isMiniReport={isMiniReport} chemoname={chemoName} />
        </Box>
      </Box>
      <Box display="flex" justifyContent={'space-between'} mb={isMiniReport ? '1px' : isReport ? '5px' : '10px'}>
        <Typography color="#000" variant="caption" fontFamily="Inter" fontWeight={isMiniReport ? isReport ? '500' : '500' : '300'} fontSize={isMiniReport ? '5px' : isReport ? '7px' : '12px'}>
          {minLabel}
        </Typography>
        <Typography color="#000" variant="caption" fontFamily="Inter" fontWeight={isMiniReport ? isReport ? '500' : '500' : '300'} fontSize={isMiniReport ? '5px' : isReport ? '7px' : '12px'}>
          {maxLabel}
        </Typography>
      </Box>
    </Box>
  );
};

const ProductMoods = ({ chemoName, data, isReport = false, isMiniReport = false }) => {
  return (
    <Box width={isMiniReport ? "auto" : "100%"} height={isMiniReport ? "106px" : "auto"}>
      <MoodBar
        label="Bliss"
        value={data['bliss'] || 0}
        maxValue={100}
        minLabel="Uplifted"
        maxLabel="Euphoric"
        isReport={isReport}
        isMiniReport={isMiniReport}
        chemoName={chemoName}
      />
      <MoodBar
        label="Energy"
        value={data['energy'] || 0}
        maxValue={100}
        minLabel="Sleepy"
        maxLabel="Energetic"
        isReport={isReport}
        isMiniReport={isMiniReport}
        chemoName={chemoName}
      />
      <MoodBar
        label="Focus"
        value={data['focus'] || 0}
        maxValue={100}
        minLabel="Aware"
        maxLabel="Zoned In"
        isReport={isReport}
        isMiniReport={isMiniReport}
        chemoName={chemoName}
      />
      <MoodBar
        label="Relaxation"
        value={data['relaxation'] || 0}
        maxValue={100}
        minLabel="Loose"
        maxLabel="Couch-Locked"
        isReport={isReport}
        isMiniReport={isMiniReport}
        chemoName={chemoName}
      />
    </Box>
  );
};

export default ProductMoods;
