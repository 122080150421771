import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { DescriptionText } from '../StyledComponents/StylesComponents';
import CannabinoidsGraph from './CannabinoidsGraph';
import { SemiCircularGaugeNew } from './ProgressMeter';

const Cannabinoids = ({ cannabinoids, isReport, isMiniReport = false, chemoName }) => {
  return (
    <Box>
      {isReport ? (
        <Grid container alignItems="center" justifyContent={'space-between'} spacing={0} pr={0} mt={-2}>
          <Grid item>
            <Typography variant="h4" fontSize={'16px'} fontFamily={"Inter"} fontWeight={"700"}>
              Total
            </Typography>
            <Typography variant="h4" fontSize={'16px'} fontFamily={"Inter"} fontWeight={"700"}>
              Cannabinoids
            </Typography>
            <Typography variant="subtitle1" fontSize={'10px'} fontFamily={"Inter"} fontWeight={"500"}>
              % by weight
            </Typography>
          </Grid>
          <Grid item>
            {/* <SemiCircularGaugeReport value={cannabinoids.total} size={180} subLabel="THC: 22.8%" chemoName={chemoName} /> */}
            <SemiCircularGaugeNew value={parseFloat(cannabinoids?.total)?.toFixed(1)} valueZ={parseFloat(cannabinoids?.totalZ)?.toFixed(1)} label="% by weight" subLabel={cannabinoids.totalTHC} chemoName={chemoName} type={'Cannaboids'} width={'220px'} height={'120px'} isReport={true} />
          </Grid>
        </Grid>
      ) : (
        isMiniReport ?
          (
            <>
              <Grid container alignItems="center" justifyContent={'space-between'} spacing={0} pr={0} >
                <Grid item xs={8}>
                  <Typography fontFamily='Inter' fontSize='8px' fontWeight={600} noWrap style={{ overflow: 'visible', }}>
                    Total Cannabinoids
                  </Typography>
                  <Typography fontFamily='Space Grotesk' fontSize='12px' fontWeight={700} style={{ marginTop: '-5px' }}>
                    {parseFloat(cannabinoids?.total)?.toFixed(1)}*
                  </Typography>
                  <Typography fontFamily='Inter' fontSize='7px' fontWeight={500} style={{ marginTop: '-4px' }}>
                    {cannabinoids.totalTHC}*
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{ position: 'absolute', marginTop: '10px', marginLeft: '65px' }}>
                  {/* <SemiCircularGaugeMiniReport value={cannabinoids.total} size={50} label="" subLabel="" chemoName={chemoName} /> */}
                  <SemiCircularGaugeNew value={parseFloat(cannabinoids?.total)?.toFixed(1)} valueZ={parseFloat(cannabinoids?.totalZ)?.toFixed(1)} label="% by weight" subLabel={cannabinoids.totalTHC} chemoName={chemoName} type={'Cannaboids'} width={'37px'} height={'20px'} isMiniReport={true} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography fontFamily={"Space Grotesk"} fontSize={"22px"} fontWeight={700} variant="h4" mb={2}>
                Total Cannabinoids
              </Typography>
              <DescriptionText mb={2} fontFamily={"Inter"} fontSize={"14px"} fontWeight={500}>
                Cannabinoids are the main molecules in cannabis that affect a variety of mind and body functions.
              </DescriptionText>
              <SemiCircularGaugeNew value={parseFloat(cannabinoids?.total)?.toFixed(1)} valueZ={parseFloat(cannabinoids?.totalZ)?.toFixed(1)} label="% by weight" subLabel={cannabinoids.totalTHC} chemoName={chemoName} type={'Cannaboids'} width={'310px'} height={'160px'} />
            </>
          )
      )}

      {/* Graph (Displayed if not mini report.) */}
      {
        isMiniReport ? (
          <></>
        ) : (
          <CannabinoidsGraph cannabinoidData={cannabinoids} chemoName={chemoName} isReport={isReport} />
        )
      }
    </Box>
  );
};

export default Cannabinoids;
