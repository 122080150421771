import { Accordion, AccordionDetails, Box, Drawer, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Components
import CustomButton from '../buttons/custombutton';
import FiltersDrawerOptions from './filtersDrawerOptions';

// Hooks
import { useFetchProductFilters } from '../../hooks/useFetchProductFilters';
import { useFilterSelection } from '../../hooks/useFilterSelection';

// Styled Components
import {
  StyledAccordionSummary,
  StyledActiveFiltersTypography,
  StyledChip,
  StyledClearAllTypography,
  StyledDrawerBox,
  StyledFiltersBox,
  StyledFiltersBoxParent,
  StyledTypography
} from './styledComponents';

import { buttonIcons } from '../../consts/filtersConsts';

const FiltersDrawer = ({ open, onClose }) => {
  // Hooks
  const { filterCategories, isLoading } = useFetchProductFilters();

  const {
    selectedFilters,
    expanded,
    handleAccordionChange,
    handleSliderChange,
    handleMultipleSliderChange,
    handleIconSelect,
    handleRadioChange,
    handleCheckboxChange,
    handleDelete,
    handleClearAll,
    handleRevealProducts
  } = useFilterSelection();

  // Consts
  const SwiperSettings = {
    slidesPerView: 1.5
  };

  const filteredFilters = Object.entries(selectedFilters).filter(
    ([category, _]) =>
      category !== 'page' &&
      category !== 'pageSize' &&
      category !== 'search' &&
      category !== 'Purchase Type' &&
      category !== 'sort' &&
      category !== 'Product Type'
  );

  const hasFiltersToShow = filteredFilters.length > 0;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <StyledDrawerBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            width: '100%',
            borderBottom: '1px solid black',
            marginBottom: '20px'
          }}
        >
          <StyledTypography>FILTER</StyledTypography>
          <IconButton onClick={onClose}>{buttonIcons.close}</IconButton>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            flexGrow: 1
          }}
        >
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            filterCategories.map((category) => (
              <Accordion
                disableGutters
                key={category.title}
                expanded={expanded === category.title}
                onChange={handleAccordionChange(category.title)}
                sx={{ boxShadow: 'none' }}
              >
                <StyledAccordionSummary expandIcon={expanded === category.title ? buttonIcons.collapse : buttonIcons.expand}>
                  <StyledTypography>{category.title}</StyledTypography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <FiltersDrawerOptions
                    category={category}
                    selectedFilters={selectedFilters}
                    handleRadioChange={handleRadioChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handleIconSelect={handleIconSelect}
                    handleSliderChange={handleSliderChange}
                    handleMultipleSliderChange={handleMultipleSliderChange}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Box>
        <StyledFiltersBoxParent>
          <StyledActiveFiltersTypography>{`ACTIVE FILTERS (${filteredFilters?.length})`}</StyledActiveFiltersTypography>
          <StyledFiltersBox>
            {hasFiltersToShow ? (
              <Swiper {...SwiperSettings}>
                {filteredFilters.map(([category, value], index) => (
                  <SwiperSlide key={index}>
                    <StyledChip
                      sx={{ marginLeft: 1 }}
                      label={`${category}: ${value}`}
                      onDelete={() => handleDelete(category)}
                      deleteIcon={<IconButton>{buttonIcons.close}</IconButton>} // Use your custom icon here
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 1.5, marginTop: 1 }}>
                <Typography>None Selected</Typography>
              </Box>
            )}
          </StyledFiltersBox>
          <Box display="flex" flexDirection="column" gap={1}>
            <CustomButton
              title={'SEE RESULTS'}
              onClick={() => {
                handleRevealProducts();
                onClose();
              }}
              sx={{ flexGrow: 1 }}
            />
            <StyledClearAllTypography
              variant="body2"
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                alignSelf: 'center'
              }}
              onClick={handleClearAll}
            >
              Clear All
            </StyledClearAllTypography>
          </Box>
        </StyledFiltersBoxParent>
      </StyledDrawerBox>
    </Drawer>
  );
};

export default FiltersDrawer;
