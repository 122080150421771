// project import
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import ThemeCustomization from './themes';
import './firebase';
import { useIdle } from 'react-use';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

// css
import './App.css';
import { useEffect } from 'react';

mixpanel.init('d4209382eab71b4b6adcf19ff21d82ab', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  cross_subdomain_cookie: false
});

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isIdle = useIdle(300000);

  console.log('Idle State: ', isIdle);

  useEffect(() => {
    if (isIdle && location.pathname !== '/') {
      dispatch({ type: 'RESET_USER_VERIFICATION' });
      try {
        if ('stop_session_recording' in mixpanel) {
          mixpanel.stop_session_recording();
        }
        if ('reset' in mixpanel) {
          mixpanel?.reset();
        }
      } catch (error) {
        console.log(error);
      }
      navigate('/');
    }
  }, [isIdle]);

  return (
    <ThemeCustomization>
      <Routes />
      <ToastContainer position={'top-right'} autoClose={3000} />
    </ThemeCustomization>
  );
};

export default App;
