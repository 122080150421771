import { Box, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as ReportAnaylzedByLogo } from '../../../assets/icons/navigationBar/report_analyzed_by.svg';

import { LogoContainer, StyledAppBar, StyledLogo2, StyledLogoContainer } from './styledComponents';

const ReportTopBar = ({ chemoName, productType }) => {
  return (
    <StyledAppBar chemoName={chemoName}>
      <Toolbar>
        <StyledLogoContainer>
          <StyledLogo2 />
        </StyledLogoContainer>
        <Box display={'flex'} alignItems={'center'}></Box>
        <Typography mr={1}>ANALYZED BY</Typography>
        <LogoContainer>
          <ReportAnaylzedByLogo />
        </LogoContainer>
        {/* <Typography mr={1} fontFamily={"Inter"} fontSize={'16px'} lineHeight={'20px'} fontWeight={600}>{productType}</Typography> */}
      </Toolbar>
    </StyledAppBar>
  );
};

export default ReportTopBar;
