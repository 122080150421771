import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StyledButton, StyledDialog, StyledDialogContent, StyledDialogTitle, StyledIconButton, StyledTypography } from './styledComponents';

import { isCancel } from 'axios';
import { addToCart, resetCartItems, setCartDialog, setCartType } from '../../store/checkout/actions/checkoutActions';
import { selectCartDialog, selectCartType } from '../../store/checkout/selectors/checkoutSelectors';
import { selectProductData } from '../../store/productDetails/selectors/productSelectors';
import { selectPurchaseTypeFilterState } from '../../store/selectors/shopBySelectors';

const CartDialog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dialogStatus = useSelector(selectCartDialog);
    const product = useSelector(selectProductData);
    const purchaseType = useSelector(selectPurchaseTypeFilterState);
    const cartType = useSelector(selectCartType) ?? 'adult';

    const handleClearAndSwitch = () => {
        dispatch(resetCartItems());
        dispatch(setCartType(purchaseType));
        dispatch(addToCart(product));
        navigate('/checkout');
        handleClose();
    }

    const handleClose = () => {
        dispatch(setCartDialog(false));
    }

    return (
        <StyledDialog open={dialogStatus} onClose={handleClose} fullWidth maxWidth={'xs'}>
            <StyledDialogTitle>
                Switch to {cartType === 'adult' ? 'medical' : 'adult'} purchase?
            </StyledDialogTitle>
            <StyledIconButton
                aria-label="close"
                onClick={handleClose}
            >
                <CloseIcon />
            </StyledIconButton>

            <StyledDialogContent>
                <StyledTypography>
                    Due to state regulations, recreational and medical items must be sold separately. If you&apos;d like to continue your cart will be cleared and your order will start fresh as a {cartType === 'adult' ? 'medical' : 'recreational'} order.
                </StyledTypography>

                <StyledButton style={{ marginTop: "15px" }} onClick={handleClearAndSwitch}>
                    CLEAR CART AND SWITCH
                </StyledButton>

                <StyledButton isCancel={isCancel} style={{ marginTop: "15px", marginBottom: "5px" }} onClick={handleClose}>
                    CANCEL
                </StyledButton>
            </StyledDialogContent>
        </StyledDialog>
    );
};

export default CartDialog;