//ShopBy

export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const FORCE_RESET_FILTERS = 'FORCE_RESET_FILTERS';

export const FETCH_PRODUCT_LOADING = 'FETCH_PRODUCT_LOADING';
export const FETCH_PRODUCT_SCROLLING = 'FETCH_PRODUCT_SCROLLING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCT_SCROLLING_SUCCESS = 'FETCH_PRODUCT_SCROLLING_SUCCESS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const UPDATE_TOTAL_PRODUCTS = 'UPDATE_TOTAL_PRODUCTS';

export const SET_USER_VERIFICATION = 'SET_USER_VERIFICATION';
export const SET_USER_VERIFICATION_INIT = 'SET_USER_VERIFICATION_INIT';
export const RESET_USER_VERIFICATION = 'RESET_USER_VERIFICATION';

export const SET_PHONE_DIALOG_STATE = 'SET_PHONE_DIALOG_STATE'