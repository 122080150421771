// reducers/productReducer.js
import {
  FETCH_PRODUCT_DATA_PENDING,
  FETCH_PRODUCT_DATA_SUCCESS,
  FETCH_PRODUCT_DATA_FAILURE,
  SET_PRODUCT_QUANTITY,
  SET_PRODUCT_WEIGHT
} from '../actions/productActions';

const initialState = {
  productData: null,
  loading: false,
  error: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: action.payload
      };

    case FETCH_PRODUCT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_PRODUCT_QUANTITY:
      if (state.productData && state.productData.quantitySelection) {
        return {
          ...state,
          productData: {
            ...state.productData,
            quantitySelection: {
              ...state.productData.quantitySelection,
              quantity: action.payload
            }
          }
        };
      }
      break;
    case SET_PRODUCT_WEIGHT:
      if (state.productData && state.productData.quantitySelection) {
        return {
          ...state,
          productData: {
            ...state.productData,
            quantitySelection: {
              ...state.productData.quantitySelection,
              weight: action.payload
            }
          }
        };
      }
      break;

    default:
      return state;
  }
};

export default productReducer;
