import { Checkbox } from '@mui/material';

// Consts
import { buttonIcons } from '../../consts/filtersConsts';

const CustomCheckbox = ({ checked, onChange, name }) => (
  <Checkbox
    icon={buttonIcons.checkboxUnchecked}
    checkedIcon={buttonIcons.checkboxChecked}
    checked={checked}
    onChange={onChange}
    name={name}
  />
);

export default CustomCheckbox;
