import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Consts
import { filterCategories as initialFilterCategories } from '../consts/filtersConsts';

// Actions
import { fetchFiltersAction } from '../store/actions/shopByActions';
import { selectFilters } from '../store/selectors/shopBySelectors';

export const useFetchProductFilters = () => {
  // Dispatch
  const dispatch = useDispatch();

  // States
  const [filterCategories, setFilterCategories] = useState(initialFilterCategories);
  const [isLoading, setIsLoading] = useState(false);

  // Selectors
  const filters = useSelector(selectFilters);

  useEffect(() => {
    const fetchFilters = async () => {
      setIsLoading(true);
      try {
        // Dispatch the action to fetch filters
        await dispatch(fetchFiltersAction());
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
      setIsLoading(false);
    };

    fetchFilters();
  }, [dispatch]);

  useEffect(() => {
    if (filters.length === 0) return;
    let newProductTypes = {};
    // Update the filterCategories with the fetched data
    const updatedFilterCategories = initialFilterCategories.map((category) => {
      switch (category.title) {
        case 'Brand':
          return { ...category, options: filters.brands };
        case 'Product Type':
          newProductTypes = filters['productTypes']
            .filter((type) => type !== '')
            .map((type) => {
              switch (type) {
                case 'Concentrate':
                  return 'Concentrates';
                case 'Edibles':
                  return 'Edibles & Beverage';
                case 'Vape':
                  return 'Vaporizors';
                default:
                  return type;
              }
            });
          return { ...category, options: newProductTypes };
        case 'Terpenes':
          return { ...category, options: filters.terpenes };
        case 'Cannabinoids':
          return { ...category, options: filters.cannabis };
        default:
          return category;
      }
    });

    setFilterCategories(updatedFilterCategories);
  }, [filters]);

  return { filterCategories, isLoading };
};
