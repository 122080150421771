import { Box, Typography } from '@mui/material';
import React from 'react';
import { ExeperienceCardBackground } from '../StyledComponents/StylesComponents';

const GetCardText = ({ type, isReport }) => {
  type = type.toUpperCase();
  if (type === 'SLEEP') {
    return (
      <Typography fontFamily={'Inter'} fontSize={isReport ? '14px' : '16px'} fontWeight={'700'} variant="subtitle1">
        Sedation & Bliss
      </Typography>
    );
  }
  if (type === 'INDULGE') {
    return (
      <Typography fontFamily={'Inter'} fontSize={isReport ? '14px' : '16px'} fontWeight={'700'} variant="subtitle1">
        Sensory Experiences
      </Typography>
    );
  }
  if (type === 'MOTIVATE') {
    return (
      <Typography fontFamily={'Inter'} fontSize={isReport ? '14px' : '16px'} fontWeight={'700'} variant="subtitle1">
        Energy & Focus
      </Typography>
    );
  }
  if (type === 'ELEVATE') {
    return (
      <Typography fontFamily={'Inter'} fontSize={isReport ? '14px' : '16px'} fontWeight={'700'} variant="subtitle1">
        Mood and Spirit
      </Typography>
    );
  }
  if (type === 'RELAX') {
    return (
      <Typography fontFamily={'Inter'} fontSize={isReport ? '14px' : '16px'} fontWeight={'700'} variant="subtitle1">
        Mind & Body
      </Typography>
    );
  }
  if (type === 'RECOVER') {
    return (
      <Typography fontFamily={'Inter'} fontSize={isReport ? '14px' : '16px'} fontWeight={'700'} variant="subtitle1">
        Rejuvenation & Relief
      </Typography>
    );
  }
};

const ExperienceCard = ({ data, isReport = false }) => {
  return (
    <ExeperienceCardBackground isreport={isReport} chemoname={data.category}>
      {!isReport && <Box sx={{ marginRight: 2 }}>{data.picture}</Box>}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: isReport ? 'center' : 'left' }}>
        <Typography
          textAlign={isReport ? 'center' : 'left'}
          fontFamily={'Inter'}
          fontSize={'12px'}
          fontWeight={'500'}
          variant="caption"
          display="block"
          mb={isReport ? 1 : 0}
          mt={isReport ? -0.5 : 0}
        >
          Typical Experience
        </Typography>
        {isReport && (
          <Box
            sx={{
              display: isReport ? 'flex' : undefined,
              justifyContent: isReport ? 'center' : undefined,
              marginRight: isReport ? 0 : 2,
              marginBottom: isReport ? 1 : 0
            }}
          >
            {data.picture}
          </Box>
        )}

        <Typography
          textAlign={isReport ? 'center' : 'left'}
          fontFamily={'Space Grotesk'}
          fontSize={isReport ? '24px' : '28px'}
          fontWeight={'500'}
          variant="h2"
          component="div"
          mb={isReport ? 0 : 0}
        >
          {data.category.slice(0, 1) + data.category.slice(1).toLowerCase()}
        </Typography>

        <GetCardText type={data.category} isReport={isReport} />
      </Box>
    </ExeperienceCardBackground>
  );
};

export default ExperienceCard;
