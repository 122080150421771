import React, { useEffect } from 'react';
// Mui
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BadgeIcon from '@mui/icons-material/Badge';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavigationType } from '../../consts/app_enums';
import AppLayout from '../../layouts/appLayout/appLayout';
import { resetCartItems, resetCompletedOrder, setPaymentDetails } from '../../store/checkout/actions/checkoutActions';
import { selectCompletedOrder, selectPaymentDetails, selectedPaymentMethod } from '../../store/checkout/selectors/checkoutSelectors';

const OrderConfirmation = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paymentDetails = useSelector(selectPaymentDetails);
  // const orderTotal = useSelector(selectOrderTotal);
  // const numProducts = useSelector(selectCheckoutProducts)?.length;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const paymentMethod = useSelector(selectedPaymentMethod);
  const completeOrder = useSelector(selectCompletedOrder);

  // const taxCalc = 0.07 * completeOrder.totalAmount;

  var afterTaxTotal = completeOrder.totalAmount;

  // if (completeOrder.paymentMethod === 'CREDIT CARD' || completeOrder.paymentMethod === 'DEBIT CARD') {
  //   afterTaxTotal = afterTaxTotal + 3.5;
  // }

  // UseEffects
  useEffect(() => {
    dispatch(setPaymentDetails({
      birthday: "",
      emailAddress: "",
      expirationDate: "",
      firstName: "",
      lastName: "",
      medicalId: "",
      paymentMethod: "",
      phoneNumber: "",
      pickupTime: "",
      registrationDate: ""
    }));

    dispatch(resetCartItems());

    const logoutTimerId = setTimeout(() => {
      dispatch({ type: 'RESET_USER_VERIFICATION' });
      navigate('/');
    }, 10000);

    return () => {
      dispatch(resetCompletedOrder());
      clearTimeout(logoutTimerId);
    };
  }, []);

  return (
    <AppLayout version={NavigationType.V3} title={'Confirmed'}>
      <Box sx={{ margin: 'auto', p: isMobile ? 2 : 4, maxWidth: isMobile ? '100%' : '80%', height: isMobile ? 'auto' : '85vh' }}>
        <Grid style={{ paddingTop: '8em' }} container spacing={1} direction="row">
          <Grid container item xs={12} md={7}>
            <Grid item xs={12}>
              <Typography fontSize="36px" fontFamily="Space Grotesk" fontWeight="700" lineHeight="42px">We&apos;ve got your Order!</Typography>

              <Typography style={{ marginTop: '0.4em' }} fontSize="18px" fontFamily="Inter" fontWeight="500" lineHeight="24px">
                We&apos;re busy gathering items for your pickup.
              </Typography>

              <Typography style={{ marginTop: '0.4em' }} fontSize="12px" fontFamily="Inter" fontWeight="500" lineHeight="24px">
                Order Number: {completeOrder.orderNumber}
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ marginTop: '4em' }}>
              <Typography fontSize="22px" fontFamily="Space Grotesk" fontWeight="700" lineHeight="36px">
                Pickup your order
              </Typography>
            </Grid>

            <Grid container item style={{ marginTop: '0.6em' }} spacing={1} alignItems="center">
              <Grid item>
                <AccessTimeIcon style={{ fontSize: '1.5em' }} />
              </Grid>

              <Grid item style={{ paddingTop: '1%' }}>
                <Typography display="inline" style={{ fontSize: '15px', fontFamily: 'Inter' }}>Today:&nbsp;</Typography>
                <Typography display="inline" style={{ fontSize: '15px', fontFamily: 'Inter', fontWeight: '600' }}>{completeOrder.pickUpTimeFrame}</Typography>
              </Grid>
            </Grid>

            <Grid container item style={{ marginTop: '0.6em' }} spacing={1} alignItems="center">
              <Grid item>
                <ShoppingBagIcon style={{ fontSize: '1.5em' }} />
              </Grid>

              <Grid item style={{ paddingTop: '1%' }}>
                <Typography fontSize="15px" fontFamily="Inter" fontWeight="400" lineHeight="19px">
                  {completeOrder?.items?.length} items
                </Typography>
              </Grid>
            </Grid>

            <Grid container item style={{ marginTop: '0.6em' }} spacing={1} alignItems="center">
              <Grid item>
                <BadgeIcon style={{ fontSize: '1.5em' }} />
              </Grid>

              <Grid item>
                <Typography display="inline" fontSize="15px" fontFamily="Inter" fontWeight="400" lineHeight="19px">
                  You must have your&nbsp;
                </Typography>

                <Typography display="inline" fontSize="15px" fontFamily="Inter" fontWeight="bold" lineHeight="19px">
                  photo ID&nbsp;
                </Typography>

                <Typography display="inline" fontSize="15px" fontFamily="Inter" fontWeight="400" lineHeight="19px">
                  at the time of pickup.
                </Typography>
              </Grid>
            </Grid>

            <Grid container item style={{ marginTop: '0.6em' }} spacing={1} alignItems="center">
              <Grid item>
                <MedicalServicesIcon style={{ fontSize: '1.5em' }} />
              </Grid>

              <Grid item>
                <Typography fontSize="15px" fontFamily="Inter" fontWeight="400" lineHeight="19px">
                  Medical customers must present their
                </Typography>

                <Typography fontSize="15px" fontFamily="Inter" fontWeight="bold" lineHeight="19px">
                  medical identification card.
                </Typography>
              </Grid>
            </Grid>

            <Grid container item style={{ marginTop: '0.6em' }} spacing={1} alignItems="center">
              <Grid item>
                <MedicalServicesIcon style={{ fontSize: '1.5em' }} />
              </Grid>

              <Grid item>
                <Typography fontSize="15px" fontFamily="Inter" fontWeight="400" lineHeight="19px">
                  Mother Earth Wellness, Pawtucket, RI 02860
                </Typography>
              </Grid>
            </Grid>

            <Grid container item style={{ marginTop: '0.6em' }} spacing={1} alignItems="center">
              <Grid item>
                <Typography fontSize="14px" fontFamily="Inter" fontWeight="700" lineHeight="20px">
                  DIRECTIONS
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '1.5em' }} xs={12}>
              <Grid item xs={9}>
                <Divider style={{ borderColor: "#000000", }} sx={{ borderBottomWidth: 5 }} />
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '1.5em' }} spacing={1} alignItems="end">
              <Grid item>
                <Typography fontSize="22px" fontFamily="Space Grotesk" fontWeight="700" lineHeight="26px">Order Total&nbsp;</Typography>
              </Grid>

              <Grid item>
                <Typography fontFamily="Space Grotesk" fontWeight="500" variant="h2">${afterTaxTotal ? afterTaxTotal.toFixed(2) : 0}</Typography>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '0em', marginBottom: '1.5em' }} spacing={1} alignItems="center">
              <Grid item>
                <Typography fontSize="15px" fontFamily="Inter" fontWeight="400" lineHeight="19px">You will be asked for payment when you pickup your order.</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            {/* <img
            src="https://maps.googleapis.com/maps/api/staticmap?center=Your+Location&zoom=15&size=400x400&key=YOUR_GOOGLE_MAPS_API_KEY"
            alt="Google Map"
          /> */}
            <iframe title='Google Maps' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11885.339475739287!2d-71.4026794!3d41.8641412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e445a154fd7947%3A0xfdf9335e88763830!2sMother%20Earth%20Wellness!5e0!3m2!1sen!2s!4v1705669624698!5m2!1sen!2s" width={isMobile ? '400px' : '600px'} height={isMobile ? '350px' : '450px'} style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
};

export default OrderConfirmation;
