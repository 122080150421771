import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Logo1V2 } from '../../../assets/icons/navigationBar/logo1_v2.svg';
import { ReactComponent as Logo2V2 } from '../../../assets/icons/navigationBar/logo2_v2.svg';
import { ReactComponent as Logo1 } from '../../../assets/icons/navigationBar/logo_1.svg';
import { ReactComponent as Logo2 } from '../../../assets/icons/navigationBar/logo_2.svg';
import { ReactComponent as MiniLogoBlack } from '../../../assets/icons/navigationBar/mini-logo-black.svg';
import { ReactComponent as MiniLogoWhite } from '../../../assets/icons/navigationBar/mini-logo-white.svg';

import { AppBar, Box, Divider, InputAdornment, Button as MuiButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NavigationType } from '../../../consts/app_enums';

const NavigationLink = styled(MuiButton)(({ theme, version }) => ({
  color: version === NavigationType.V1 ? '#FFFF' : '#000',
  display: version === NavigationType.V3 ? 'none' : 'inline-block',
  cursor: 'pointer'
}));

const StyledAppBar = styled(AppBar)(({ version }) => ({
  backgroundColor: version == NavigationType.V1 ? 'black' : '#F1EEEB',
  elevation: 0,
  position: 'static'
}));

const StyledSearchIcon = styled(SearchIcon)(({ version }) => ({
  color: version == NavigationType.V1 ? '#FFF' : '#000000'
}));

const StyledClearIcon = styled(CloseIcon)(({ version }) => ({
  color: version == NavigationType.V1 ? '#FFF' : '#000000'
}));

const StyledInputAdornment = styled(InputAdornment)(({ version }) => ({}));

const StyledLogoContainer = styled(Box)(({ version }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}));

const SearchTextField = styled(TextField)(({ theme, version }) => ({
  display: version === NavigationType.V3 ? 'none' : 'flex',
  '& .MuiInputBase-input': {
    color: version === NavigationType.V1 ? 'white' : 'black'
  },
  '& .MuiInputBase-input::placeholder': {
    color: version === NavigationType.V1 ? '#FFFFFF' : '#000000',
    opacity: 0.7
  },
  '& .MuiInputBase-adornedStart': {
    backgroundColor: version === NavigationType.V1 ? 'black' : 'white'
  },
  '& .MuiInputAdornment-positionStart': {
    backgroundColor: version === NavigationType.V1 ? 'black' : 'white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  },
  margin: `${theme.spacing(0)} ${theme.spacing(5)}`,
  flex: 1,
  [theme.breakpoints.down('md')]: {
    '& .MuiInputBase-input': {
      padding: "4px 4px",
    }
  }
}));

const StyledIconButton = styled(IconButton)(({ version }) => ({
  color: 'inherit'
}));

const StyledMenuIcon = styled(MenuIcon)(({ version }) => ({
  color: version === NavigationType.V1 ? '#FFFF' : '#000'
}));

const StyledShoppingCartIcon = styled(ShoppingCartIcon)(({ version }) => ({
  display: version === NavigationType.V3 ? 'none' : 'block',
  color: version === NavigationType.V1 ? '#FFF' : '#000'
}));

const StyledBadge = styled(Badge)(({ version }) => ({
  '& .MuiBadge-badge': {
    right: -6,
    top: 13,
    border: `2px solid ${version === NavigationType.V1 ? '#FFF' : '#000'}`,
    height: '22px',
    width: '22px',
    padding: '4px',
    backgroundColor: version === NavigationType.V1 ? '#000' : '#FFF',
    color: version === NavigationType.V1 ? '#FFF' : '#000'
  }
}));

const StyledLogo1 = styled(({ version, isMobile, ...otherProps }) => {
  if (version === NavigationType.V1) {
    return isMobile ? <MiniLogoWhite {...otherProps} /> : <Logo1 {...otherProps} />;
  } else {
    return isMobile ? <MiniLogoBlack /> : <Logo1V2 {...otherProps} />;
  }
})(({ theme, version }) => ({}));

const StyledLogo2 = styled(({ version, isMobile, ...otherProps }) => {
  if (version === NavigationType.V1) {
    return <Logo2 {...otherProps} />;
  } else {
    return <Logo2V2 {...otherProps} />;
  }
})(({ theme, version }) => ({}));

const NavTitle = styled(Typography)(({ version }) => ({
  color: '#000',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '26px',
  marginLeft: '14px',
  display: version === NavigationType.V3 ? 'block' : 'none'
}));

const StyledDivider = styled(Divider)(({ version }) => ({
  backgroundColor: version === NavigationType.V1 ? '#D9D9D9' : 'black'
}));

export {
  NavTitle,
  NavigationLink,
  SearchTextField,
  StyledAppBar,
  StyledBadge,
  StyledClearIcon,
  StyledDivider,
  StyledIconButton,
  StyledInputAdornment,
  StyledLogo1,
  StyledLogo2,
  StyledLogoContainer,
  StyledMenuIcon,
  StyledSearchIcon,
  StyledShoppingCartIcon
};
