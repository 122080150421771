import React from 'react';
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';

export default function ProductLayout({ thumbnail, experience, optionsQTY, composition, recommended }) {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper
      elevation={0}
      sx={{
        padding: { xs: 2, md: 6 },
        margin: 0,
        minHeight: '100vh'
      }}
    >
      <Grid container spacing={2}>
        <Grid item container xs={12} md={8} spacing={2}>
          {thumbnail}
          {matchesMd && optionsQTY}
          {experience}
          {composition}
        </Grid>
        {!matchesMd && (
          <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
            {optionsQTY}
          </Grid>
        )}
      </Grid>

      <Grid container spacing={0}>
        {recommended}
      </Grid>
    </Paper>
  );
}
