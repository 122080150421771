import { Typography, useMediaQuery, useTheme } from '@mui/material';
import elevateLogo from '../assets/icons/banner/mood/elevate.png';
import indulgeLogo from '../assets/icons/banner/mood/indulge.png';
import motivateLogo from '../assets/icons/banner/mood/motivate.png';
import recoverLogo from '../assets/icons/banner/mood/recover.png';
import relaxLogo from '../assets/icons/banner/mood/relax.png';
import sleepLogo from '../assets/icons/banner/mood/sleep.png';

import creativityLogo from '../assets/icons/banner/activity/creativity.png';
import entertainmentLogo from '../assets/icons/banner/activity/Entertainment.png';
import exerciseLogo from '../assets/icons/banner/activity/exercise.png';
import diningLogo from '../assets/icons/banner/activity/Indulgence.png';
import intimacyLogo from '../assets/icons/banner/activity/Intimacy.png';
import mindfulnessLogo from '../assets/icons/banner/activity/mindfullness.png';
import mobilityLogo from '../assets/icons/banner/activity/mobility.png';
import outdoorsLogo from '../assets/icons/banner/activity/Outdoors.png';
import relaxationLogo from '../assets/icons/banner/activity/relax.png';
import sleepAcLogo from '../assets/icons/banner/activity/Sleep.png';
import socializingLogo from '../assets/icons/banner/activity/socializing.png';

import appetiteLogo from '../assets/icons/banner/medicinal/appetite.png';
import fatigueLogo from '../assets/icons/banner/medicinal/fatigue.png';
import focusLogo from '../assets/icons/banner/medicinal/focus.png';
import inflamationLogo from '../assets/icons/banner/medicinal/inflamation.png';
import moodMdLogo from '../assets/icons/banner/medicinal/mood.png';
import nauseaLogo from '../assets/icons/banner/medicinal/nausea.png';
import painLogo from '../assets/icons/banner/medicinal/pain.png';
import sleepMdLogo from '../assets/icons/banner/medicinal/sleep.png';
import stressLogo from '../assets/icons/banner/medicinal/stress.png';



import inflamationImage from '../assets/images/shopBy/symptoms/Inflamation.png';

import dopeImage from '../assets/icons/banner/chemicals/Dopamine.jpg';
import endoImage from '../assets/icons/banner/chemicals/Endorphins.jpg';
import gabaImage from '../assets/icons/banner/chemicals/Gaba.jpg';
import norImage from '../assets/icons/banner/chemicals/Noradrenaline.jpg';
import seroImage from '../assets/icons/banner/chemicals/Serotonin.jpg';

export const BannerConsts = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return {
        'Category': {
            elevate: {
                color: 'rgb(255, 241, 201)',
                logo: elevateLogo,
                title: 'ELEVATE',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products promote uplifted mood and feelings of gratification, mainly due to ingredients that increase <b>serotonin</b>, <b>noradrenaline</b>, and endorphin activity in the brain.</Typography>,
                imageOne: seroImage,
                imageTwo: norImage
            },
            motivate: {
                color: 'rgb(210, 234, 206)',
                logo: motivateLogo,
                title: 'MOTIVATE',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products increase energy, focus, and productivity due to ingredients that increase <b>dopamine</b>, <b>noradrenaline</b>, glutamate, and acetylcholine activity in the brain.</Typography>,
                imageOne: dopeImage,
                imageTwo: norImage
            },
            relax: {
                color: 'rgb(200, 219, 251)',
                logo: relaxLogo,
                title: 'RELAX',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products ease muscle tension and promote mental tranquility due to ingredients that increase the activity of <b>GABA</b> and <b>serotonin</b> and reduce norepinephrine activity in the nervous system.</Typography>,
                imageOne: gabaImage,
                imageTwo: seroImage
            },
            recover: {
                color: 'rgb(255, 203, 190)',
                logo: recoverLogo,
                title: 'RECOVER',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products have anti-inflammatory and pain-relieving effects due to ingredients that increase <b>endorphins</b> and <b>GABA</b> activity in the brain, while reducing immune inflammatory response in the body.</Typography>,
                imageOne: endoImage,
                imageTwo: gabaImage
            },
            sleep: {
                color: 'rgb(200, 219, 251)',
                logo: sleepLogo,
                title: 'SLEEP',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products ease muscle tension and promote mental tranquility due to ingredients that increase the activity of <b>GABA</b> and <b>serotonin</b> and reduce norepinephrine activity in the nervous system.</Typography>,
                imageOne: gabaImage,
                imageTwo: seroImage
            },
            indulge: {
                color: 'rgb(207, 193, 223)',
                logo: indulgeLogo,
                title: 'INDULGE',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products enhance sensory experiences and promote feelings of appreciation and euphoria, mainly due to ingredients that increase <b>endorphins</b>, <b>dopamine</b>, and noradrenaline activity in the brain.</Typography>,
                imageOne: endoImage,
                imageTwo: dopeImage
            }
        },
        'Activity': {
            creativity: {
                color: 'rgb(212, 215, 221)',
                logo: creativityLogo,
                title: 'CREATIVITY',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>dopamine</b> and <b>serotonin</b>. This promotes uplifted mood, enhanced focus, and a relaxed state of mind—all of which can enhance creative thinking.</Typography>,
                imageOne: dopeImage,
                imageTwo: seroImage
            },
            exercise: {
                color: 'rgb(212, 215, 221)',
                logo: exerciseLogo,
                title: 'EXERCISE',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>noradrenaline</b>, <b>dopamine</b>, and <b>serotonin</b>. This promotes a surge in energy, alertness, and focus—all of which can enhance exercise.</Typography>,
                imageOne: norImage,
                imageTwo: dopeImage
            },
            entertainment: {
                color: 'rgb(212, 215, 221)',
                logo: entertainmentLogo,
                title: 'ENTERTAINMENT',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>dopamine</b> and <b>GABA</b>. This promotes feelings of contentment, appreciation, comfort, and nostalgia—all of which can enhance enjoyment of passive entertainment.</Typography>,
                imageOne: dopeImage,
                imageTwo: gabaImage
            },
            dining: {
                color: 'rgb(212, 215, 221)',
                logo: diningLogo,
                title: 'DINING',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>dopamine</b> and <b>endorphins</b>. This enhances appetite and taste perception, and promotes feelings of appreciation and enjoyment, which can enhance dining experiences.</Typography>,
                imageOne: dopeImage,
                imageTwo: endoImage
            },
            intimacy: {
                color: 'rgb(212, 215, 221)',
                logo: intimacyLogo,
                title: 'INTIMACY',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>dopamine</b> and <b>endorphins</b>. This promotes feelings of passion, desire, pleasure, excitement and sensuality—all of which can enhance intimacy.</Typography>,
                imageOne: dopeImage,
                imageTwo: endoImage
            },
            mindfulness: {
                color: 'rgb(212, 215, 221)',
                logo: mindfulnessLogo,
                title: 'MINDFULNESS',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>serotonin</b> and <b>GABA</b>. This promotes a calm and tranquil state of mind, increases focus, and elevates mood—all of which can enhance mindfulness and meditation.</Typography>,
                imageOne: seroImage,
                imageTwo: gabaImage
            },
            mobility: {
                color: 'rgb(212, 215, 221)',
                logo: mobilityLogo,
                title: 'MOBILITY',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>endorphins</b> and <b>GABA</b>. This relieves muscle tension, promotes a sense of calm, increases focus, and elevates mood, which can enhance yoga and stretching.</Typography>,
                imageOne: endoImage,
                imageTwo: gabaImage
            },
            relaxation: {
                color: 'rgb(212, 215, 221)',
                logo: relaxationLogo,
                title: 'RELAXATION',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>GABA</b> and <b>endorphins</b>. This promotes a calm and tranquil state of mind, eases muscle tension, and elevates mood, which can enhance relaxation and relieve stress.</Typography>,
                imageOne: gabaImage,
                imageTwo: endoImage
            },
            sleep: {
                color: 'rgb(212, 215, 221)',
                logo: sleepAcLogo,
                title: 'SLEEP',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>GABA</b> and <b>serotonin</b>. This promotes feelings of mental tranquility, comfort, relaxation, and uplifted mood—all of which can contribute to restful sleep.</Typography>,
                imageOne: gabaImage,
                imageTwo: seroImage
            },
            socializing: {
                color: 'rgb(212, 215, 221)',
                logo: socializingLogo,
                title: 'SOCIALIZING',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>serotonin</b> and <b>endorphins</b>. This promotes feelings of happiness, trust, gratitude, and relaxation—all of which can enhance social behavior and bonding.</Typography>,
                imageOne: seroImage,
                imageTwo: endoImage
            },
            outdoors: {
                color: 'rgb(212, 215, 221)',
                logo: outdoorsLogo,
                title: 'OUTDOORS',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>serotonin</b> and <b>dopamine</b>. This promotes feelings of happiness, motivation, euphoria, and vitality—all of which can enhance enjoyment of outdoor sunlit experiences.</Typography>,
                imageOne: seroImage,
                imageTwo: dopeImage
            },
        },
        'Medicinal': {
            appetite: {
                color: 'rgb(212, 215, 221)',
                logo: appetiteLogo,
                title: 'APPETITE',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that can boost appetite and food cravings by increasing release of <b>ghrelin</b> (the hunger hormone) from the stomach and increasing <b>dopamine</b> activity in the brain.</Typography>,
                imageOne: dopeImage,
                imageTwo: ""
            },
            energy: {
                color: 'rgb(212, 215, 221)',
                logo: fatigueLogo,
                title: 'ENERGY',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that can boost energy levels and improve focus and attention, mainly through their ability to increase <b>noradrenaline</b> and <b>dopamine</b> activity in the brain.</Typography>,
                imageOne: norImage,
                imageTwo: dopeImage
            },
            focus: {
                color: 'rgb(212, 215, 221)',
                logo: focusLogo,
                title: 'FOCUS',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that have known anti-anxiety properties or are known to increase <b>GABA</b> activity, which promotes mental tranquility and eases muscle tension associated with stress.</Typography>,
                imageOne: norImage,
                imageTwo: dopeImage
            },
            // inflammation: {
            //     color: 'rgb(212, 215, 221)',
            //     logo: inflamationLogo,
            //     title: 'INFLAMMATION',
            //     description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that have anti-inflammatory properties and work to reduce the inflammatory response of the body’s immune system by interfering with release of <b>cytokines</b> by white blood cells.</Typography>,
            //     imageOne: inflamationImage
            // },
            mood: {
                color: 'rgb(212, 215, 221)',
                logo: moodMdLogo,
                title: 'MOOD',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that boost <b>serotonin</b> and <b>dopamine</b> levels in the brain, which promotes uplifted mood and feelings of contentment and gratification.</Typography>,
                imageOne: seroImage,
                imageTwo: dopeImage
            },
            nausea: {
                color: 'rgb(212, 215, 221)',
                logo: nauseaLogo,
                title: 'NAUSEA',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that have are effective in reducing symptoms of nausea based on early research.  This is believed to be due to their effects on the activity of <b>serotonin</b> and <b>dopamine</b> in the brain.</Typography>,
                imageOne: seroImage,
                imageTwo: dopeImage
            },
            discomfort: {
                color: 'rgb(212, 215, 221)',
                logo: painLogo,
                title: 'DISCOMFORT',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that can reduce the brain’s perception of pain intensity through direct interaction with the endocannabinoid system and pain pathways in the brain and spinal cord.</Typography>,
                imageOne: endoImage,
                imageTwo: gabaImage
            },
            rest: {
                color: 'rgb(212, 215, 221)',
                logo: sleepMdLogo,
                title: 'REST',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that are known to have sedative effects through their ability to increase <b>GABA</b> and <b>serotonin</b> activity in the brain.</Typography>,
                imageOne: gabaImage,
                imageTwo: seroImage
            },
            stress: {
                color: 'rgb(212, 215, 221)',
                logo: stressLogo,
                title: 'STRESS',
                description: <Typography fontWeight={500} fontSize={isMobile ? '16px' : '18px'}>These products contain ingredients that have known anti-anxiety properties or are known to increase <b>GABA</b> activity, which promotes mental tranquility and eases muscle tension associated with stress.</Typography>,
                imageOne: gabaImage,
                imageTwo: ""
            },
        }
    }
}