// import React from 'react';
// import { Provider } from 'react-redux';

// import configureStore from '../store';

// const store = configureStore();

// export default function StoreProvider({ children }) {
//   return <Provider store={store}>{children}</Provider>;
// }

// StoreProvider.js
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import configureStore from '../store';

const { store, persistor } = configureStore();

export default function StoreProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
