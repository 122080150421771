import { Grid, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import React from 'react';
import { DescriptionText } from '../StyledComponents/StylesComponents';
import CustomButton from '../../../../components/buttons/custombutton';
import { useNavigate } from 'react-router';

const renderCannabinoids = (data) => {
  return Object.entries(data)
    .map(([key, value]) => {
      if (key.toLowerCase() === 'totalthc') {
        return null;
      }

      const formattedKey = key.toUpperCase();
      const formattedValue = value == null || value.length == 0 ? 'Not Available' : `${value}`;

      return (
        <Typography key={key} variant="body1">
          {formattedKey}: <b>{formattedValue}</b>
        </Typography>
      );
    })
    .filter(Boolean);
};

const renderTerpenes = (data) => {
  return Object.entries(data).map(([key, value]) => {
    return (
      <Typography key={key} variant="body1">
        {value === "" || value === null ? "Not Available" : value}
      </Typography>
    );
  });
};

const ProductNotAnalyzed = ({ cannabinoidsNotAnalyzed, terpenesNotAnalyzed }) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <React.Fragment>
      <Grid item xs={12} md={4} mt={3} display={'flex'} flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>
        <Typography fontStyle={"Space Grotesk"} fontWeight={500} fontSize={"28px"} mb={0.5}>
          Total THC
        </Typography>
        <Typography fontStyle={"Space Grotesk"} fontWeight={700} fontSize={"38px"} mb={2}>
          {cannabinoidsNotAnalyzed.totalTHC}
        </Typography>
        <DescriptionText mb={2}>
          * THC is most responsible for the “high” and other therapeutic effects. Its potency is only one factor in the overall experience.
        </DescriptionText>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        mt={3}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'start'}
        alignItems={isMediumOrLarger ? 'center' : 'start'}
      >
        <Typography fontStyle={"Inter"} fontWeight={700} fontSize={"18px"} mb={2}>
          Cannabinoids
        </Typography>
        <DescriptionText mb={2}>{renderCannabinoids(cannabinoidsNotAnalyzed)}</DescriptionText>
      </Grid>

      <Grid item xs={12} md={4} mt={3} display={'flex'} flexDirection={'column'} justifyContent={'start'} alignItems={'flex-start'}>
        <Typography fontStyle={"Inter"} fontWeight={700} fontSize={"18px"} mb={2}>
          Dominant Terpenes
        </Typography>
        <DescriptionText mb={2}>{renderTerpenes(terpenesNotAnalyzed)}</DescriptionText>
      </Grid>

      <Grid item xs={12} mb={-10} mt={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              maxWidth: '70%',
              textAlign: 'center'
            }}
          >
            <Typography fontStyle={"Inter"} fontWeight={500} fontSize={"18px"} mb={2}>
              Hmmm. Not much to go on here. Want more information about product effects to guide your purchase? Try shopping PowerLeaf analyzed products instead.
            </Typography>

            <CustomButton title={'Shop The Powerleaf Way'} sx={{ width: '250px' }} onClick={() => { navigate('/shop/perfectMatch') }} />
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default ProductNotAnalyzed;
