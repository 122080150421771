import { Box, Typography } from '@mui/material';
import React from 'react';

export const SemiCircularGaugeNew = ({
  value,
  valueZ,
  label,
  subLabel,
  chemoName,
  type,
  width,
  height,
  isReport = true,
  isMiniReport = false
}) => {
  return (
    <>
      <Box
        style={isMiniReport ? { marginTop: 6 } : { paddingBottom: isReport ? '0rem' : '2.5rem' }}
        sx={{
          position: 'relative',
          userSelect: 'none',
          marginTop: isReport ? '0px' : isMiniReport ? '10px' : '20px',
          marginBottom: isMiniReport ? '5px' : isReport ? '16px' : '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div style={{ paddingBottom: isMiniReport ? '1rem' : isReport ? '3.5rem' : '4.5rem' }}>
          <PreciseSVGGauge percentage={parseFloat(value)} chemoName={chemoName} isMiniReport={isMiniReport} isReport={isReport} />
        </div>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            marginTop: isReport ? (type !== 'Terpenes' ? 3 : 0) : type !== 'Terpenes' ? 6 : 3.5,
            textAlign: 'center'
          }}
        >
          {!isReport && !isMiniReport && (
            <div
              style={{
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: '500'
              }}
            >
              {label}
            </div>
          )}

          {!isMiniReport && (
            <div
              style={{
                position: 'relative',
                fontSize: isReport ? '22px' : '38px',
                fontFamily: 'Space Grotesk',
                fontWeight: 700,
                top: -8
              }}
            >
              {type === 'Cannaboids' ? `${value}%` : `${value}%`}
            </div>
          )}

          {!isMiniReport && type !== 'Terpenes' && (
            <div
              style={{
                position: 'relative',
                fontSize: isReport ? '13px' : '16px',
                fontFamily: 'Inter',
                fontWeight: 700,
                top: -16
              }}
            >
              {subLabel}
            </div>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: isMiniReport ? '15px' : isReport ? '90px' : '150px',
            width: isMiniReport ? '40px' : isReport ? '200px' : '300px'
          }}
        >
          <Typography fontSize={isMiniReport ? '3px' : isReport ? '10px' : '12px'} fontFamily={'Inter'} fontWeight={500}>
            LOW
          </Typography>

          <Typography fontSize={isMiniReport ? '3px' : isReport ? '10px' : '12px'} fontFamily={'Inter'} fontWeight={500}>
            TYPICAL
          </Typography>

          <Typography fontSize={isMiniReport ? '3px' : isReport ? '10px' : '12px'} fontFamily={'Inter'} fontWeight={500}>
            HIGH
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const PreciseSVGGauge = ({ percentage = 0, chemoName = 'SLEEP', isMiniReport = false, isReport = false }) => {
  // Ensure percentage is between 0 and 100
  const clampedPercentage = Math.min(100, Math.max(0, percentage));

  // Get the color for the gauge
  const color = getColorName(chemoName);

  // SVG parameters
  const centerX = 154.5;
  const centerY = 150;
  const radius = 115;
  const strokeWidth = 20;

  // Calculate the angle for the gauge indicator (0 to 180 degrees)
  const angle = (clampedPercentage / 100) * 180 - 180 / 2;

  function adjustRadius(radius, percentage) {
    // If percentage is 50, return the original radius
    if (percentage === 50) return radius;

    // Calculate the distance from 50%
    const distanceFrom50 = Math.abs(percentage - 48);

    // Calculate the adjustment factor
    const adjustmentFactor = distanceFrom50 / 50;

    // Adjust the radius
    const needleArcCenteringMove = percentage > 50 ? 0.25 * adjustmentFactor : 0.42 * adjustmentFactor;
    // const power = radius * (1 + adjustmentFactor);
    const adjustedRadius = radius * (1 + adjustmentFactor * needleArcCenteringMove);

    // Return the rounded result
    return Math.round(adjustedRadius);
  }

  // Function to convert polar coordinates to cartesian
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180);
    const flowRadius = adjustRadius(radius, percentage);

    return {
      x: centerX + flowRadius * Math.cos(angleInRadians),
      y: centerY + flowRadius * Math.sin(angleInRadians)
    };
  };

  // Calculate the position of the indicator
  const indicatorWidth = strokeWidth - 3;
  const indicatorHeight = 60;
  const indicatorCenter = polarToCartesian(centerX, centerY, radius, angle);
  const indicatorX = indicatorCenter.x - indicatorWidth / 2;
  const indicatorY = indicatorCenter.y - indicatorHeight / 2 + 15;

  return (
    <div style={{ width: isMiniReport ? 45 : isReport ? 210 : 330, height: isMiniReport ? 20 : isReport ? 105 : 160 }}>
      <svg style={{ width: '100%', height: '100%', overflow: 'visible' }} viewBox="0 0 310 160">
        <path
          d="M3.20274 148.38C2.39794 150.648 4.09119 153 6.48094 153H6.64193C8.09629 153 9.39789 152.094 9.94511 150.736C31.2382 97.8846 82.6797 60.6166 142.757 60.6166C202.834 60.6166 254.275 97.8846 275.569 150.736C276.115 152.094 277.418 153 278.871 153H302.519C304.909 153 306.602 150.648 305.798 148.38C283.587 85.7868 224.232 41 154.5 41C84.7674 41 25.4127 85.7868 3.20274 148.38Z"
          fill="url(#paint0_linear_166_127)"
        />
        <defs>
          <linearGradient id="paint0_linear_166_127" x1="18.5" y1="113.5" x2="290" y2="113.5" gradientUnits="userSpaceOnUse">
            <stop stopOpacity="0.1" />
            <stop offset="1" />
          </linearGradient>
        </defs>
        <rect
          id="indicator"
          x={indicatorX}
          y={indicatorY}
          width={indicatorWidth}
          height={indicatorHeight}
          transform={`rotate(${angle}, ${indicatorCenter.x}, ${indicatorCenter.y})`}
          style={{ strokeWidth: 4, stroke: 'white' }}
          fill={color}
          rx={2}
          ry={2}
        />
      </svg>
    </div>
  );
};

const getColorName = (chemoName) => {
  if (chemoName === 'RECOVER') {
    return '#EB6E23';
  }
  if (chemoName === 'ELEVATE') {
    return '#FFC700';
  }
  if (chemoName === 'MOTIVATE') {
    return '#88B04B';
  }
  if (chemoName === 'RELAX') {
    return '#007FFF';
  }
  if (chemoName === 'INDULGE') {
    return '#654EA3';
  }
  if (chemoName === 'SLEEP') {
    return '#030972';
  }
};
