import {
  FETCH_FILTERS_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_LOADING,
  FETCH_PRODUCT_SCROLLING,
  FETCH_PRODUCT_SCROLLING_SUCCESS,
  FORCE_RESET_FILTERS,
  RESET_FILTERS,
  RESET_PRODUCTS,
  UPDATE_FILTERS,
  UPDATE_TOTAL_PRODUCTS
} from '../consts/reduxActionTypes';

// Initial states
const initialProductState = {
  loading: false,
  scrolling: false,
  total: 0,
  data: []
};

const initialFilterState = {
  filters: [],
  selectedFilters: {
    page: 1,
    pageSize: 10
  }
};

const productsReducer = (state = initialProductState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case FETCH_PRODUCT_SCROLLING:
      return {
        ...state,
        scrolling: action.payload
      };
    case UPDATE_TOTAL_PRODUCTS:
      return {
        ...state,
        total: action.payload
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case FETCH_PRODUCT_SCROLLING_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload]
      };
    case RESET_PRODUCTS:
      return initialProductState;

    default:
      return state;
  }
};

const filtersReducer = (state = initialFilterState, action) => {
  switch (action.type) {
    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload
      };

    case UPDATE_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload
      };

    case RESET_FILTERS: {
      return {
        ...state,
        selectedFilters: action.payload
      };
    }
    case FORCE_RESET_FILTERS: {
      return { ...initialFilterState };
    }

    default:
      return state;
  }
};

export { filtersReducer, productsReducer };
