import { ReactComponent as Logo2V2 } from '../../../assets/icons/navigationBar/logo2_v2.svg';

import { AppBar, Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ variant }) => ({
  backgroundColor: '#F1EEEB',
  elevation: 0,
  position: 'static',
  marginTop: variant === 'home' ? 0 : '5%',
  paddingTop: '2rem',
  paddingBottom: '1.5rem',
  alignItems: 'center'
}));

const StyledLogoContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '7px',
  fontWeight: 'light',
  color: 'black'
}));

const StyledLogo2 = styled(() => {
  return <Logo2V2 />;
})(() => ({}));

const StyledDivider = styled(Divider)(() => ({
  backgroundColor: 'black'
}));

export { StyledAppBar, StyledLogoContainer, StyledLogo2, StyledDivider, StyledTypography };
