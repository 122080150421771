import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import CustomButton from '../../../components/buttons/custombutton';
import ProductsList from '../../../components/lists/productsList';
import CustomPopper from '../../../components/poppers/customPopper';
import OverAllFilters from '../../../components/shopByFilters/selectedFilters';
import SortComponent from '../../../components/shopByFilters/sort';
import NavigationTabs from '../../../components/tabs/navigationTabs';
import AgeVerification from '../../AgeVerification/AgeVerification';
import PerfectMatchFilters from './perfectMatchFilters';

// Const
import { perFectMatchConsts, perfectMatchFiltersConsts, popperRevealProductsConsts } from '../../../consts/shopbyPerfectMatchConsts';

// Styles
import { DescriptionTypography, ParentContainer, ParentGrid, PerfectMatchTypography, TitleContainer } from './styledComponents';

// Actions
import { fetchProductsLoadingAction, updateFiltersAction } from '../../../store/actions/shopByActions';

// Selectors
import { selectSelectedFilters } from '../../../store/selectors/shopBySelectors';

// Hooks
import useFetchProducts from '../../../hooks/useFetchProducts';
import { usePopper } from '../../../hooks/usePopper';
import usePoppersVisibility from '../../../hooks/usePoppersVisibility';
import useStepTransition from '../../../hooks/useStepTransition';
import PerfectMatchProductSectionHome from '../../HomePage/components/ShopByPerfectMatchHome/PerfectMatchProductsSectionHome';
import Container from '../../../components/Container';

const PerfectMatch = ({ isHomeView = false }) => {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  //Selectors
  const selectedFilters = useSelector(selectSelectedFilters);
  const userVerfificationState = useSelector((state) => state.user.userVerified);

  // Hooks
  const { ref, anchorEl, togglePopper } = usePopper();
  const { loading, products } = useFetchProducts(false);

  // States
  const isBelowLargeScreen = useMediaQuery('(max-width:1100px)');
  const [filterStates, setFilterStates] = useState([50, 50, 25, 50]);
  const { showFirstPopper, showSecondPopper, setShowFirstPopper, setShowSecondPopper, poppersAlreadyShown } = usePoppersVisibility();

  const handleProductReveal = () => {
    // updating filter
    const updatedFilters = {
      ...selectedFilters,
      page: 1,
      pageSize: 10,
      'Customize mood': Object.values(filterStates),
      'Product Type': 'All'
    };
    dispatch(fetchProductsLoadingAction(true));
    dispatch(updateFiltersAction(updatedFilters));
  };
  const { stepState, handleNext, handleBack } = useStepTransition(
    setShowFirstPopper,
    setShowSecondPopper,
    handleProductReveal,
    poppersAlreadyShown
  );

  const handleFilterChange = (index, step) => {
    const stepValues = [0, 25, 50, 75, 100];
    const newValue = stepValues[step];
    const newState = [...filterStates];
    newState[index] = newValue;
    setFilterStates(newState);
  };

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  useEffect(() => {
    if (selectedFilters['Customize mood']) {
      const newState = selectedFilters['Customize mood'];
      setFilterStates(newState);
    } else {
      const newState = [50, 50, 25, 50];
      setFilterStates(newState);
      if (!isHomeView) {
        handleProductReveal();
      }
    }
  }, [selectedFilters]);

  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      handleProductReveal();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <>
      <Wrapper isHomeView={isHomeView}>
        <ParentGrid
          isBelowLargeScreen={isBelowLargeScreen}
          isHomeView={isHomeView}
          container
          spacing={4}
          direction={isBelowLargeScreen ? 'column' : 'row'}
        >
          <TitleContainer item isBelowLargeScreen={isBelowLargeScreen} xs={12}>
            {/* <FindYourTypography>{perFectMatchConsts.textOne}</FindYourTypography> */}
            <PerfectMatchTypography>{perFectMatchConsts.textTwo}</PerfectMatchTypography>
          </TitleContainer>
          <Grid item alignContent={'center'} alignItems={'center'} style={{ marginTop: isBelowLargeScreen ? -25 : -20 }}>
            <DescriptionTypography>{perFectMatchConsts.description}</DescriptionTypography>
            <DescriptionTypography>
              Let <b>Power</b>Leaf data pair your product based on your specific needs.
            </DescriptionTypography>
          </Grid>
          {isBelowLargeScreen ? (
            <Grid item>
              <NavigationTabs
                showFirstPopper={showFirstPopper}
                showSecondPopper={showSecondPopper}
                filterStates={filterStates}
                stepState={stepState}
                handleFilterChange={handleFilterChange}
                handleNext={handleNext}
                handleBack={handleBack}
                enableProductsPrefill
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <PerfectMatchFilters
                  showFirstPopper={showFirstPopper}
                  toggleFirstPopper={handleNext}
                  filterStates={filterStates}
                  handleFilterChange={handleFilterChange}
                  perfectMatchFiltersConsts={perfectMatchFiltersConsts}
                />
              </Grid>

              <Grid item mt={-2} ref={ref}>
                <CustomButton title={'LETS DO THIS'} sx={{ width: '243px' }} onClick={handleProductReveal} />
                {showSecondPopper && (
                  <CustomPopper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    handleClose={handleNext}
                    popperDetails={popperRevealProductsConsts}
                  />
                )}
              </Grid>
            </>
          )}

          {!userVerfificationState && <AgeVerification isProductDetail={true} />}
        </ParentGrid>
      </Wrapper>
      <Grid item sx={12} container display={'flex'} direction={'column'}>
        {!isHomeView && (
          <>
            <OverAllFilters />
            <SortComponent />
          </>
        )}
        {isHomeView ? (
          <PerfectMatchProductSectionHome loading={loading} products={products} showOverlayText={true} onClick={handleProductClick} />
        ) : (
          <ProductsList isHomeView loading={loading} products={products} showOverlayText={true} onClick={handleProductClick} />
        )}
      </Grid>
    </>
  );
};

const Wrapper = ({ isHomeView, children }) => {
  if (isHomeView) {
    return <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>{children}</Container>;
  }

  return children;
};

export default PerfectMatch;
