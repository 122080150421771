import ActivitiesPNG from '../assets/icons/navigationBar/activities.png';
import MedicinalPNG from '../assets/icons/navigationBar/medicinal.png';
import MoodPNG from '../assets/icons/navigationBar/mood.png';
import ProductTypePNG from '../assets/icons/navigationBar/product_type.png';

// Routes
import { appRoutes } from '../routes/appRoutes';

export const menuData = {
  activities: {
    title: 'Activity',
    route: appRoutes.shopByActivity,
    svg: ActivitiesPNG,
    links: [
      'Exercise',
      // 'Stretch/Mobility',
      'Mindfulness',
      'Mobility',
      'Creativity',
      'Relaxation',
      'Entertainment',
      'Socializing',
      // 'Indulgence',
      'Sleep',
      'Intimacy',
      'Outdoors',
      'Dining'
    ]
  },
  medicinal: {
    title: 'Wellness Applications',
    route: appRoutes.shopBySymptoms,
    svg: MedicinalPNG,
    links: ['Stress', 'Mood', 'Nausea', 'Rest', 'Appetite', 'Energy', 'Discomfort', 'Focus']
  },
  mood: {
    title: 'Category',
    route: appRoutes.shopByMood,
    svg: MoodPNG,
    links: ['Elevate', 'Indulge', 'Motivate', 'Recover', 'Relax', 'Sleep']
  },
  productType: {
    title: 'Product Type',
    route: appRoutes.shopByProductType,
    svg: ProductTypePNG,
    links: ['Flower', 'Pre-Rolls', 'Vaporizors', 'Concentrates', 'Edibles & Beverage', 'Topicals']
  }
};
