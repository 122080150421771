import { Grid, Typography } from '@mui/material';
import React from 'react';
import Cannabinoids from './Cannabinoids';
import Terpenes from './Terpenes';

const ProductComposition = ({ compositionData, isReport = false }) => {
  return (
    <React.Fragment>
      <Grid item xs={isReport ? 6 : 12} md={6} mt={isReport ? -3 : 0}>
        <Typography variant="h3" mb={2} fontFamily={'Space Grotesk'} fontSize={isReport ? '24px' : '28px'} fontWeight={500}>
          Composition *
        </Typography>
        <Cannabinoids cannabinoids={compositionData.cannabinoids} isReport={isReport} chemoName={compositionData.chemoName} />
      </Grid>
      <Grid item xs={isReport ? 6 : 12} md={6} mt={isReport ? 7 : 5}>
        <Terpenes terpenesData={compositionData.terpenes} isReport={isReport} chemoName={compositionData.chemoName} />
      </Grid>
    </React.Fragment>
  );
};

export default ProductComposition;
