import React from 'react';
import { getMoodColors } from '../../../config/consts';

const IntimacySVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="60" height="60" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.96315 1.29556C10.1406 1.19309 10.3593 1.19309 10.5368 1.29556L17.6449 5.39944C17.8224 5.50191 17.9317 5.69127 17.9317 5.89621V14.104C17.9317 14.3089 17.8224 14.4983 17.6449 14.6007L10.5368 18.7046C10.3593 18.8071 10.1406 18.8071 9.96315 18.7046L2.85502 14.6007C2.67754 14.4983 2.56821 14.3089 2.56821 14.104V5.89621C2.56821 5.69127 2.67754 5.5019 2.85502 5.39944L9.96315 1.29556Z" stroke={color} strokeWidth="0.382413" />
      <path d="M12.3951 6.61118L13.5517 5.45459H9.51123V9.49507L10.6729 8.33343C11.6678 9.07082 12.3143 10.2426 12.3143 11.5759C12.3143 12.2375 12.1527 12.8588 11.8699 13.4093C13.0467 12.6416 13.8295 11.3183 13.8295 9.80821C13.8295 8.53546 13.2689 7.39907 12.3951 6.61118Z" fill={color} />
      <path d="M7.72209 8.42419C7.72209 7.76256 7.88371 7.14134 8.16654 6.59082C6.9847 7.35851 6.20691 8.68177 6.20691 10.1919C6.20691 11.4646 6.76248 12.601 7.64128 13.3889L6.47964 14.5455H10.5201V10.505L9.35848 11.6667C8.36857 10.9293 7.72209 9.75755 7.72209 8.42419Z" fill={color} />
    </svg>

  );
};

export default IntimacySVG;
