import ApiBaseHelper from '../apiBaseHelper';
import ApiLinks from '../apiLinks';

const fetchFilters = async () => {
  const response = await ApiBaseHelper.get(`${ApiLinks.FETCH_FILTERS}`);
  return response;
};

const fetchProducts = async (payload) => {
    const response = await ApiBaseHelper.post(`${ApiLinks.FETCH_PRODUCTS}`, payload);
    return response;
}

export {
    fetchFilters,
    fetchProducts
}