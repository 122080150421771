import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  borderRadius: '6px',
  height: 210,
  width: 171,
  border: isSelected ? '3px solid' : 'none',
  borderImage: isSelected ? 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%) 1' : 'none',
  borderImageSlice: 1
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FilterCard = ({ title, image, onClick, isSelected }) => (
  <StyledCard isSelected={isSelected} onClick={onClick}>
    <Box position="relative" width="100%" height="100%">
      <CardActionArea>
        {typeof image === 'string' ? <img src={image} alt={title} style={{ borderRadius: 'inherit' }} /> : image}
        <StyledCardContent>
          <Typography fontFamily="Inter" fontSize="16px" fontWeight={700}>{title.charAt(0).toUpperCase() + title.slice(1)}</Typography>
        </StyledCardContent>
      </CardActionArea>
    </Box>
  </StyledCard>
);

export default FilterCard;
