import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const Label = styled('label')({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.1px'
});

const CustomTextField = ({ label, name, register, errors, loading, ...rest }) => {
  return (
    <div>
      <Label>{label}</Label>
      <TextField
        variant="outlined"
        fullWidth
        {...register(name, { required: `${label} is required` })}
        error={!!errors[name]}
        helperText={errors[name]?.message}
        disabled={loading}
        InputLabelProps={{ shrink: false }}
        sx={{
          '& input': {
            color: '#00000066',
            backgroundColor: '#F1EEEB'
          },
          '&  .MuiFormHelperText-root.Mui-error': {
            backgroundColor: 'transparent',
            marginLeft: 0
          },
          '.css-o8pmqo-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
            color: '#00000066',
            backgroundColor: '#F1EEEB'
          }
        }}
        {...rest}
      />
    </div>
  );
};

export default CustomTextField;
