// ApiBaseHelper.js
import axios from 'axios';
import ApiLinks from './apiLinks';

class ApiBaseHelper {
  static async get(url) {
    const response = await axios.get(`${ApiLinks.API_BASE_URL}${url}`);
    return response.data;
  }

  static async post(url, data) {
    const response = await axios.post(`${ApiLinks.API_BASE_URL}${url}`, data);
    return response.data;
  }

  static async patch(url, data) {
    const response = await axios.patch(`${ApiLinks.API_BASE_URL}${url}`, data);
    return response.data;
  }

  static async delete(url) {
    await axios.delete(`${ApiLinks.API_BASE_URL}${url}`);
  }
}

export default ApiBaseHelper;
