// hooks/useSubmitOrder.js
import { useState } from 'react';
import { submitOrder } from '../store/checkout/services/checkoutService';
import { setCompletedOrder, useCheckoutPayload } from '../store/checkout/actions/checkoutActions';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const useSubmitOrder = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkoutPayload = useCheckoutPayload();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submit = async () => {
    try {
      setIsSubmitting(true);
      const completedOrder = await submitOrder(payload);
      dispatch(setCompletedOrder(payload));
      navigate('/confirmation');
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, submit };
};

export default useSubmitOrder;
