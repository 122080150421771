import { getMoodColors } from '../../../config/consts';

const CreativitySVG = ({ chemoName }) => {
  const color = getMoodColors(chemoName).color;

  return (
    <svg width="60" height="60" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.2001C41 32.4174 32.7173 40.7001 22.5 40.7001C12.2827 40.7001 4 32.4174 4 22.2001C4 11.9828 12.2827 3.70007 22.5 3.70007C32.7173 3.70007 41 11.9828 41 22.2001Z"
        stroke={color}
      />
      <path
        d="M22 12.2001C16.2017 12.2001 11.5 16.9017 11.5 22.7001C11.5 28.4984 16.2017 33.2001 22 33.2001C22.9683 33.2001 23.75 32.4184 23.75 31.4501C23.75 30.9951 23.575 30.5867 23.295 30.2717C23.0267 29.9684 22.8517 29.5601 22.8517 29.1167C22.8517 28.1484 23.6333 27.3667 24.6017 27.3667H26.6667C29.8867 27.3667 32.5 24.7534 32.5 21.5334C32.5 16.3767 27.7983 12.2001 22 12.2001ZM15.5833 22.7001C14.615 22.7001 13.8333 21.9184 13.8333 20.9501C13.8333 19.9817 14.615 19.2001 15.5833 19.2001C16.5517 19.2001 17.3333 19.9817 17.3333 20.9501C17.3333 21.9184 16.5517 22.7001 15.5833 22.7001ZM19.0833 18.0334C18.115 18.0334 17.3333 17.2517 17.3333 16.2834C17.3333 15.3151 18.115 14.5334 19.0833 14.5334C20.0517 14.5334 20.8333 15.3151 20.8333 16.2834C20.8333 17.2517 20.0517 18.0334 19.0833 18.0334ZM24.9167 18.0334C23.9483 18.0334 23.1667 17.2517 23.1667 16.2834C23.1667 15.3151 23.9483 14.5334 24.9167 14.5334C25.885 14.5334 26.6667 15.3151 26.6667 16.2834C26.6667 17.2517 25.885 18.0334 24.9167 18.0334ZM28.4167 22.7001C27.4483 22.7001 26.6667 21.9184 26.6667 20.9501C26.6667 19.9817 27.4483 19.2001 28.4167 19.2001C29.385 19.2001 30.1667 19.9817 30.1667 20.9501C30.1667 21.9184 29.385 22.7001 28.4167 22.7001Z"
        fill={color}
      />
    </svg>
  );
};

export default CreativitySVG;
