import { Grid, Typography } from '@mui/material';
import React from 'react';
import { DescriptionText } from '../StyledComponents/StylesComponents';
import ActivityMedicine from './ActivityMedicine';
import ExperienceCard from './ExperienceCard';
import MoodsComponent from './ProductMoods';

const GetDescriptionText = ({ type }) => {
  type = type.toUpperCase();
  if (type === 'SLEEP') {
    return <DescriptionText mb={2}>The active ingredients in this product are likely to influence GABA and Serotonin levels to promote peaceful relaxation, sedative effects, and feelings of comfort and bliss.<br /><br />The brain chemistry that would typically result from this product would most likely pair well with the following consumption goals:</DescriptionText>
  }
  if (type === 'INDULGE') {
    return <DescriptionText mb={2}>The active ingredients in this product are likely to influence Dopamine, Serotonin, and Endorphins to promote heightened sensory experiences, cravings, feelings of enjoyment and euphoria.<br /><br />The brain chemistry that would typically result from this product would most likely pair well with the following consumption goals:</DescriptionText>
  }
  if (type === 'MOTIVATE') {
    return <DescriptionText mb={2}>The active ingredients in this product are likely to influence Dopamine and Norepinephrine levels to promote energy, enhanced focus, and motivation.<br /><br />The brain chemistry that would typically result from this product would most likely pair well with the following consumption goals:</DescriptionText>
  }
  if (type === 'ELEVATE') {
    return <DescriptionText mb={2}>The active ingredients in this product are likely to have a predominant influence on Serotonin and Dopamine levels to balance mood, and create feelings of contentment, and appreciation.<br /><br />The brain chemistry that would typically result from this product would most likely pair well with the following consumption goals:</DescriptionText>
  }
  if (type === 'RELAX') {
    return <DescriptionText mb={2}>The active ingredients in this product are likely to influence GABA and Endorphins to promote mental tranquility, total body relaxation and bliss.<br /><br />The brain chemistry that would typically result from this product would most likely pair well with the following consumption goals:</DescriptionText>
  }
  if (type === 'RECOVER') {
    return <DescriptionText mb={2}>The active ingredients in this product are likely to influence Endorphins and GABA levels to promote body relaxation, ease discomfort, and restore energy balance.<br /><br />The brain chemistry that would typically result from this product would most likely pair well with the following consumption goals:</DescriptionText>
  }
}

const ProductExperience = ({ experienceData }) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" mb={2} fontFamily={"Space Grotesk"} fontWeight={"500"} fontSize={"28px"}>
          Experience
        </Typography>

        <GetDescriptionText type={experienceData.logo.category} />

        <ActivityMedicine activities={experienceData.activityPairings} uses={experienceData.medicinalUses} chemoName={experienceData.logo.category} />
      </Grid>

      <Grid item xs={12} md={6} mt={5}>
        <ExperienceCard data={experienceData.logo} />
        <MoodsComponent chemoName={experienceData.logo.category} data={experienceData.filters} />
      </Grid>
    </React.Fragment>
  );
};

export default ProductExperience;
