import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BannerConsts } from '../../consts/banner_consts';

// Selectors
import { selectSelectedFilters } from '../../store/selectors/shopBySelectors';

const BannerComponent = ({ type }) => {
    // Dispatch
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    //Selectors
    const selectedFilters = useSelector(selectSelectedFilters);

    const bannerConst = BannerConsts();

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1} mt={2} style={{ width: '100%', minHeight: '80px', backgroundColor: bannerConst[type][selectedFilters[type]]?.color }}>
            <Grid container direction={isMobile ? 'column' : 'row'} alignItems={'center'} spacing={isMobile ? 1 : 4}>
                <Grid item mt={1} ml={1}>
                    <img src={bannerConst[type][selectedFilters[type]]?.logo} alt="" style={{ maxHeight: '60px', maxWidth: '60px' }} />
                </Grid>

                <Grid item>
                    <Typography fontWeight={700} fontSize={selectedFilters[type] === 'entertainment' || selectedFilters[type] === 'mindfulness' || selectedFilters[type] === 'inflammation' ? '18px' : '26px'}>
                        {bannerConst[type][selectedFilters[type]]?.title}
                    </Typography>
                </Grid>

                <Grid item xs={6} md={5} lg={6.5} xl={7.5} style={{ width: isMobile ? '90%' : '100%' }}>
                    {bannerConst[type][selectedFilters[type]]?.description}
                </Grid>

                {
                    isMobile ? (
                        <>
                            <Grid container spacing={6} alignItems={"center"} justifyContent={"center"}>
                                <Grid item mt={3}>
                                    <img src={bannerConst[type][selectedFilters[type]]?.imageOne} alt="" style={{ maxHeight: '70px', maxWidth: '140px' }} />
                                </Grid>

                                <Grid item mt={3}>
                                    <img src={bannerConst[type][selectedFilters[type]]?.imageTwo} alt="" style={{ maxHeight: '70px', maxWidth: '140px' }} />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item mt={1}>
                                <img src={bannerConst[type][selectedFilters[type]]?.imageOne} alt="" style={{ maxHeight: '70px', maxWidth: '110px' }} />
                            </Grid>

                            <Grid item mt={1}>
                                <img src={bannerConst[type][selectedFilters[type]]?.imageTwo} alt="" style={{ maxHeight: '70px', maxWidth: '110px' }} />
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </Box>
    );
};

export default BannerComponent;
