import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

// Components
import PerfectMatchCard from '../../../components/cards/perfectMatchCard';

const PerfectMatchFilters = ({ filterStates, handleFilterChange, perfectMatchFiltersConsts, showFirstPopper, toggleFirstPopper }) => {
  return (
    <Grid container spacing={2} justifyContent="center">
      {perfectMatchFiltersConsts.map((mood, index) => (
        <Grid item key={index}>
          <PerfectMatchCard
            showFirstPopper={showFirstPopper}
            toggleFirstPopper={toggleFirstPopper}
            noFiltersDescription={mood.noFiltersDescription}
            title={mood.title}
            steps={mood.steps}
            currentStep={parseInt(filterStates[index] / 25)}
            onStepChange={(step) => handleFilterChange(index, step)}
            index={index}
          />
          <Typography textAlign={"center"} mt={2} style={{ color: "#5f5f5f" }}>{mood?.steps[parseInt(filterStates[index] / 25)]?.label}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default PerfectMatchFilters;
