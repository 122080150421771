import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as ProductReportLogo } from '../../../../assets/icons/productDetails/report_product_logo.svg';
import ElevateBackground from '../../../../assets/images/miniReport/ElevateBackground.png';
import IndulgeBackground from '../../../../assets/images/miniReport/IndulgeBackground.png';
import { ReactComponent as MiniReportLogo } from '../../../../assets/images/miniReport/MiniReportLogo.svg';
import MotivateBackground from '../../../../assets/images/miniReport/MotivateBackground.png';
import RecoverBackground from '../../../../assets/images/miniReport/RecoverBackground.png';
import RelaxBackground from '../../../../assets/images/miniReport/RelaxBackground.png';
import SleepBackground from '../../../../assets/images/miniReport/SleepBackground.png';
import { ReactComponent as ElevateLogo } from '../../../../assets/images/miniReport/cardFront/elevateLogo.svg';
import { ReactComponent as IndulgeLogo } from '../../../../assets/images/miniReport/cardFront/indulgeLogo.svg';
import { ReactComponent as MotivateLogo } from '../../../../assets/images/miniReport/cardFront/motivateLogo.svg';
import { ReactComponent as RecoverLogo } from '../../../../assets/images/miniReport/cardFront/recoverLogo.svg';
import { ReactComponent as RelaxLogo } from '../../../../assets/images/miniReport/cardFront/relaxLogo.svg';
import { ReactComponent as SleepLogo } from '../../../../assets/images/miniReport/cardFront/sleepLogo.svg';

const FrontLogosMap = {
  ELEVATE: <ElevateLogo width={'26px'} height={'26px'} chemoName={'ELEVATE'} />,
  MOTIVATE: <MotivateLogo width={'26px'} height={'26px'} chemoName={'MOTIVATE'} />,
  INDULGE: <IndulgeLogo width={'26px'} height={'26px'} chemoName={'INDULGE'} />,
  RECOVER: <RecoverLogo width={'26px'} height={'26px'} chemoName={'RECOVER'} />,
  RELAX: <RelaxLogo width={'26px'} height={'26px'} chemoName={'RELAX'} />,
  SLEEP: <SleepLogo width={'26px'} height={'26px'} chemoName={'SLEEP'} />
};

const FrontLogo = ({ chemoName }) => {
  return FrontLogosMap[chemoName] || <SleepLogo />;
};

const GetCardText = ({ type }) => {
  type = type.toUpperCase();
  if (type === 'SLEEP') {
    return (
      <Typography color="#fff" fontFamily="Inter" fontSize="7.5px" fontWeight={700} lineHeight="10px" style={{ marginTop: '-2px' }}>
        Sedation & Bliss
      </Typography>
    );
  }
  if (type === 'INDULGE') {
    return (
      <Typography color="#fff" fontFamily="Inter" fontSize="7.5px" fontWeight={700} lineHeight="10px" style={{ marginTop: '-2px' }}>
        Sensory Experiences
      </Typography>
    );
  }
  if (type === 'MOTIVATE') {
    return (
      <Typography color="#fff" fontFamily="Inter" fontSize="7.5px" fontWeight={700} lineHeight="10px" style={{ marginTop: '-2px' }}>
        Energy & Focus
      </Typography>
    );
  }
  if (type === 'ELEVATE') {
    return (
      <Typography color="#fff" fontFamily="Inter" fontSize="7.5px" fontWeight={700} lineHeight="10px" style={{ marginTop: '-2px' }}>
        Mood and Spirit
      </Typography>
    );
  }
  if (type === 'RELAX') {
    return (
      <Typography color="#fff" fontFamily="Inter" fontSize="7.5px" fontWeight={700} lineHeight="10px" style={{ marginTop: '-2px' }}>
        Mind & Body
      </Typography>
    );
  }
  if (type === 'RECOVER') {
    return (
      <Typography color="#fff" fontFamily="Inter" fontSize="7.5px" fontWeight={700} lineHeight="10px" style={{ marginTop: '-2px' }}>
        Rejuvenation & Relief
      </Typography>
    );
  }
};

const getCardBackroundImage = (type) => {
  type = type.toUpperCase();
  if (type === 'SLEEP') {
    return SleepBackground;
  }
  if (type === 'INDULGE') {
    return IndulgeBackground;
  }
  if (type === 'MOTIVATE') {
    return MotivateBackground;
  }
  if (type === 'ELEVATE') {
    return ElevateBackground;
  }
  if (type === 'RELAX') {
    return RelaxBackground;
  }
  if (type === 'RECOVER') {
    return RecoverBackground;
  }
};

export default function BaseballCardFront({ product }) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${getCardBackroundImage(product.experience.logo.category)})`,
        backgroundSize: 'cover',
        width: '240px',
        height: '336px',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#ffffff',
          display: 'inline'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#000000',
            width: '80px',
            height: '109px',
            marginLeft: '10px',
            zIndex: '2',
            position: 'absolute',
            borderRadius: '0% 0% 3% 3%'
          }}
        >
          <Box>
            {/* <img src={MiniReportLogo} alt="Logo" width="58px" height="44" style={{ marginLeft: '10px' }} /> */}
            <MiniReportLogo width="58px" height="44" style={{ marginLeft: '10px', marginTop: '6px' }} />
          </Box>

          <Box
            sx={{
              marginTop: '6px',
              marginLeft: '20px'
            }}
          >
            {/* <QRCode style={{ width: '40px', height: '40px' }} /> */}
            <img src={`${product.qr.qrCode}`} alt={'QR'} style={{ width: '40px', height: '40px' }} />
          </Box>
        </Box>

        <Grid container style={{ position: 'absolute', marginLeft: '100px', marginTop: '15px' }}>
          <Grid item xs={1.7}>
            <FrontLogo chemoName={product.experience.logo.category} width={'26px'} height={'26px'} />
          </Grid>

          <Grid container item xs={10} direction="column" spacing={0.45}>
            <Grid container item alignItems={'flex-start'}>
              {/* <Grid item xs={1}>
                            <FrontLogo chemoName={product.experience.logo.category} width="14px" height="14px" />
                        </Grid> */}
              <Grid item>
                <Typography color="#fff" fontFamily="Space Grotesk" fontSize="16px" fontWeight={500} lineHeight="15.03px">
                  {product.experience.logo.category.slice(0, 1) + product.experience.logo.category.slice(1).toLowerCase()}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <GetCardText type={product.experience.logo.category.toUpperCase()} />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundImage: `url('${product.thumbnail.picture}')`,
          backgroundColor: '#fff',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '200px',
          height: '234px',
          position: 'absolute',
          marginTop: '51px',
          marginLeft: '20px'
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          marginTop: '260px',
          marginLeft: '10px',
          display: 'flex'
        }}
      >
        {/* <img src={BaySideLogo} alt="BaySide Logo" width="64px" height="64px" /> */}
        {product.companyLogoUrl ? (
          <div style={{ backgroundColor: '#fff', borderRadius: '50%', width: '64px', height: '64px', marginTop: '2px' }}>
            <img
              src={`${product.companyLogoUrl}`}
              alt={'Img'}
              width="64px"
              height="64px"
              style={{ borderRadius: '50%', objectFit: 'fill' }}
            />
          </div>
        ) : (
          <>
            <ProductReportLogo width="64px" height="64px" />
          </>
        )}

        <Grid container direction="column" spacing={0} style={{ marginTop: '27px', marginLeft: '2px' }}>
          <Grid item>
            <Typography
              color="#fff"
              fontFamily="Space Grotesk"
              fontSize={product.thumbnail.name.trim().split(/\s+/).length >= 3 ? '10px' : '13px'}
              fontWeight={700}
            >
              {product.thumbnail.name}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              color="#fff"
              fontFamily="Inter"
              fontSize={product.thumbnail.name.trim().split(/\s+/).length >= 3 ? '8px' : '10px'}
              fontWeight={400}
              marginTop={'0px'}
            >
              {product.thumbnail.offeredBy}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
