import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import PerfectMatchProductListHome from '../../../HomePage/components/ShopByPerfectMatchHome/PerfectMatchProductListHome';

const RecommendedProducts = ({ title, products, isAnalyzed, isCheckout = false }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };
  return (
    <React.Fragment>
      <Grid
        item
        sx={{ overflowX: 'auto', overflowY: 'hidden', mt: !isAnalyzed && matchesMd && !isCheckout ? 30 : 5, whiteSpace: 'nowrap' }}
      >
        <Box>
          <Typography fontFamily={'Roboto'} fontSize={'28px'} fontWeight={'400'}>
            {title}
          </Typography>
          <PerfectMatchProductListHome loading={false} products={products} onClick={handleProductClick} />
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default RecommendedProducts;
