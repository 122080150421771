import React from 'react';
// redux
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useFetchProductData from '../../hooks/useFetchProductData';
import { selectErrorState, selectLoadingState, selectProductData } from '../../store/productDetails/selectors/productSelectors';
import ProductReportShimmer from '../ProductDetails/Components/ProductDetailsShimmer/ProductDetailsShimmer';
import BaseballCardBack from './Components/ProductMiniReportComponents/BaseballCardBack';
import BaseballCardFront from './Components/ProductMiniReportComponents/BaseballCardFront';

export default function ProductMiniReport() {
    const productData = useSelector(selectProductData);
    const loading = useSelector(selectLoadingState);
    const error = useSelector(selectErrorState);
    const { id } = useParams();
    useFetchProductData(id);

    return (
        <>
            {loading ? (
                <ProductReportShimmer isReport={true} />
            ) : error ? (
                <Typography>Error: {error}</Typography>
            ) : productData ? (
                <>
                    <BaseballCardFront product={productData} />
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    />
                    <BaseballCardBack product={productData} />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
