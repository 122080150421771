import ImageIcon from '@mui/icons-material/Image';
import { Box, CardMedia, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { ReactComponent as ProductReportLogo } from '../../../../assets/icons/productDetails/report_product_logo.svg';

const truncateText = (text) => {
  return text?.length >= 35 ? text.slice(0, 33) + "..." : text;
}

const ProductName = styled(Typography)(({ theme }) => ({
  color: '#1d1b20',
  // fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 700,
}));

const ProductReportThumbnail = ({ product, thumbnailData }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <Grid container alignItems="center" mb={3} mt={4}>
      <Grid item xs={9} container spacing={0}>
        {
          product.companyLogoUrl ? (
            <div style={{ backgroundColor: '#fff', borderRadius: '50%' }}>
              <img src={`${product.companyLogoUrl}`} alt={"Img"} width="80px" height="80px" style={{ borderRadius: '50%', objectFit: 'fill' }} />
            </div>
          ) : (
            <>
              <ProductReportLogo />
            </>
          )
        }
        <Grid item xs={8}>
          <ProductName fontSize={thumbnailData.name.length >= 35 ? "26px" : "30px"} lineHeight={thumbnailData.name.length >= 35 ? "36px" : "40px"} fontWeight={700} fontFamily={"Space Grotesk"} ml={2}>
            {thumbnailData.name}
          </ProductName>
          <Typography fontSize={"18px"} fontWeight={400} fontFamily={"Inter"} component="div" ml={2}>
            {thumbnailData.offeredBy}
          </Typography>
          <Typography component="div" ml={2} fontFamily={"Inter"} fontSize={'16px'} lineHeight={'20px'} fontWeight={600}>{product?.composition?.cannabinoids?.productType}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        {thumbnailData.picture && imageLoaded ? (
          <CardMedia
            component="img"
            height={'135px'}
            src={thumbnailData.picture}
            alt={'Product'}
            style={{ width: '100%', height: '135px', objectFit: 'cover' }}
            onError={() => setImageLoaded(false)}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={'135px'}
            sx={{ width: '100%', backgroundColor: '#f0f0f0' }}
          >
            <ImageIcon style={{ fontSize: '300%', color: '#ccc' }} />
          </Box>
        )}{' '}
      </Grid>
    </Grid>
  );
};

export default ProductReportThumbnail;
